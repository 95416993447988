import {
  Heading,
  Container,
  Divider,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { ROUTES } from "constants/routes";
import { useHistory } from "react-router-dom";
import { UPDATE_USER_PROFILE } from "graphql/mutations";
import { GET_USER } from "graphql/queries";
import { useAuthContext } from "context/AuthContext";
import HeaderBack from "components/HeaderBack";
import PageLoader from "components/PageLoader";
import PhoneInput from "components/PhoneInput";
import { DURATION } from "constants/duration";
import ResetPasswordModal from "components/ResetPasswordModal";

/**
 * Used to create new or update existing tournament.
 */
function ProfileEditPage() {
  const toast = useToast();
  const history = useHistory();
  const { authState } = useAuthContext();
  const {
    isOpen: isPasswordResetConfirmModalOpen,
    onOpen: onPasswordResetConfirmModalOpen,
    onClose: onPasswordResetConfirmModalClose,
  } = useDisclosure();

  const userId = authState?.user?.objectId;

  const { loading: isLoadingUser, data } = useQuery(GET_USER, {
    fetchPolicy: "no-cache",
    variables: { userId },
  });

  const user = data?.user;

  const [updateUserProfile, { loading: isUpdatingUser }] =
    useMutation(UPDATE_USER_PROFILE);

  const formFields = {
    fullname: user?.fullname || "",
    email: user?.email || "",
    phone: user?.phone || "",
  };

  return (
    <>
      <HeaderBack onCloseRoute={ROUTES.ACCOUNT_SETTINGS} />
      <Container
        maxW="container.md"
        mt={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}
      >
        <PageLoader isLoading={isLoadingUser}>
          <Heading mb={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}>
            Edit profile
          </Heading>

          <Formik
            initialValues={formFields}
            validationSchema={Yup.object({
              fullname: Yup.string()
                .max(50, "Must be 50 characters or less")
                .required("What's your first and last name?"),
              email: Yup.string()
                .email("Invalid email address")
                .required("What's your email?"),
              phone: Yup.string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("This field is Required"),
            })}
            onSubmit={(values) => {
              const variables = { ...values, userId };
              updateUserProfile({ variables })
                .then((resp) => {
                  toast({
                    title: "Your profile information was successfully updated",
                    status: "success",
                    duration: DURATION.MEDIUM,
                    isClosable: true,
                  });
                  history.push(ROUTES.ACCOUNT_SETTINGS);
                })
                .catch((e) => {
                  toast({
                    title: e.message,
                    status: "error",
                    duration: DURATION.LONG,
                    isClosable: true,
                  });
                });
            }}
          >
            <Form>
              <Field name="fullname">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.fullname && form.touched.fullname}
                  >
                    <FormLabel htmlFor="fullname">Full Name</FormLabel>
                    <Input {...field} id="fullname" />
                    <FormErrorMessage>{form.errors.fullname}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="email">
                {({ field, form }) => (
                  <FormControl
                    mt="3"
                    isInvalid={form.errors.email && form.touched.email}
                  >
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input {...field} id="email" />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="phone">
                {({ field, form }) => {
                  const onChange = (value) => {
                    form.setFieldValue(field.name, value);
                  };

                  return (
                    <FormControl
                      mt="3"
                      isInvalid={form.errors.phone && form.touched.phone}
                    >
                      <FormLabel htmlFor="phone">Phone</FormLabel>
                      <PhoneInput
                        id="phone"
                        name={field.name}
                        value={field.value}
                        onBlur={field.onBlur}
                        onChange={onChange}
                      />
                      <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>

              <Button
                mt={4}
                w="100%"
                colorScheme="teal"
                isLoading={isUpdatingUser}
                type="submit"
              >
                Update
              </Button>
            </Form>
          </Formik>

          <Divider my="10" />

          <Button variant="link" onClick={onPasswordResetConfirmModalOpen}>
            Reset password
          </Button>
        </PageLoader>
      </Container>

      <ResetPasswordModal
        isOpen={isPasswordResetConfirmModalOpen}
        onClose={onPasswordResetConfirmModalClose}
        email={user?.email}
      />
    </>
  );
}

export default ProfileEditPage;
