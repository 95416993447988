import { useMutation } from "@apollo/client";
import { Button } from "@chakra-ui/react";
import { LOG_OUT } from "graphql/mutations";
import { useAuthContext } from "context/AuthContext";

function LogoutButton({ ...props }) {
  const [logOutMutation, { loading }] = useMutation(LOG_OUT);
  const { logOut } = useAuthContext();

  return (
    <Button
      isLoading={loading}
      onClick={() => {
        logOutMutation()
          .then(() => {
            logOut();
          })
          .catch((err) => {
            // TODO: add error logging

            // Still clear any client side state by logging out.
            logOut();
          });
      }}
      {...props}
    >
      Log Out
    </Button>
  );
}

export default LogoutButton;
