import {
  DINKPAL_FLAT_FEE,
  PAYPAL_FLAT_FEE,
  PAYPAL_PERCENT_FEE,
} from "constants/transactions";
import { getProfitAfterFees } from "utils/formatters";

function TransactionFeeInfo({ pricePerPerson }) {
  return (
    <>
      DinkPal charges {`$${DINKPAL_FLAT_FEE}`} per transaction. PayPal charges{" "}
      {`${PAYPAL_PERCENT_FEE * 100}%`} + {`$${PAYPAL_FLAT_FEE}`} per
      transaction. After this, you will receive{" "}
      <b>{getProfitAfterFees(pricePerPerson)}</b> per person.
    </>
  );
}

export default TransactionFeeInfo;
