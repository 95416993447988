export const ROUTES = {
  HOME: "/",
  SIGNUP: "/signup",
  SIGNIN: "/signin",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_OF_USE: "/terms-of-use",
  CONTACT_US: "/contact-us",

  MY_EVENTS: "/my-events",
  MY_LEAGUE: "/my-league",
  ADMIN_TOURNAMENT: "/my-league/tournaments/:tournamentId",

  ACCOUNT_SETTINGS: "/account-settings",
  EDIT_PROFILE: "/edit-profile",

  EDIT_LEAGUE: "/leagues/:leagueId",
  NEW_LEAGUE: "/leagues/new",
  LEAGUE_PAYPAL_ONBOARDING_COMPLETE:
    "/leagues/:leagueId/paypal-onboarding-complete",

  REGISTER_FOR_TOURNAMENT: "/tournaments/:tournamentId/register",
  EVENT_SCHEDULE: "/event-schedule/:eventId",

  EDIT_TOURNAMENT: "/leagues/:leagueId/tournaments/:tournamentId",
  NEW_TOURNAMENT: "/leagues/:leagueId/tournaments/new",

  EDIT_EVENT: "/leagues/:leagueId/tournaments/:tournamentId/events/:eventId",
  NEW_EVENT: "/leagues/:leagueId/tournaments/:tournamentId/events/new",

  TEAM_INVITE: "/teams/:teamId/invite",

  BRACKET_PREVIEW: "/bracket-preview/:bracketType/:numTeams",
};
