import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";

function DatePicker({ value, onChange, name }) {
  return (
    <ReactDatePicker
      id={name}
      name={name}
      dateFormat="MM/dd/yyyy h:mm aa"
      selected={typeof value === "string" ? new Date(value) : value}
      onChange={onChange}
      minDate={new Date()}
      showTimeSelect
    />
  );
}

export default DatePicker;
