import { useMutation } from "@apollo/client";
import {
  Button,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { DURATION } from "constants/duration";
import client from "graphql/client";
import {
  ADD_EXISTING_TEAM_TO_EVENT,
  ADD_EXISTING_PLAYER_TO_TEAM,
  CREATE_TEAM,
  DELETE_TEAM,
} from "graphql/mutations";
import { useState } from "react";
import { getTeamPlayerNames } from "utils/formatters";
import ConfirmModal from "components/ConfirmModal";
import { BiCaretDown } from "react-icons/bi";

function MergeTeamsDropdown({
  team,
  teamOptions,
  eventId,
  refetchQueries,
  triggerStyles = {},
}) {
  const [teamsToMerge, setTeamsToMerge] = useState([]);
  const filteredTeamOptions = (teamOptions || []).filter(
    ({ objectId }) => objectId !== team.objectId
  );

  const toast = useToast();
  const {
    isOpen: isConfirmMoveModalOpen,
    onOpen: onConfirmMoveModalOpen,
    onClose: onConfirmMoveModalClose,
  } = useDisclosure();

  const [createTeam] = useMutation(CREATE_TEAM);
  const [addExistingTeamToEvent] = useMutation(ADD_EXISTING_TEAM_TO_EVENT);
  const [addExistingPlayerToTeam] = useMutation(ADD_EXISTING_PLAYER_TO_TEAM);
  const [deleteTeam] = useMutation(DELETE_TEAM);

  return (
    <>
      <Menu>
        <MenuButton
          variant="ghost"
          size="sm"
          as={Button}
          rightIcon={<BiCaretDown />}
          disabled={!filteredTeamOptions.length}
          {...triggerStyles}
        >
          Join with
        </MenuButton>
        <MenuList>
          {filteredTeamOptions.map((teamOption) => {
            return (
              <MenuItem
                key={teamOption.objectId}
                onClick={() => {
                  setTeamsToMerge([team, teamOption]);
                  onConfirmMoveModalOpen();
                }}
              >
                {getTeamPlayerNames(teamOption)}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
      <ConfirmModal
        headerText="Join players"
        confirmButtonText="Join"
        isOpen={isConfirmMoveModalOpen}
        onClose={onConfirmMoveModalClose}
        onConfirmClick={async () => {
          try {
            // 1. Create new team
            // 2. Add players to that team
            // 3. Add team to the provided event
            // 4. Delete all previous teams

            const newTeam = await createTeam({ variables: { eventId } });
            const newTeamId = newTeam?.data?.createTeam?.team?.objectId;

            const players = teamsToMerge.reduce((allPlayers, team) => {
              const teamPlayers = team?.players?.edges.map(({ node }) => node);
              return [...allPlayers, ...teamPlayers];
            }, []);

            const addPlayerToTeamPromises = players.map((player) =>
              addExistingPlayerToTeam({
                variables: { teamId: newTeamId, playerId: player?.objectId },
              })
            );

            await Promise.all(addPlayerToTeamPromises);

            await addExistingTeamToEvent({
              variables: { eventId, teamId: newTeamId },
            });

            const teamToDeletePromises = teamsToMerge.map((team) =>
              deleteTeam({ variables: { teamId: team?.objectId } })
            );

            await Promise.all(teamToDeletePromises);

            // TODO: Should I pass a refetch() function instead of refetchQueries?
            await client.refetchQueries({
              include: refetchQueries,
            });

            toast({
              title: `Players were successfully joined on the same team`,
              status: "success",
              duration: DURATION.MEDIUM,
              isClosable: true,
            });
          } catch (e) {
            toast({
              title:
                "Sorry, there was a problem joining these players. Please try again later.",
              status: "error",
              duration: DURATION.MEDIUM,
              isClosable: true,
            });
          }
        }}
      >
        {teamsToMerge.length && (
          <Text>
            Are you sure you want to join{" "}
            <b>{getTeamPlayerNames(teamsToMerge?.[0])}</b> with{" "}
            <b>{getTeamPlayerNames(teamsToMerge?.[1])}</b>?
          </Text>
        )}
      </ConfirmModal>
    </>
  );
}

export default MergeTeamsDropdown;
