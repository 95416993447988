import { Input } from "@chakra-ui/react";

const format = (str) => {
  const part1 = str.slice(0, 3);
  const part2 = str.slice(3, 6);
  const part3 = str.slice(6, 10);
  let finalStr = part1;
  if (part2) {
    finalStr += `-${part2}`;
  }
  if (part3) {
    finalStr += `-${part3}`;
  }
  return finalStr;
};

const parse = (val) => {
  return val.replaceAll("-", "").slice(0, 10);
};

function PhoneInput({ value, onChange, ...rest }) {
  return (
    <Input
      inputMode="numeric"
      onChange={(e) => {
        const valueString = e.target.value.replace(/\D/g, "");
        const parsedValue = parse(valueString);
        onChange(parsedValue);
      }}
      value={format(value)}
      {...rest}
    />
  );
}

export default PhoneInput;
