import {
  Alert,
  AlertIcon,
  Text,
  VStack,
  SimpleGrid,
  Heading,
  Container,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { ROUTES } from "constants/routes";
import { useHistory, useParams } from "react-router-dom";
import { CREATE_EVENT, UPDATE_EVENT, DELETE_EVENT } from "graphql/mutations";
import { GET_EVENT } from "graphql/queries";
import HeaderBack from "components/HeaderBack";
import PageLoader from "components/PageLoader";
import EventTypeRadioGroup from "components/EventTypeRadioGroup";
import EventFormatRadioGroup from "components/EventFormatRadioGroup";
import DatePicker from "components/DatePicker";
import SkillLevelRadioGroup from "components/SkillLevelRadioGroup";
import AgeRangeSlider from "components/AgeRangeSlider";
import WinByRadioGroup from "components/PointsToWinByRadioGroup";
import MaxPlayersInput from "components/MaxPlayersInput";
import PointsToWinInput from "components/PointsToWinInput";
import ConfirmModal from "components/ConfirmModal";
import { DURATION } from "constants/duration";
import { getStartTimeFromQueryParam } from "utils/formatters";
import { EVENT_FIELD_DEFAULTS } from "utils/event";
import NotFoundPage from "./NotFoundPage";
import { useAuthContext } from "context/AuthContext";

/**
 * Used to create new or update existing event.
 */
function EventEditPage() {
  const { authState } = useAuthContext();
  const currentUserId = authState?.user?.objectId;
  const currentUserLeagueId = authState?.user?.leagueId;

  const toast = useToast();
  const history = useHistory();
  const { leagueId, tournamentId, eventId } = useParams();
  const isNewEvent = eventId === "new";
  const tournamentAdminPageUrl = `/my-league/tournaments/${tournamentId}`;

  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();

  const [createEvent, { loading: isCreatingEvent }] = useMutation(CREATE_EVENT);
  const [updateEvent, { loading: isUpdatingEvent }] = useMutation(UPDATE_EVENT);
  const [deleteEvent, { loading: isDeletingEvent }] = useMutation(
    DELETE_EVENT,
    {
      variables: {
        eventId,
      },
    }
  );

  const { loading: isEventLoading, data: eventData } = useQuery(GET_EVENT, {
    fetchPolicy: "no-cache",
    variables: { eventId },
    skip: isNewEvent,
  });

  const eventLeagueCreatedBy =
    eventData?.event?.tournament?.league?.createdBy?.objectId;
  const eventLeagueId = eventData?.event?.tournament?.league?.objectId;
  const eventTournamentId = eventData?.event?.tournament?.objectId;

  // Make sure provided leagueId matches logged in user.leagueId
  if (leagueId !== currentUserLeagueId) {
    return <NotFoundPage />;
  }

  // Show 404 for url params that are not allowed
  if (!isNewEvent && !isEventLoading) {
    if (
      // if fetched event does not exist
      !eventData ||
      // if event's league does not match logged in user.leagueId
      currentUserId !== eventLeagueCreatedBy ||
      // if provided tournamentId doesn't match event's tournamentId
      tournamentId !== eventTournamentId ||
      // if provided leagueId doesn't match event's leagueId
      leagueId !== eventLeagueId
    ) {
      return <NotFoundPage />;
    }
  }

  const formFields = {
    eventType: eventData?.event?.eventType || EVENT_FIELD_DEFAULTS.eventType,
    eventFormat:
      eventData?.event?.eventFormat || EVENT_FIELD_DEFAULTS.eventFormat,
    eventStartTime:
      eventData?.event?.eventStartTime || getStartTimeFromQueryParam(),
    skillLevel: eventData?.event?.skillLevel || "3.0",
    ageRange: eventData?.event?.ageRange || "0,100",
    playersMax: eventData?.event?.playersMax || 12,
    pointsToWin: eventData?.event?.pointsToWin || 11,
    pointsToWinBy: eventData?.event?.pointsToWinBy || 2,
  };

  const isEventTypeDisabled = !!eventData?.event?.teams?.edges?.length;
  const isEventFormatDisabled = !!eventData?.event?.eventSpec;

  return (
    <>
      <HeaderBack onCloseRoute={ROUTES.MY_LEAGUE} />
      <Container
        maxW="container.md"
        mt={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}
      >
        <PageLoader isLoading={isEventLoading}>
          <Heading mb={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}>
            {isNewEvent ? "Create event" : "Edit event"}
          </Heading>

          {isEventTypeDisabled && (
            <Alert status="warning" mb="4" borderRadius="md">
              <AlertIcon />
              This event already has registered players. Some important fields
              like "Event type" are not allowed to be changed.
            </Alert>
          )}

          <Formik
            initialValues={formFields}
            validationSchema={Yup.object({
              eventStartTime: Yup.string(),
              eventType: Yup.string().required("Please select a type of event"),
              eventFormat: Yup.string().required(
                "Please select an event format"
              ),
              skillLevel: Yup.string(),
              ageRange: Yup.string(),
              playersMax: Yup.number(),
              pointsToWin: Yup.number(),
              pointsToWinBy: Yup.number(),
            })}
            onSubmit={(values) => {
              if (isNewEvent) {
                const variables = { ...values, tournamentId };
                createEvent({ variables })
                  .then((resp) => {
                    toast({
                      title: "Your event was successfully created",
                      status: "success",
                      duration: DURATION.MEDIUM,
                      isClosable: true,
                    });
                    history.push(tournamentAdminPageUrl);
                  })
                  .catch((e) => {
                    toast({
                      title: e.message,
                      status: "error",
                      duration: DURATION.LONG,
                      isClosable: true,
                    });
                  });
              } else {
                const variables = { ...values, eventId };
                updateEvent({ variables })
                  .then((resp) => {
                    toast({
                      title: "Your event was successfully updated",
                      status: "success",
                      duration: DURATION.MEDIUM,
                      isClosable: true,
                    });
                    history.push(tournamentAdminPageUrl);
                  })
                  .catch((e) => {
                    toast({
                      title: e.message,
                      status: "error",
                      duration: DURATION.LONG,
                      isClosable: true,
                    });
                  });
              }
            }}
          >
            {({ values }) => (
              <Form>
                <VStack spacing={12} align="stretch">
                  <Field name="eventStartTime">
                    {({ field, form }) => {
                      const onChange = (value) => {
                        form.setFieldValue(field.name, value);
                      };
                      return (
                        <FormControl
                          isInvalid={
                            form.errors.eventStartTime &&
                            form.touched.eventStartTime
                          }
                        >
                          <FormLabel htmlFor="eventStartTime">
                            What day and time will this event be?
                          </FormLabel>
                          <DatePicker
                            name="eventStartTime"
                            value={field.value}
                            onChange={onChange}
                          />
                          <FormErrorMessage>
                            {form.errors.eventStartTime}
                          </FormErrorMessage>
                        </FormControl>
                      );
                    }}
                  </Field>

                  <Field name="eventType">
                    {({ field, form }) => (
                      <FormControl
                        as="fieldset"
                        isInvalid={
                          form.errors.eventType && form.touched.eventType
                        }
                      >
                        <Text mb="2" as="legend" fontWeight="semibold">
                          Event type
                        </Text>
                        <EventTypeRadioGroup
                          {...field}
                          disabled={isEventTypeDisabled}
                        />
                        <FormHelperText>
                          Event type cannot be changed once users start
                          registering.
                        </FormHelperText>
                        <FormErrorMessage>
                          {form.errors.eventType}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="eventFormat">
                    {({ field, form }) => (
                      <FormControl
                        as="fieldset"
                        isInvalid={
                          form.errors.eventFormat && form.touched.eventFormat
                        }
                      >
                        <Text mb="2" as="legend" fontWeight="semibold">
                          Format
                        </Text>
                        <EventFormatRadioGroup
                          {...field}
                          disabled={isEventFormatDisabled}
                        />
                        <FormErrorMessage>
                          {form.errors.eventFormat}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="skillLevel">
                    {({ field, form }) => {
                      const onChange = (value) => {
                        form.setFieldValue(field.name, value);
                      };
                      return (
                        <FormControl
                          as="fieldset"
                          isInvalid={
                            form.errors.skillLevel && form.touched.skillLevel
                          }
                        >
                          <Text mb="2" as="legend" fontWeight="semibold">
                            Skill level
                          </Text>
                          <SkillLevelRadioGroup
                            value={field.value}
                            onChange={onChange}
                          />
                          <FormErrorMessage>
                            {form.errors.skillLevel}
                          </FormErrorMessage>
                        </FormControl>
                      );
                    }}
                  </Field>

                  <Field name="ageRange">
                    {({ field, form }) => {
                      const onChange = (value) => {
                        form.setFieldValue(field.name, value);
                      };
                      return (
                        <FormControl
                          isInvalid={
                            form.errors.ageRange && form.touched.ageRange
                          }
                        >
                          <FormLabel htmlFor="ageRange">Age range</FormLabel>
                          <AgeRangeSlider
                            value={field.value}
                            onChange={onChange}
                          />
                          <FormErrorMessage>
                            {form.errors.ageRange}
                          </FormErrorMessage>
                        </FormControl>
                      );
                    }}
                  </Field>

                  <SimpleGrid columns={3} columnGap={4}>
                    <Field name="playersMax">
                      {({ field, form }) => {
                        const onChange = (value) => {
                          form.setFieldValue(field.name, Number(value));
                        };
                        return (
                          <FormControl
                            isInvalid={
                              form.errors.playersMax && form.touched.playersMax
                            }
                          >
                            <FormLabel htmlFor="playersMax">
                              Max players
                            </FormLabel>
                            <MaxPlayersInput
                              name="playersMax"
                              value={field.value}
                              onChange={onChange}
                            />
                            <FormErrorMessage>
                              {form.errors.playersMax}
                            </FormErrorMessage>
                          </FormControl>
                        );
                      }}
                    </Field>

                    <Field name="pointsToWin">
                      {({ field, form }) => {
                        const onChange = (value) => {
                          form.setFieldValue(field.name, Number(value));
                        };
                        return (
                          <FormControl
                            isInvalid={
                              form.errors.pointsToWin &&
                              form.touched.pointsToWin
                            }
                          >
                            <FormLabel htmlFor="pointsToWin">
                              Points to win
                            </FormLabel>
                            <PointsToWinInput
                              name="pointsToWin"
                              value={field.value}
                              onChange={onChange}
                            />
                            <FormErrorMessage>
                              {form.errors.pointsToWin}
                            </FormErrorMessage>
                          </FormControl>
                        );
                      }}
                    </Field>

                    <Field name="pointsToWinBy">
                      {({ field, form }) => {
                        const onChange = (value) => {
                          form.setFieldValue(field.name, value);
                        };
                        return (
                          <FormControl
                            as="fieldset"
                            isInvalid={
                              form.errors.pointsToWinBy &&
                              form.touched.pointsToWinBy
                            }
                          >
                            <Text mb="2" as="legend" fontWeight="semibold">
                              Win by
                            </Text>
                            <WinByRadioGroup
                              name="pointsToWinBy"
                              value={field.value}
                              onChange={onChange}
                            />
                            <FormErrorMessage>
                              {form.errors.pointsToWinBy}
                            </FormErrorMessage>
                          </FormControl>
                        );
                      }}
                    </Field>
                  </SimpleGrid>
                </VStack>

                <Button
                  mt={10}
                  w="100%"
                  colorScheme="teal"
                  isLoading={isCreatingEvent || isUpdatingEvent}
                  type="submit"
                >
                  {isNewEvent ? "Create" : "Update"}
                </Button>

                <Button
                  mt={4}
                  w="100%"
                  onClick={() => {
                    history.push(tournamentAdminPageUrl);
                  }}
                >
                  Cancel
                </Button>

                {!isNewEvent && (
                  <Button
                    mt={10}
                    w="100%"
                    colorScheme="red"
                    variant="ghost"
                    isLoading={isDeletingEvent}
                    onClick={() => {
                      onDeleteModalOpen();
                    }}
                  >
                    Delete event
                  </Button>
                )}
              </Form>
            )}
          </Formik>

          <ConfirmModal
            headerText="Delete event"
            bodyText="Are you sure you want to delete this event?"
            confirmButtonText="Delete"
            isOpen={isDeleteModalOpen}
            onClose={onDeleteModalClose}
            onConfirmClick={() => {
              deleteEvent().then(() => {
                toast({
                  title: "Event was deleted",
                  status: "success",
                  duration: DURATION.MEDIUM,
                  isClosable: true,
                });
                history.push(tournamentAdminPageUrl);
              });
            }}
          />
        </PageLoader>
      </Container>
    </>
  );
}

export default EventEditPage;
