// eslint-disable-next-line import/no-anonymous-default-export
export default {
  id: "match:20",
  children: [
    {
      id: "match:17",
      loserTo: "team:L17",
      children: [
        {
          id: "match:13",
          loserTo: "team:L13",
          children: [
            {
              id: "match:4",
              loserTo: "team:L4",
              children: [
                {
                  id: "team:4",
                },
                {
                  id: "team:5",
                },
              ],
            },
            {
              id: "match:5",
              loserTo: "team:L5",
              children: [
                {
                  id: "match:1",
                  children: [
                    {
                      id: "team:8",
                    },
                    {
                      id: "team:9",
                    },
                  ],
                },
                {
                  id: "team:1",
                },
              ],
            },
          ],
        },
        {
          id: "match:14",
          loserTo: "team:L14",
          children: [
            {
              id: "match:6",
              loserTo: "team:L6",
              children: [
                {
                  id: "team:2",
                },
                {
                  id: "match:2",
                  children: [
                    {
                      id: "team:7",
                    },
                    {
                      id: "team:10",
                    },
                  ],
                },
              ],
            },
            {
              id: "match:7",
              loserTo: "team:L7",
              children: [
                {
                  id: "match:3",
                  loserTo: "team:L3",
                  children: [
                    {
                      id: "team:6",
                    },
                    {
                      id: "team:11",
                    },
                  ],
                },
                {
                  id: "team:3",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "match:19",
      children: [
        {
          id: "team:L17",
        },
        {
          id: "match:18",
          children: [
            {
              id: "match:16",
              children: [
                {
                  id: "team:L14",
                },
                {
                  id: "match:11",
                  children: [
                    {
                      id: "match:8",
                      children: [
                        {
                          id: "team:L2",
                        },
                        {
                          id: "team:L4",
                        },
                      ],
                    },
                    {
                      id: "match:9",
                      children: [
                        {
                          id: "team:L3",
                        },
                        {
                          id: "team:L5",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              id: "match:15",
              children: [
                {
                  id: "match:12",
                  children: [
                    {
                      id: "team:L6",
                    },
                    {
                      id: "match:10",
                      children: [
                        {
                          id: "team:L1",
                        },
                        {
                          id: "team:L7",
                        },
                      ],
                    },
                  ],
                },
                {
                  id: "team:L13",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
