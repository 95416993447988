import { SkeletonText, Box, VStack } from "@chakra-ui/react";

/**
 * Most Pages should be wrapped with this component.
 * Ensures that all data is available for a page before rendering.
 * @param {boolean} isLoading - Will render a loading skeleton animation if true, else return children.
 */
function PageLoader({ isLoading, numSections = 3, children, ...otherProps }) {
  return isLoading ? (
    <VStack
      spacing={6}
      align="stretch"
      data-testid="page-loader"
      {...otherProps}
    >
      {Array(numSections)
        .fill()
        .map((_, i) => {
          return (
            <Box key={i}>
              <SkeletonText mt="4" noOfLines={4} spacing="4" />
            </Box>
          );
        })}
    </VStack>
  ) : (
    <>{children}</>
  );
}

export default PageLoader;
