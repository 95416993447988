import {
  Box,
  Divider,
  Heading,
  Container,
  Checkbox,
  Input,
  SimpleGrid,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Link,
  Image,
  Text,
  Switch,
  useToast,
  useColorModeValue,
  IconButton,
} from "@chakra-ui/react";
import { Editor } from "@tinymce/tinymce-react";
import { useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import client from "graphql/client";
import { ROUTES } from "constants/routes";
import { useHistory, useParams } from "react-router-dom";
import {
  ADD_SPONSOR_IMAGE_TO_TOURNAMENT,
  CREATE_TOURNAMENT,
  UPDATE_TOURNAMENT,
  UPDATE_MEDIA,
  DELETE_MEDIA,
  ADD_TOURNAMENT_TO_LEAGUE,
} from "graphql/mutations";
import { GET_LEAGUE, GET_TOURNAMENT } from "graphql/queries";
import HeaderBack from "components/HeaderBack";
import PageLoader from "components/PageLoader";
import PriceInput from "components/PriceInput";
import { DURATION } from "constants/duration";
import {
  PRICE_PER_PERSON_INPUT_MAX,
  PRICE_PER_PERSON_INPUT_MIN,
} from "utils/tournament";
import { CloseIcon } from "@chakra-ui/icons";
import { isValidUrl } from "utils/urls";
import TransactionFeeInfo from "components/TransactionFeeInfo";

const MAX_LOGO_WIDTH_PX = 200;
const MAX_LOGO_HEIGHT_PX = 200;

/**
 * Used to create new or update existing tournament.
 */
function TournamentEditPage() {
  const toast = useToast();
  const history = useHistory();
  const linkColor = useColorModeValue("teal.500", "teal.200");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const { leagueId, tournamentId } = useParams();
  const descriptionEditorRef = useRef(null);
  const parkingEditorRef = useRef(null);
  const hiddenFileInput = useRef(null);

  const [createTournament, { loading: isCreatingTournament }] =
    useMutation(CREATE_TOURNAMENT);
  const [addTournamentToLeague, { loading: isAddingTournament }] = useMutation(
    ADD_TOURNAMENT_TO_LEAGUE
  );
  const [updateTournament, { loading: isUpdatingTournament }] =
    useMutation(UPDATE_TOURNAMENT);
  const [addSponsorImageToTournament] = useMutation(
    ADD_SPONSOR_IMAGE_TO_TOURNAMENT
  );

  const [updateMedia] = useMutation(UPDATE_MEDIA);
  const [deleteMedia] = useMutation(DELETE_MEDIA);

  const isNewTournament = tournamentId === "new";

  const { loading: isTournamentLoading, data: tournamentData } = useQuery(
    GET_TOURNAMENT,
    {
      variables: { tournamentId },
      skip: isNewTournament,
    }
  );

  const { loading: isLeagueLoading, data: leagueData } = useQuery(GET_LEAGUE, {
    variables: { leagueId },
  });

  const isMissingLeaguePaypalEmail = !leagueData?.league?.paypalEmail;

  const formFields = {
    isPublished: tournamentData?.tournament?.isPublished ?? false,
    name: tournamentData?.tournament?.name || "",
    description: tournamentData?.tournament?.description || "",
    location: tournamentData?.tournament?.location || "",
    parkingInstructions: tournamentData?.tournament?.parkingInstructions || "",
    pricePerPerson: tournamentData?.tournament?.pricePerPerson || 0,
    priceAfterFirstEvent:
      tournamentData?.tournament?.priceAfterFirstEvent ?? null,
  };

  // Order sponsor images by earliest createdAt first.
  const sponsorImages = (tournamentData?.tournament?.sponsorImages?.edges || [])
    .map(({ node }) => node)
    .sort(
      (mediaA, mediaB) =>
        new Date(mediaA?.createdAt) - new Date(mediaB?.createdAt)
    );

  return (
    <>
      <HeaderBack onCloseRoute={ROUTES.MY_LEAGUE} />
      <Container
        maxW="container.md"
        mt={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}
      >
        <PageLoader isLoading={isTournamentLoading}>
          <Formik
            initialValues={formFields}
            validationSchema={Yup.object({
              name: Yup.string()
                .max(50, "Must be 50 characters or less")
                .required("What's your tournament name?"),
              description: Yup.string().max(
                2000,
                "Must be less than 2000 characters"
              ),
              location: Yup.string().max(
                500,
                "Must be less than 500 characters"
              ),
              parkingInstructions: Yup.string().max(
                1000,
                "Must be less than 1000 characters"
              ),
              pricePerPerson: Yup.number()
                .min(
                  PRICE_PER_PERSON_INPUT_MIN,
                  `Must be greater than $${PRICE_PER_PERSON_INPUT_MIN}`
                )
                .max(
                  PRICE_PER_PERSON_INPUT_MAX,
                  `Must be less than $${PRICE_PER_PERSON_INPUT_MAX}`
                ),
              priceAfterFirstEvent: Yup.number()
                .min(
                  PRICE_PER_PERSON_INPUT_MIN,
                  `Must be greater than $${PRICE_PER_PERSON_INPUT_MIN}`
                )
                .max(
                  PRICE_PER_PERSON_INPUT_MAX,
                  `Must be less than $${PRICE_PER_PERSON_INPUT_MAX}`
                )
                .nullable(true),
            })}
            onSubmit={async (values) => {
              if (isNewTournament) {
                const createTournamentVars = {
                  ...values,
                  leagueId,
                  description: descriptionEditorRef.current?.getContent(),
                  parkingInstructions: parkingEditorRef.current?.getContent(),
                };

                try {
                  const resp = await createTournament({
                    variables: createTournamentVars,
                  });
                  const newTournamentId =
                    resp?.data?.createTournament?.tournament?.objectId;

                  await addTournamentToLeague({
                    variables: {
                      leagueId,
                      tournamentId: newTournamentId,
                    },
                  });

                  toast({
                    title: "Your tournament was successfully created",
                    status: "success",
                    duration: DURATION.MEDIUM,
                    isClosable: true,
                  });

                  history.push(`/my-league/tournaments/${newTournamentId}`);
                } catch (e) {
                  toast({
                    title: e.message,
                    status: "error",
                    duration: DURATION.LONG,
                    isClosable: true,
                  });
                }
              } else {
                const variables = {
                  ...values,
                  tournamentId,
                  description: descriptionEditorRef.current?.getContent(),
                  parkingInstructions: parkingEditorRef.current?.getContent(),
                };

                try {
                  await updateTournament({ variables });

                  toast({
                    title: "Your tournament was successfully updated",
                    status: "success",
                    duration: DURATION.MEDIUM,
                    isClosable: true,
                  });

                  history.push(`/my-league/tournaments/${tournamentId}`);
                } catch (e) {
                  toast({
                    title: e.message,
                    status: "error",
                    duration: DURATION.LONG,
                    isClosable: true,
                  });
                }
              }
            }}
          >
            {({ values }) => (
              <Form>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  mb={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}
                >
                  <Box>
                    <Heading as="h1">
                      {isNewTournament
                        ? "Create tournament"
                        : "Edit tournament"}
                    </Heading>
                  </Box>
                  <Box>
                    <Field name="isPublished">
                      {({ field, form }) => (
                        <FormControl display="flex" alignItems="center">
                          <FormLabel htmlFor="isPublished" mb="0">
                            Published
                          </FormLabel>
                          <Switch
                            {...field}
                            id="isPublished"
                            size="lg"
                            isChecked={field.value}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                </Box>
                <Field name="name">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.name && form.touched.name}
                    >
                      <FormLabel htmlFor="name">
                        Tournament name
                        <Text
                          as="i"
                          color="red.500"
                          fontWeight="normal"
                          fontSize="sm"
                          ml="2"
                        >
                          required
                        </Text>
                      </FormLabel>
                      <Input {...field} id="name" />
                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="location">
                  {({ field, form }) => (
                    <FormControl
                      mt="4"
                      isInvalid={form.errors.location && form.touched.location}
                    >
                      <FormLabel htmlFor="location">Where is it?</FormLabel>
                      <Input {...field} id="location" />
                      <FormHelperText>
                        Full address is preferrable. This will show a Google map
                        on the registration page.
                      </FormHelperText>
                      <FormErrorMessage>
                        {form.errors.location}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="description">
                  {({ field, form }) => {
                    return (
                      <>
                        <FormLabel mt="4" htmlFor="description">
                          Description
                        </FormLabel>
                        {/* For all TinyMCE options see: https://www.tiny.cloud/docs/integrations/react/ */}
                        <Editor
                          apiKey={
                            process.env.REACT_APP_TINY_MCE_RICH_TEXT_API_KEY
                          }
                          onInit={(evt, editor) => {
                            descriptionEditorRef.current = editor;
                          }}
                          initialValue={field.value}
                          init={{
                            height: 300,
                            menubar: false,
                            plugins: ["link"],
                            toolbar:
                              "undo redo | " +
                              "bold italic underline | bullist numlist outdent indent | link",
                          }}
                        />
                      </>
                    );
                  }}
                </Field>

                <Field name="parkingInstructions">
                  {({ field, form }) => {
                    return (
                      <>
                        <FormLabel mt="4" htmlFor="parkingInstructions">
                          Parking instructions
                        </FormLabel>
                        <Editor
                          apiKey={
                            process.env.REACT_APP_TINY_MCE_RICH_TEXT_API_KEY
                          }
                          onInit={(evt, editor) =>
                            (parkingEditorRef.current = editor)
                          }
                          initialValue={field.value}
                          init={{
                            height: 200,
                            menubar: false,
                            plugins: ["link"],
                            toolbar:
                              "undo redo | " +
                              "bold italic underline | bullist numlist outdent indent | link",
                          }}
                        />
                      </>
                    );
                  }}
                </Field>

                <Field name="pricePerPerson">
                  {({ field, form }) => {
                    const onChange = (value) => {
                      const numberValue = Number(value);
                      form.setFieldValue(field.name, numberValue);
                      if (numberValue === 0) {
                        form.setFieldValue("priceAfterFirstEvent", null);
                      }
                    };

                    return (
                      <FormControl
                        mt="4"
                        isInvalid={
                          form.errors.pricePerPerson &&
                          form.touched.pricePerPerson
                        }
                      >
                        <FormLabel htmlFor="pricePerPerson">
                          Price per person
                        </FormLabel>

                        <PriceInput
                          isDisabled={isMissingLeaguePaypalEmail}
                          value={field.value}
                          onChange={onChange}
                        />
                        {values.pricePerPerson > 0 && (
                          <FormHelperText>
                            <TransactionFeeInfo
                              pricePerPerson={values.pricePerPerson}
                            />
                          </FormHelperText>
                        )}
                        {!isLeagueLoading && isMissingLeaguePaypalEmail && (
                          <FormHelperText>
                            Paid tournaments require a league PayPal email to be
                            set up. Please set this at{" "}
                            <Link
                              href={`/leagues/${leagueId}`}
                              target="_blank"
                              color={linkColor}
                            >
                              Edit league
                            </Link>
                            .
                          </FormHelperText>
                        )}
                        <FormErrorMessage>
                          {form.errors.pricePerPerson}
                        </FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>

                <Field name="priceAfterFirstEvent">
                  {({ field, form }) => {
                    const onChange = (value) => {
                      form.setFieldValue(field.name, Number(value));
                    };

                    return (
                      <>
                        <Checkbox
                          isChecked={field.value !== null}
                          onChange={() => {
                            if (field.value === null) {
                              form.setFieldValue(
                                field.name,
                                Number(values.pricePerPerson)
                              );
                            } else {
                              form.setFieldValue(field.name, null);
                            }
                          }}
                          mt="3"
                        >
                          Offer discount for additional registrations
                        </Checkbox>
                        <FormControl
                          d={field.value === null ? "none" : "block"}
                          mt="4"
                          isInvalid={
                            form.errors.priceAfterFirstEvent &&
                            form.touched.priceAfterFirstEvent
                          }
                        >
                          <FormLabel htmlFor="priceAfterFirstEvent">
                            Price for each additional event
                          </FormLabel>

                          <PriceInput
                            isDisabled={isMissingLeaguePaypalEmail}
                            value={field.value}
                            onChange={onChange}
                          />
                          <FormHelperText>
                            This is the price per person for each additional
                            event.
                          </FormHelperText>
                          {field.value > 0 && (
                            <FormHelperText>
                              <TransactionFeeInfo
                                pricePerPerson={field.value}
                              />
                            </FormHelperText>
                          )}
                          <FormErrorMessage>
                            {form.errors.priceAfterFirstEvent}
                          </FormErrorMessage>
                        </FormControl>
                      </>
                    );
                  }}
                </Field>

                <Button
                  mt={4}
                  w="100%"
                  colorScheme="teal"
                  isLoading={
                    isCreatingTournament ||
                    isAddingTournament ||
                    isUpdatingTournament
                  }
                  type="submit"
                >
                  {isNewTournament ? "Create" : "Update"}
                </Button>

                <Button
                  mt={4}
                  w="100%"
                  onClick={() => {
                    history.push(`/my-league/tournaments/${tournamentId}`);
                  }}
                >
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>

          <Divider my="20" />

          {/* TODO: Maybe change this behavior so logos aren't automatically uploaded as soon as they're selected. */}
          {/* What if user makes a mistake? Currently they instantly go live. */}
          <Box>
            <Heading as="h2" mb="2">
              Sponsor logos
            </Heading>
            <Text>
              {`Images must be ${MAX_LOGO_WIDTH_PX}px by ${MAX_LOGO_HEIGHT_PX}px or less, ideally on a white or
              transparent background.`}
            </Text>

            <SimpleGrid columns={4} spacing="3" mt="3">
              {sponsorImages.map((media) => {
                const isValidWebsite = isValidUrl(media.website);

                return (
                  <Box
                    key={media?.file?.url}
                    border="1px solid"
                    borderColor={borderColor}
                    p="3"
                    pos="relative"
                    flexDir="column"
                    borderRadius="md"
                    bgColor="white"
                    overflow="hidden"
                  >
                    <Box
                      d="flex"
                      h="100px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image
                        src={media?.file?.url}
                        alt={`logo for ${media?.website}`}
                        maxH="100px"
                        maxW="100px"
                      />
                    </Box>
                    <Input
                      placeholder="https://mysite.com"
                      value={media?.website || ""}
                      onChange={(e) => {
                        const newVal = e.target.value;

                        client.cache.modify({
                          id: `Media:${media.objectId}`,
                          fields: {
                            website() {
                              return newVal;
                            },
                          },
                        });
                      }}
                      onBlur={() => {
                        if (isValidWebsite) {
                          updateMedia({
                            variables: {
                              mediaId: media.objectId,
                              website: media.website,
                            },
                          }).then(() => {
                            toast({
                              title: "Website saved",
                              status: "success",
                              duration: DURATION.SHORT,
                              isClosable: true,
                            });
                          });
                        }
                      }}
                    />
                    {!isValidWebsite && (
                      <Text fontSize="sm" color="red">
                        Please add valid url
                      </Text>
                    )}
                    <IconButton
                      minW="none"
                      w="24px"
                      h="24px"
                      mt="3"
                      mr="2"
                      pos="absolute"
                      top="0"
                      right="0"
                      aria-label="Delete image"
                      variant="link"
                      color="black"
                      icon={<CloseIcon />}
                      onClick={() => {
                        deleteMedia({
                          variables: {
                            mediaId: media.objectId,
                          },
                        }).then(() => {
                          client.cache.evict({ id: `Media:${media.objectId}` });
                          client.cache.gc();
                        });
                      }}
                    />
                  </Box>
                );
              })}
              <Button
                h="100%"
                minH="140px"
                onClick={() => {
                  hiddenFileInput.current.click();
                }}
              >
                + Add Logo
              </Button>
            </SimpleGrid>

            <Input
              ref={hiddenFileInput}
              d="none"
              type="file"
              accept="image/*"
              onChange={({
                target: {
                  validity,
                  files: [file],
                },
              }) => {
                // create an image element with that selected file
                const img = new window.Image();
                img.src = window.URL.createObjectURL(file);
                img.onload = function () {
                  const imgWidth = img.naturalWidth;
                  const imgHeight = img.naturalHeight;

                  // unload it
                  window.URL.revokeObjectURL(img.src);

                  // check its dimensions
                  if (
                    imgWidth <= MAX_LOGO_WIDTH_PX &&
                    imgHeight <= MAX_LOGO_HEIGHT_PX &&
                    validity.valid
                  ) {
                    addSponsorImageToTournament({
                      variables: {
                        tournamentId,
                        file,
                      },
                    });
                  } else {
                    toast({
                      title: `Images must be ${MAX_LOGO_WIDTH_PX}px by ${MAX_LOGO_HEIGHT_PX}px or less.`,
                      status: "error",
                      duration: DURATION.MEDIUM,
                      isClosable: true,
                    });
                  }
                };
              }}
            />
          </Box>
        </PageLoader>
      </Container>
    </>
  );
}

export default TournamentEditPage;
