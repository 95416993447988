import { useQuery } from "@apollo/client";
import { Box, Heading } from "@chakra-ui/react";
import { GET_PAST_TOURNAMENTS } from "graphql/queries";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { groupTournamentEventsByDate } from "utils/formatters";
import CardLoader from "./CardLoader";
import TournamentsList from "./TournamentsList";

function PastTournaments() {
  const history = useHistory();

  // Note passing new Date() to useQuery() will result in infinite render loop because
  // it will be different on each render. To prevent this, remove the time data with setHours.
  const todayWithoutTime = new Date();
  todayWithoutTime.setHours(0, 0, 0, 0);

  const { loading: isLoadingPastTournaments, data: pastTournaments } = useQuery(
    GET_PAST_TOURNAMENTS,
    {
      variables: {
        startTime: todayWithoutTime,
      },
    }
  );

  const tournaments = (pastTournaments?.tournaments?.edges || [])
    .map(({ node }) => groupTournamentEventsByDate(node))
    .sort((a, b) => new Date(b.lastEventTime) - new Date(a.lastEventTime));

  return (
    <Box>
      <Heading as="h3" fontSize="x-large" mb="4">
        Past tournaments
      </Heading>
      <CardLoader
        numSections={1}
        noOfLines={4}
        isLoading={isLoadingPastTournaments}
      >
        <TournamentsList
          tournaments={tournaments}
          onClick={(tournament) => {
            history.push(`/tournaments/${tournament?.objectId}/register`);
          }}
        />
      </CardLoader>
    </Box>
  );
}

export default PastTournaments;
