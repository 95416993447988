import { Badge } from "@chakra-ui/react";

function TournamentStatus({ tournament, ...styleProps }) {
  console.log(tournament?.isPublished);
  return tournament?.isPublished ? (
    <Badge {...styleProps} colorScheme="green">
      Published
    </Badge>
  ) : (
    <Badge {...styleProps}>Not published</Badge>
  );
}

export default TournamentStatus;
