import { Box } from "@chakra-ui/react";
import { getTeamPlayerNames } from "utils/formatters";
import MatchCard from "components/MatchCard";
import "./BracketViewer.scss";
import MatchInput from "components/MatchInput";
import { MATCH_NODE_PREFIX, TEAM_NODE_PREFIX } from "utils/constants";

function Item({
  node,
  onUpdateGame,
  onAddGame,
  onDeleteGame,
  isEditMode,
  gamesMap,
  teamsMap,
}) {
  const { id, teamId, children } = node;

  if (children) {
    return (
      <Box className="item">
        <Box className="item-parent">
          <MatchInput
            gamesMap={gamesMap}
            teamsMap={teamsMap}
            matchNode={node}
            onUpdateGame={onUpdateGame}
            onAddGame={onAddGame}
            onDeleteGame={onDeleteGame}
            isEditMode={isEditMode}
          />
        </Box>
        <Box className="item-childrens">
          {children.map((node, i) => (
            <Box className="item-child" key={i}>
              <Item
                gamesMap={gamesMap}
                teamsMap={teamsMap}
                node={node}
                onUpdateGame={onUpdateGame}
                onAddGame={onAddGame}
                onDeleteGame={onDeleteGame}
                isEditMode={isEditMode}
              />
            </Box>
          ))}
        </Box>
      </Box>
    );
  } else {
    // Team node
    let teamNodeText = "";
    const team = teamsMap[teamId];
    if (team) {
      teamNodeText = `${id.slice(5)}) ${getTeamPlayerNames(team)}`;
    } else if (id.includes("L")) {
      teamNodeText = `Loser of Match ${id.slice(MATCH_NODE_PREFIX.length)}`;
    } else {
      teamNodeText = `Team ${id.slice(TEAM_NODE_PREFIX.length)}`;
    }

    return (
      <Box className="item-childrens">
        <Box className="item-child">
          <MatchCard w="300px">{teamNodeText}</MatchCard>
        </Box>
      </Box>
    );
  }
}

/**
 * @param {Object} rootNode
 * @param {Function} onUpdateGame
 * @param {Function} onAddGame
 * @param {Function} onDeleteGame
 * @param {boolean} isEditMode - If game scores are allowed to be edited.
 */
function BracketViewer({
  rootNode = {},
  onUpdateGame = () => {},
  onAddGame = () => {},
  onDeleteGame = () => {},
  isEditMode = false,
  gamesMap = {},
  teamsMap = {},
}) {
  return (
    <Box d="flex">
      <Item
        node={rootNode}
        onUpdateGame={onUpdateGame}
        onAddGame={onAddGame}
        onDeleteGame={onDeleteGame}
        isEditMode={isEditMode}
        gamesMap={gamesMap}
        teamsMap={teamsMap}
      />
    </Box>
  );
}

export default BracketViewer;
