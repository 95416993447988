import { Modal, ModalOverlay } from "@chakra-ui/react";
import InvitePartnerModalContent from "components/InvitePartnerModalContent";
import PropTypes from "prop-types";

/**
 * Modal which allow a player to send an invite to join their team.
 * @param {Object} league - User object from Parse-GraphQL
 * @param {Object} tournament - Tournament object from Parse-GraphQL
 * @param {Object} player - Player object from Parse-GraphQL
 * @param {boolean} isOpen - Is InvitePartnerModal open or not
 * @param {Function} onClose - Call to close InvitePartnerModal
 */
function InvitePartnerModal({ league, tournament, event, isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <InvitePartnerModalContent
        league={league}
        tournament={tournament}
        event={event}
        onClose={onClose}
        onComplete={onClose}
      />
    </Modal>
  );
}

InvitePartnerModal.propTypes = {
  league: PropTypes.object.isRequired,
  tournament: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InvitePartnerModal;
