import React from "react";
import { Helmet } from "react-helmet-async";

export default function HelmetMetaData({
  title,
  quote,
  image,
  description,
  hashtag,
  viewport,
}) {
  const currentUrl = window.location.href;
  const metaTitle = title || "DinkPal - Pickleball tournaments made easy";
  const metaQuote = quote || "";
  const metaImage = image || `${process.env.REACT_APP_DOMAIN}/dinkpal_logo.png`;
  const metaDescription =
    description ||
    "DinkPal lets you create free and paid pickleball tournaments quickly to with the public. Our goal is to create virtually self-running tournaments so people can spend more time on the courts and less time running touraments.";
  const metaHashtag = hashtag || "#dinkpal #dinkpal-tournaments";
  const metaViewport =
    viewport || "width=device-width, initial-scale=1.0, user-scalable=no";

  return (
    <Helmet>
      <title>{title}</title>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="csrf_token" content="" />
      <meta property="type" content="website" />
      <meta name="viewport" content={metaViewport} />
      <meta property="url" content={currentUrl} />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="_token" content="" />
      <meta name="robots" content="noodp" />
      <meta property="title" content={metaTitle} />
      <meta property="quote" content={metaQuote} />
      <meta name="description" content={metaDescription} />
      <meta property="image" content={metaImage} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:quote" content={metaQuote} />
      <meta property="og:hashtag" content={metaHashtag} />
      <meta property="og:image:" content={metaImage} />
      <meta property="og:image:url" content={metaImage} />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content="DinkPal" />
      <meta property="og:description" content={metaDescription} />
    </Helmet>
  );
}
