import {
  Link,
  Textarea,
  Text,
  Heading,
  Container,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VisuallyHidden,
  useToast,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { ROUTES } from "constants/routes";
import { useHistory } from "react-router-dom";
import { SEND_FEEDBACK } from "graphql/mutations";
import HeaderBack from "components/HeaderBack";
import { DURATION } from "constants/duration";
import TextDivider from "components/TextDivider";

/**
 * Contact Us Page. Also contains an input to send anonymous feedback.
 */
function ContactUsPage() {
  const toast = useToast();
  const history = useHistory();

  // Send Feedback
  const [sendFeedback, { loading: isSendingFeedback }] =
    useMutation(SEND_FEEDBACK);

  return (
    <>
      <HeaderBack />
      <Container
        maxW="container.md"
        mt={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}
      >
        <Heading as="h1" mb={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}>
          Contact Us
        </Heading>
        <Text>
          Questions, comments, concerns? Reach out at{" "}
          <Link
            color="teal"
            fontWeight="bold"
            href="mailto:dinkpalpickleball@gmail.com"
          >
            dinkpalpickleball@gmail.com
          </Link>
        </Text>

        <TextDivider text="or" my="20" />

        <Text>You can also submit anonymous feedback in the form below.</Text>

        <Formik
          initialValues={{
            comments: "",
          }}
          validationSchema={Yup.object({
            comments: Yup.string()
              .max(2000, "Must be less than 2000 characters")
              .required("Please enter your feedback"),
          })}
          onSubmit={({ comments }) => {
            sendFeedback({ variables: { comments } })
              .then(() => {
                toast({
                  title: "Thanks for your feedback.",
                  status: "success",
                  duration: DURATION.MEDIUM,
                  isClosable: true,
                });
                history.push(ROUTES.ACCOUNT_SETTINGS);
              })
              .catch(() => {
                toast({
                  title: "Sorry, there was a problem sending feedback.",
                  status: "error",
                  duration: DURATION.LONG,
                  isClosable: true,
                });
              });
          }}
        >
          <Form>
            <Field name="comments">
              {({ field, form }) => (
                <FormControl
                  mt="4"
                  isInvalid={form.errors.comments && form.touched.comments}
                >
                  <VisuallyHidden>
                    <FormLabel htmlFor="comments">Comments</FormLabel>
                  </VisuallyHidden>
                  <Textarea {...field} id="comments" />
                  <FormErrorMessage>{form.errors.comments}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Button
              mt={4}
              w="100%"
              colorScheme="teal"
              isLoading={isSendingFeedback}
              type="submit"
            >
              Send
            </Button>
          </Form>
        </Formik>
      </Container>
    </>
  );
}

export default ContactUsPage;
