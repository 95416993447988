import { Container, Text, Box, Heading } from "@chakra-ui/react";

const FORGETFUL_QUOTES = [
  "What's the score again?",
  "Am I a one or a two?",
  "Who just served?",
];

function getRandom(array) {
  return array[Math.floor(Math.random() * array.length)];
}

function NotFoundPage() {
  return (
    <Container
      maxW="container.lg"
      my={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}
      py={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}
    >
      <Box
        d="flex"
        justifyContent="center"
        flexDir="column"
        alignItems="center"
        textAlign="center"
        mt="15vh"
      >
        <Heading
          fontSize={["8em", "8em", "12em"]}
          opacity="50%"
          userSelect="none"
        >
          404
        </Heading>
        <Heading
          fontSize={["2em", "2em", "4em"]}
          opacity="50%"
          userSelect="none"
        >
          Page not found
        </Heading>
        <Text
          fontSize={["1em", "1em", "2em"]}
          fontWeight="bold"
          mt="6"
          opacity="50%"
          userSelect="none"
        >
          "{getRandom(FORGETFUL_QUOTES)}"
        </Text>
      </Box>
    </Container>
  );
}

export default NotFoundPage;
