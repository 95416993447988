import { Radio, RadioGroup } from "@chakra-ui/react";
import { SKILL_LEVEL_OPTIONS_LIST } from "utils/event";

function SkillLevelRadioGroup({ value, onChange }) {
  return (
    <RadioGroup onChange={onChange} value={value}>
      {SKILL_LEVEL_OPTIONS_LIST.map((option) => (
        <Radio pr="4" colorScheme="teal" value={option} key={option}>
          {option}
        </Radio>
      ))}
    </RadioGroup>
  );
}

export default SkillLevelRadioGroup;
