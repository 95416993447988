import { useAuthContext } from "context/AuthContext";
import { ROUTES } from "constants/routes";
import { Redirect, Route } from "react-router-dom";

function AuthenticatedRoute({ children, ...rest }) {
  const { isAuthenticated } = useAuthContext();

  return (
    <Route
      {...rest}
      render={() =>
        isAuthenticated() ? <>{children}</> : <Redirect to={ROUTES.HOME} />
      }
    />
  );
}

export default AuthenticatedRoute;
