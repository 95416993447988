import { Button, Icon, useToast } from "@chakra-ui/react";
import { DURATION } from "constants/duration";
import { useAuthContext } from "context/AuthContext";
import { BsFacebook } from "react-icons/bs";
import { useState } from "react";
import { CONTINUE_WITH_FACEBOOK } from "graphql/mutations";
import { useMutation } from "@apollo/client";
import { isMobileSafari } from "utils/browsers";

function FacebookLoginButton({ onSuccess }) {
  const toast = useToast();
  const { setAuthState } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [continueWithFacebook] = useMutation(CONTINUE_WITH_FACEBOOK);

  function logIntoDinkPal(fbAccessToken) {
    continueWithFacebook({ variables: { fbAccessToken } })
      .then((resp) => {
        setIsLoading(false);
        const { user, sessionToken } = resp?.data?.ContinueWithFacebook;

        setAuthState({
          sessionToken,
          user,
        });

        onSuccess();
      })
      .catch((err) => {
        setIsLoading(false);
        toast({
          title:
            "Sorry, there was a problem logging in with Facebook. Please try again later.",
          status: "error",
          duration: DURATION.LONG,
          isClosable: true,
        });
      });
  }

  function logInToFacebook(onFacebookLogin) {
    window.FB.login(
      (response) => {
        const fbAccessToken = response?.authResponse?.accessToken;
        onFacebookLogin(fbAccessToken);
      },
      { scope: "public_profile,email" }
    );
  }

  return (
    <>
      <Button
        w="100%"
        colorScheme="blue"
        isLoading={isLoading}
        onClick={() => {
          setIsLoading(true);

          if (isMobileSafari()) {
            // Mobile Safari will block the new window from opening on first click attempt if calling FB.login inside the FB.getLoginStatus callback function.
            // So, call FB.login first.
            logInToFacebook((fbToken) => {
              logIntoDinkPal(fbToken);
            });
          } else {
            // Most other browsers like checking login status first before calling FB.login.
            window.FB.getLoginStatus((resp) => {
              if (
                resp.status === "connected" &&
                resp.authResponse.accessToken
              ) {
                logIntoDinkPal(resp.authResponse.accessToken);
              } else {
                logInToFacebook((fbToken) => {
                  logIntoDinkPal(fbToken);
                });
              }
            });
          }
        }}
      >
        <Icon as={BsFacebook} w={5} h={5} mr="2" />
        Continue with Facebook
      </Button>
    </>
  );
}

export default FacebookLoginButton;
