import { SkeletonText, Box, VStack, useColorModeValue } from "@chakra-ui/react";

/**
 * @param {boolean} isLoading - Will render a loading skeleton animation if true, else return children.
 */
function CardLoader({ isLoading, numSections = 3, noOfLines = 4, children }) {
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const eventCardBgColor = useColorModeValue("white", "gray.900");

  return isLoading ? (
    <VStack
      borderRadius="md"
      border="1.5px solid"
      borderColor={borderColor}
      backgroundColor={eventCardBgColor}
      p="20px"
      w="100%"
      spacing={6}
      align="stretch"
      data-testid="page-loader"
    >
      {Array(numSections)
        .fill()
        .map((_, i) => {
          return (
            <Box key={i}>
              <SkeletonText noOfLines={noOfLines} spacing="4" />
            </Box>
          );
        })}
    </VStack>
  ) : (
    <>{children}</>
  );
}

export default CardLoader;
