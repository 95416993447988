// eslint-disable-next-line import/no-anonymous-default-export
export default {
  id: "match:4",
  children: [
    {
      id: "match:2",
      loserTo: "team:L2",
      children: [
        {
          id: "team:1",
        },
        {
          id: "match:1",
          loserTo: "team:L1",
          children: [
            {
              id: "team:2",
            },
            {
              id: "team:3",
            },
          ],
        },
      ],
    },
    {
      id: "match:3",
      children: [
        {
          id: "team:L1",
        },
        {
          id: "team:L2",
        },
      ],
    },
  ],
};
