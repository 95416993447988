import { Box } from "@chakra-ui/react";

import "./Pulse.css";

function Pulse({ children, ...styleProps }) {
  return (
    <Box className="pulse" {...styleProps}>
      {children}
    </Box>
  );
}

export default Pulse;
