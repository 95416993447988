import { SimpleGrid, Text, useRadioGroup } from "@chakra-ui/react";
import RadioCard from "components/RadioCard";
import {
  SUPPORTED_EVENT_FORMAT_OPTIONS_LIST,
  EVENT_FORMAT_OPTION_ICONS,
  EVENT_FORMAT_OPTION_NAMES,
  EVENT_FORMAT_OPTION_DESCRIPTIONS,
} from "utils/event";

function EventTypeRadioGroup({ name, value, onChange, onBlur, disabled }) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue: value,
    onChange,
  });
  const group = getRootProps();
  const mergedProps = { ...group, name, value, onChange, onBlur };

  return (
    <>
      <SimpleGrid columns={3} columnGap={4} rowGap={4} {...mergedProps}>
        {SUPPORTED_EVENT_FORMAT_OPTIONS_LIST.map((option) => {
          const radio = getRadioProps({ value: option });
          const FormatIcons = EVENT_FORMAT_OPTION_ICONS[option];
          return (
            <RadioCard {...radio} key={option} disabled={disabled}>
              <FormatIcons />
              <Text fontSize={[12, 12, 16]} textAlign="center">
                {EVENT_FORMAT_OPTION_NAMES[option]}
              </Text>
            </RadioCard>
          );
        })}
      </SimpleGrid>
      <Text mt="3">{EVENT_FORMAT_OPTION_DESCRIPTIONS[value].text}</Text>
    </>
  );
}

export default EventTypeRadioGroup;
