import { Button, useToast } from "@chakra-ui/react";
import {
  SEND_ACCEPT_INVITE,
  ADD_NEW_PLAYER_TO_TEAM,
  ADD_EXISTING_PLAYER_TO_TEAM,
  DELETE_TEAM,
} from "graphql/mutations";
import { useMutation } from "@apollo/client";
import {
  formatEventName,
  formatFullDateTime,
  isUserInTeam,
  getPlayerFromEvent,
  getTeamByUserId,
  doesTeamHaveRequiredPlayers,
} from "utils/formatters";
import PropTypes from "prop-types";
import { DURATION } from "constants/duration";

function InviteAcceptButton({
  accepterId,
  inviterId,
  inviterFullName,
  event,
  tournament,
  league,
  team,
  onComplete,
  onButtonClick,
  refetchQueries,
}) {
  const toast = useToast();
  const tournamentName = tournament?.name;
  const leagueName = league?.name;
  const eventName = formatEventName(event);
  const eventDateTime = formatFullDateTime(event?.eventStartTime);

  const [sendAcceptInvite, { loading: isSendingAcceptInvite }] = useMutation(
    SEND_ACCEPT_INVITE,
    {
      variables: {
        accepterId,
        inviterId,
        tournamentName,
        leagueName,
        eventName,
        eventDateTime,
      },
    }
  );

  const [addNewPlayerToTeam, { loading: isAddingNewPlayerToTeam }] =
    useMutation(ADD_NEW_PLAYER_TO_TEAM, { refetchQueries });
  const [addExistingPlayerToTeam, { loading: isAddingExistingPlayerToTeam }] =
    useMutation(ADD_EXISTING_PLAYER_TO_TEAM, { refetchQueries });
  const [deleteExistingTeam, { loading: isDeletingExistingTeam }] = useMutation(
    DELETE_TEAM,
    { refetchQueries }
  );

  const isLoading =
    isSendingAcceptInvite ||
    isAddingNewPlayerToTeam ||
    isAddingExistingPlayerToTeam ||
    isDeletingExistingTeam;

  return (
    <Button
      size="lg"
      width="100%"
      colorScheme="teal"
      variant="solid"
      isLoading={isLoading}
      onClick={() => {
        // Inform parent
        onButtonClick();

        if (!accepterId) {
          return;
        }

        if (isUserInTeam(accepterId, team)) {
          toast({
            title: "You are already on this team.",
            status: "info",
            duration: DURATION.MEDIUM,
            isClosable: true,
          });
          return;
        }

        if (doesTeamHaveRequiredPlayers(team, event)) {
          toast({
            title: "Sorry, this team's already full!",
            status: "error",
            duration: DURATION.MEDIUM,
            isClosable: true,
          });
          return;
        }

        // Check if accepter is already on *another* team
        // If so, move that player into inviter team and don't create a new player.
        const existingTeam = getTeamByUserId(accepterId, event);
        const maybeDeleteTeam = existingTeam
          ? deleteExistingTeam
          : () => Promise.resolve();

        const existingPlayer = getPlayerFromEvent(accepterId, event);
        const addPlayerToTeam = existingPlayer
          ? addExistingPlayerToTeam
          : addNewPlayerToTeam;
        const variables = existingPlayer
          ? {
              teamId: team?.objectId,
              playerId: existingPlayer?.objectId,
            }
          : {
              teamId: team?.objectId,
              userId: accepterId,
            };

        maybeDeleteTeam({ variables: { teamId: existingTeam?.objectId } })
          .then(() => {
            return addPlayerToTeam({ variables });
          })
          .then(() => {
            return sendAcceptInvite();
          })
          .then(() => {
            toast({
              title: `You are now partnered with ${inviterFullName}. We sent them an email letting them know you accepted their invite.`,
              status: "success",
              duration: DURATION.LONG,
              isClosable: true,
            });
            onComplete(existingPlayer);
          })
          .catch((e) => {
            toast({
              title:
                "Sorry, there was a problem accepting this invite, please try again later.",
              status: "error",
              duration: DURATION.LONG,
              isClosable: true,
            });
          });
      }}
    >
      Accept
    </Button>
  );
}

InviteAcceptButton.propTypes = {
  accepterId: PropTypes.string,
  inviterId: PropTypes.string,
  event: PropTypes.object,
  tournament: PropTypes.object,
  league: PropTypes.object,
  team: PropTypes.object,
  onComplete: PropTypes.func,
  refetchQueries: PropTypes.array,
};

export default InviteAcceptButton;
