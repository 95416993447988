import { Box, useColorModeValue } from "@chakra-ui/react";

function MatchCard({ children, ...styles }) {
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const cardBgColor = useColorModeValue("gray.100", "gray.900");

  return (
    <Box
      border="1px solid"
      borderColor={borderColor}
      background={cardBgColor}
      borderRadius="md"
      p="3"
      {...styles}
    >
      {children}
    </Box>
  );
}

export default MatchCard;
