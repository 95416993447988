import { Text, Box, useColorModeValue } from "@chakra-ui/react";

function TextDivider({ text, ...styleProps }) {
  const lineColor = useColorModeValue("gray.400", "gray.600");
  const pseudoStyles = {
    content: '""',
    height: "1px",
    backgroundColor: lineColor,
    flexGrow: 1,
  };

  return (
    <Box
      {...styleProps}
      d="flex"
      alignItems="center"
      opacity={0.8}
      _before={{
        ...pseudoStyles,
        marginRight: "1rem",
      }}
      _after={{
        ...pseudoStyles,
        marginLeft: "1rem",
      }}
    >
      <Text fontWeight="bold" opacity={0.8}>
        {text}
      </Text>
    </Box>
  );
}

export default TextDivider;
