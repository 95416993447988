import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Button, VStack, Text, useColorModeValue } from "@chakra-ui/react";
import { formatTournamentDateRange } from "utils/formatters";

/**
 * Renders list of tournament objects.
 * @param {<Tournament>[]} tournaments - Array of tournament objects.
 * @param {function} onClick - Called when clicking on a tournament.
 * @param {function} yieldRight - Function called on each tournament to render right most section.
 */
function TournamentsList({ tournaments, onClick, yieldRight }) {
  const bgColor = useColorModeValue("white", "gray.900");

  return (
    <VStack spacing="4" align="stretch">
      {tournaments.map((tournament) => {
        const tournamentDateRange = formatTournamentDateRange(
          tournament?.events
        );

        return (
          <Button
            key={tournament.objectId}
            onClick={() => onClick(tournament)}
            variant="outline"
            lineHeight="unset"
            height="unset"
            backgroundColor={bgColor}
            p="4"
            d="flex"
            whiteSpace="unset"
          >
            <Box display="flex" justifyContent="space-between" flex="1">
              <Box d="flex" flexDir="column">
                {tournamentDateRange && (
                  <Text textAlign="left" mr="4" mb="4">
                    {tournamentDateRange}
                  </Text>
                )}
                <Text textAlign="left" fontWeight="semibold" fontSize="xl">
                  {tournament?.name}
                </Text>
                <Text textAlign="left" fontSize="sm" fontWeight="normal">
                  {tournament?.location}
                </Text>
              </Box>
              {typeof yieldRight === "function" && (
                <Box>{yieldRight(tournament)}</Box>
              )}
            </Box>
            <Box
              d="flex"
              flex="0 0 50px"
              justifyContent="center"
              alignItems="center"
            >
              <ChevronRightIcon w="30px" h="30px" />
            </Box>
          </Button>
        );
      })}
    </VStack>
  );
}

export default TournamentsList;
