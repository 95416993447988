import {
  Heading,
  Container,
  Link,
  Box,
  Text,
  VStack,
  Stack,
  Badge,
  Image,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_TOURNAMENT_EVENTS } from "graphql/queries";
import PageLoader from "components/PageLoader";
import {
  getTournamentTotals,
  groupTournamentEventsByDate,
  formatTournamentDateRange,
  formatPrice,
} from "utils/formatters";
import EventCard from "components/EventCard";
import TextExpander from "components/TextExpander";
import NotFoundPage from "pages/NotFoundPage";
import SocialShareButtons from "components/SocialShareButtons";
import HelmetMetaData from "components/HelmetMetaData";
import "./TournamentRegisterPage.css";
import { useHashFragment } from "utils/hooks";

/**
 * ***Public Page***
 * Used for unauthenticated people to view and sign up for tournaments.
 * - Logged in users will see "Register" buttons
 * - Non-logged in users will see "Sign up & register" buttons
 */
function TournamentRegisterPage() {
  const { tournamentId } = useParams();

  const heroBgColor = useColorModeValue("teal", "gray.800");
  const gMapsIframeFilter = useColorModeValue("none", "invert(90%)");

  const { data: tournamentEventsData, loading: isTournamentEventsLoading } =
    useQuery(GET_TOURNAMENT_EVENTS, {
      variables: { tournamentId },
    });

  // scroll to section fragment if available
  useHashFragment(0, !!tournamentEventsData);

  if (!isTournamentEventsLoading && !tournamentEventsData) {
    return <NotFoundPage />;
  }

  const tournament = groupTournamentEventsByDate(
    tournamentEventsData?.tournament
  );
  const league = tournament?.league;
  const leagueName = league?.name;
  const leagueEmail = league?.paypalEmail;
  const hasEvents = !!tournament?.eventsByDate?.length;
  const tournamentName = tournament?.name;
  const tournamentDateRange = formatTournamentDateRange(tournament?.events);

  const { numRegistrations, maxRegistrations } =
    getTournamentTotals(tournament);

  // Order sponsor images by earliest createdAt first.
  const sponsorImages = (tournament?.sponsorImages?.edges || [])
    .map(({ node }) => node)
    .sort(
      (mediaA, mediaB) =>
        new Date(mediaA?.createdAt) - new Date(mediaB?.createdAt)
    );

  return (
    <Box pb={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}>
      <Box d="flex" w="100%" backgroundColor={heroBgColor}>
        <Container maxW="container.lg" py="10">
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
            <Box>
              <PageLoader numSections={2} isLoading={isTournamentEventsLoading}>
                <HelmetMetaData
                  title={`${tournamentDateRange} - ${tournamentName} hosted by ${leagueName}`}
                />
                <Heading as="h1" mb="1" color="white">
                  {tournamentName}
                </Heading>
                <Stack direction={["column", "row"]} mb="4" spacing="2">
                  {Number.isInteger(tournament.pricePerPerson) && (
                    <Box>
                      <Badge colorScheme="orange" variant="solid" fontSize="sm">
                        {formatPrice(tournament.pricePerPerson)}
                      </Badge>
                    </Box>
                  )}
                  {tournament.pricePerPerson !== 0 &&
                    Number.isInteger(tournament.priceAfterFirstEvent) && (
                      <Box>
                        <Badge
                          colorScheme="yellow"
                          variant="solid"
                          fontSize="sm"
                        >
                          {formatPrice(tournament.priceAfterFirstEvent)} each
                          additional event
                        </Badge>
                      </Box>
                    )}
                </Stack>
                <Text mb="4" fontWeight="bold" color="white" fontSize="xl">
                  {numRegistrations} out of {maxRegistrations} registrations
                </Text>
                <Text mb="4" fontWeight="bold" color="white">
                  {tournamentDateRange}
                </Text>
                <SocialShareButtons mb="4" />
                <Text mb="4" fontWeight="bold" color="white">
                  {tournament?.location}
                </Text>
                {leagueEmail && (
                  <Text mb="4" fontWeight="bold" color="white">
                    League contact:{" "}
                    <Link href={`mailto:${leagueEmail}`}>{leagueEmail}</Link>
                  </Text>
                )}

                {tournament?.description && (
                  <>
                    <Text color="white" fontWeight="bold" mt="4">
                      Tournament info
                    </Text>
                    <TextExpander>
                      <Text
                        className="trp__description"
                        wordwrap="break-word"
                        wordBreak="break-word"
                        dangerouslySetInnerHTML={{
                          __html: tournament?.description,
                        }}
                        color="white"
                      ></Text>
                    </TextExpander>
                  </>
                )}
                {tournament?.parkingInstructions && (
                  <>
                    <Text color="white" fontWeight="bold" mt="4">
                      Parking instructions
                    </Text>
                    <TextExpander>
                      <Text
                        className="trp__parking"
                        wordwrap="break-word"
                        wordBreak="break-word"
                        dangerouslySetInnerHTML={{
                          __html: tournament?.parkingInstructions,
                        }}
                        color="white"
                      ></Text>
                    </TextExpander>
                  </>
                )}
              </PageLoader>
            </Box>
            <Box>
              {tournament?.location && (
                <Box borderRadius="md" overflow="hidden" height={[300, 500]}>
                  <iframe
                    title="tournament location"
                    width="100%"
                    height="100%"
                    style={{ border: 0, filter: gMapsIframeFilter }}
                    loading="lazy"
                    src={`https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(
                      tournament?.location
                    )}&key=${process.env.REACT_APP_GOOGLE_API_CLIENT_KEY}`}
                  ></iframe>
                </Box>
              )}
            </Box>
          </SimpleGrid>

          {!!sponsorImages?.length && (
            <Box mt="3">
              <Text mb="3" color="white" fontWeight="bold">
                Tournament sponsors
              </Text>
              <SimpleGrid columns={[3, 3, 6, 8]} spacing="3">
                {sponsorImages.map((media) => {
                  const hasWebsite = !!media?.website;
                  const LinkOrBox = hasWebsite ? Link : Box;
                  return (
                    <LinkOrBox
                      name={media?.file?.url}
                      key={media?.file?.url}
                      href={media?.website}
                      target="_blank"
                      d="flex"
                      borderRadius="md"
                      bgColor="white"
                      overflow="hidden"
                      justifyContent="center"
                      alignItems="center"
                      h="100px"
                    >
                      <Image
                        src={media?.file?.url}
                        alt={`logo for ${media?.website}`}
                        maxH="100px"
                        maxW="100px"
                      />
                    </LinkOrBox>
                  );
                })}
              </SimpleGrid>
            </Box>
          )}
        </Container>
      </Box>

      <Container
        maxW="container.lg"
        mt={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}
      >
        <PageLoader isLoading={isTournamentEventsLoading}>
          <Heading as="h2">Events</Heading>

          {hasEvents &&
            tournament.eventsByDate.map(({ events, date }) => {
              return (
                <Box key={date}>
                  <Box mt="8" d="flex">
                    <Text fontWeight="bold" fontSize="xl">
                      {date}
                    </Text>
                  </Box>
                  <VStack mt={2} spacing={8}>
                    {events.map((event) => {
                      return (
                        <Box key={event.objectId} w="100%">
                          <Box
                            border={"none"}
                            borderColor={"none"}
                            borderBottomRadius="lg"
                            borderRightRadius="lg"
                          >
                            <EventCard
                              event={event}
                              tournament={tournament}
                              league={league}
                              refetchQueries={[GET_TOURNAMENT_EVENTS]}
                            />
                          </Box>
                        </Box>
                      );
                    })}
                  </VStack>
                </Box>
              );
            })}
        </PageLoader>
      </Container>
    </Box>
  );
}

export default TournamentRegisterPage;
