import { useMutation } from "@apollo/client";
import {
  Badge,
  Button,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { DURATION } from "constants/duration";
import {
  ADD_EXISTING_TEAM_TO_EVENT,
  REMOVE_TEAM_FROM_EVENT,
} from "graphql/mutations";
import { useState } from "react";
import {
  formatEventName,
  formatShortDateTime,
  getIsEventFull,
  getIsUserInEvent,
  getTeamUsers,
} from "utils/formatters";
import ConfirmModal from "components/ConfirmModal";
import { BiCaretDown } from "react-icons/bi";

function MoveTeamDropdown({
  tournament,
  event,
  team,
  refetchQueries,
  triggerStyles = {},
}) {
  const [eventToMoveTo, setEventToMoveTo] = useState(null);

  const toast = useToast();
  const {
    isOpen: isConfirmMoveModalOpen,
    onOpen: onConfirmMoveModalOpen,
    onClose: onConfirmMoveModalClose,
  } = useDisclosure();
  const [addExistingTeamToEvent] = useMutation(ADD_EXISTING_TEAM_TO_EVENT);
  const [removeTeamFromEvent] = useMutation(REMOVE_TEAM_FROM_EVENT);

  const events = (tournament?.events?.edges || []).map(({ node }) => node);
  const usersOnTeam = getTeamUsers(team);

  return (
    <>
      <Menu>
        <MenuButton
          variant="ghost"
          size="sm"
          as={Button}
          rightIcon={<BiCaretDown />}
          {...triggerStyles}
        >
          Move team to
        </MenuButton>
        <MenuList>
          {events.map((eventOption) => {
            const isEventFull = getIsEventFull(eventOption, tournament);
            const doesEventAlreadyHaveUsersFromTeam = (usersOnTeam || []).some(
              (user) => getIsUserInEvent(user?.objectId, eventOption)
            );
            const isCurrentEvent = event.objectId === eventOption.objectId;

            // Don't render option for this same event.
            if (isCurrentEvent) {
              return null;
            }

            return (
              <MenuItem
                key={eventOption.objectId}
                isDisabled={isEventFull || doesEventAlreadyHaveUsersFromTeam}
                onClick={() => {
                  setEventToMoveTo(eventOption);
                  onConfirmMoveModalOpen();
                }}
              >
                {eventOption.eventStartTime && (
                  <Text mr="2">
                    {formatShortDateTime(eventOption.eventStartTime)}
                  </Text>
                )}
                <Text fontWeight="semibold">
                  {formatEventName(eventOption)}
                </Text>
                {isEventFull && <Badge ml="2">Event full</Badge>}
                {doesEventAlreadyHaveUsersFromTeam && (
                  <Badge ml="2">User already in event</Badge>
                )}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
      <ConfirmModal
        headerText="Move team"
        confirmButtonText="Move"
        isOpen={isConfirmMoveModalOpen}
        onClose={onConfirmMoveModalClose}
        onConfirmClick={async () => {
          try {
            await addExistingTeamToEvent({
              variables: {
                eventId: eventToMoveTo?.objectId,
                teamId: team?.objectId,
              },
            });

            await removeTeamFromEvent({
              variables: {
                eventId: event?.objectId,
                teamId: team?.objectId,
              },
              refetchQueries,
            });

            toast({
              title: `Your team was moved to ${formatEventName(eventToMoveTo)}`,
              status: "success",
              duration: DURATION.MEDIUM,
              isClosable: true,
            });
          } catch (e) {
            toast({
              title:
                "Sorry, there was a problem moving this team, please try again later.",
              status: "error",
              duration: DURATION.MEDIUM,
              isClosable: true,
            });
          }
        }}
      >
        <Text>Are you sure you want to move this team to</Text>
        <Text my="4" fontWeight="bold">
          {formatEventName(eventToMoveTo)}
        </Text>
      </ConfirmModal>
    </>
  );
}

export default MoveTeamDropdown;
