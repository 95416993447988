import {
  Heading,
  Container,
  Box,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";

import { useQuery } from "@apollo/client";
import { useParams, useHistory } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";
import {
  getQueryParams,
  getUserFromTeam,
  isTournamentFree,
  isUserInTeam,
} from "utils/formatters";
import { GET_TEAM_INVITE } from "graphql/queries";
import PageLoader from "components/PageLoader";
import EventCard from "components/EventCard";
import NotFoundPage from "pages/NotFoundPage";
import HeaderBack from "components/HeaderBack";
import InviteDeclineButton from "components/InviteDeclineButton";
import InviteAcceptButton from "components/InviteAcceptButton";
import CompletePaymentModal from "components/CompletePaymentModal";
import SignUpModal from "components/SignUpModal";
import { ROUTES } from "constants/routes";

/**
 * ***Public Page***
 * Used as a landing page from invite emails for players to accept (or reject)
 * an invite form a Team for a particular Event.
 *
 * ex. route
 * /teams/:teamId/invite?iti=jtk5Pm2Jhi
 */
function TeamEventInvitePage() {
  const { authState, isAuthenticated } = useAuthContext();
  const user = authState?.user;
  const { teamId } = useParams();
  const inviterUserId = getQueryParams().get("iui");
  const history = useHistory();

  const {
    isOpen: isCompletePaymentModalOpen,
    onOpen: onCompletePaymentModalOpen,
  } = useDisclosure();
  const {
    isOpen: isSignUpModalOpen,
    onOpen: onSignUpModalOpen,
    onClose: onSignUpModalClose,
  } = useDisclosure();

  const { loading: isTeamLoading, data: teamData } = useQuery(GET_TEAM_INVITE, {
    variables: { teamId },
  });

  const team = teamData?.team;
  const invitingUser = getUserFromTeam(inviterUserId, team);
  const event = team?.event;
  const inviterFullName = invitingUser?.fullname;
  const tournament = event?.tournament;
  const league = tournament?.league;
  const isUserAlreadyOnTeam = isUserInTeam(user?.objectId, team);
  const isRegistrationFree = isTournamentFree(tournament);

  const showPageNotFound =
    !isTeamLoading && (!teamId || !inviterUserId || !team || !inviterFullName);

  if (showPageNotFound) {
    return <NotFoundPage />;
  }

  return (
    <Box pb={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}>
      <HeaderBack onCloseRoute={ROUTES.HOME} />
      <Container
        maxW="container.lg"
        mt={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}
      >
        <PageLoader isLoading={isTeamLoading}>
          {event && tournament && league && (
            <>
              <Heading as="h1" size="lg" mb="6" textAlign="center">
                {isUserAlreadyOnTeam
                  ? "This is your own invite. Please send this link to someone else."
                  : `You have an invite from ${inviterFullName}`}
              </Heading>

              <EventCard
                event={event}
                tournament={tournament}
                league={league}
                showRegistrationButtons={false}
              />

              {!isUserAlreadyOnTeam && (
                <Stack direction={["column", "row"]} spacing="24px" mt="6">
                  <InviteDeclineButton
                    declinerId={user?.objectId}
                    inviterId={inviterUserId}
                    event={event}
                    tournament={tournament}
                    league={league}
                    team={team}
                    onButtonClick={() => {
                      if (!isAuthenticated()) {
                        onSignUpModalOpen();
                      }
                    }}
                  />
                  <InviteAcceptButton
                    accepterId={user?.objectId}
                    inviterId={inviterUserId}
                    inviterFullName={inviterFullName}
                    event={event}
                    tournament={tournament}
                    league={league}
                    team={team}
                    onButtonClick={() => {
                      if (!isAuthenticated()) {
                        onSignUpModalOpen();
                      }
                    }}
                    refetchQueries={[GET_TEAM_INVITE]}
                    onComplete={(existingPlayer) => {
                      // Event requires payment so open payment modal
                      if (!isRegistrationFree && !existingPlayer?.hasPaid) {
                        onCompletePaymentModalOpen();
                      } else {
                        // Else go to My Events Page
                        history.push(ROUTES.MY_EVENTS);
                      }
                    }}
                  />
                </Stack>
              )}

              <CompletePaymentModal
                league={league}
                tournament={tournament}
                event={event}
                isOpen={isCompletePaymentModalOpen}
                onClose={() => {
                  history.push(ROUTES.MY_EVENTS);
                }}
              />

              <SignUpModal
                isOpen={isSignUpModalOpen}
                onClose={onSignUpModalClose}
              />
            </>
          )}
        </PageLoader>
      </Container>
    </Box>
  );
}

export default TeamEventInvitePage;
