import { useRadioGroup, SimpleGrid, Text } from "@chakra-ui/react";
import RadioCard from "components/RadioCard";
import {
  EVENT_TYPE_OPTIONS_LIST,
  EVENT_TYPE_OPTION_DESCRIPTIONS,
  EVENT_TYPE_OPTION_ICONS,
  EVENT_TYPE_OPTION_NAMES,
} from "utils/event";

function EventTypeRadioGroup({ name, value, onChange, onBlur, disabled }) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue: value,
    onChange,
  });
  const group = getRootProps();
  const mergedProps = { ...group, name, value, onChange, onBlur };

  return (
    <>
      <SimpleGrid columns={3} columnGap={4} rowGap={4} {...mergedProps}>
        {EVENT_TYPE_OPTIONS_LIST.map((value) => {
          const radio = getRadioProps({ value });
          return (
            <RadioCard {...radio} key={value} disabled={disabled}>
              {EVENT_TYPE_OPTION_ICONS[value]()}
              <Text fontSize={[12, 12, 16]} textAlign="center">
                {EVENT_TYPE_OPTION_NAMES[value]}
              </Text>
            </RadioCard>
          );
        })}
      </SimpleGrid>
      <Text mt="3">{EVENT_TYPE_OPTION_DESCRIPTIONS[value].text}</Text>
    </>
  );
}

export default EventTypeRadioGroup;
