import { useHistory } from "react-router-dom";
import { VStack, Button, useDisclosure } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { ROUTES } from "constants/routes";
import {
  doesTeamHaveRequiredPlayers,
  getHasUserPaidForEvent,
  getTeamByUserId,
  getIsEventFull,
  isPartnerNeededToRegister,
  isTournamentFree,
  isEventScheduleSupported,
  getIsEventInPast,
} from "utils/formatters";
import RegistrationWithdrawModal from "./RegistrationWithdrawModal";
import RegistrationWizardModal from "./RegistrationWizardModal";
import { useAuthContext } from "context/AuthContext";
import CompletePaymentModal from "components/CompletePaymentModal";
import InvitePartnerModal from "./InvitePartnerModal";

/**
 * Renders one or more buttons which control various mutations on a Team.
 * Team fields are how users sign up for an event.
 * @param {Object} tournament
 * @param {Object} user
 * @param {Object} event
 * @param {Object} league
 * @param {boolean} showTournamentInfoButton - If "Tournament info" button should show
 * @param {boolean} showEventScheduleButton - If "Event schedule" button should show
 * @param {gql[]} refetchQueries - Array of graphQL queries
 */
function RegistrationButtons({
  tournament,
  event,
  league,
  showTournamentInfoButton = false,
  refetchQueries,
}) {
  const { authState, isAuthenticated } = useAuthContext();
  const userId = authState?.user?.objectId;
  const history = useHistory();
  const { pathname, search } = useLocation();
  const {
    isOpen: isRegistrationWithdrawModalOpen,
    onOpen: onRegistrationWithdrawModalOpen,
    onClose: onRegistrationWithdrawModalClose,
  } = useDisclosure();
  const {
    isOpen: isRegistrationModalOpen,
    onOpen: onRegistrationModalOpen,
    onClose: onRegistrationModalClose,
  } = useDisclosure();
  const {
    isOpen: isCompletePaymentModalOpen,
    onOpen: onCompletePaymentModalOpen,
    onClose: onCompletePaymentModalClose,
  } = useDisclosure();
  const {
    isOpen: isInvitePartnerModalOpen,
    onOpen: onInvitePartnerModalOpen,
    onClose: onInvitePartnerModalClose,
  } = useDisclosure();

  const isLoggedIn = isAuthenticated();
  const userTeam = getTeamByUserId(userId, event);
  const isUserInEvent = !!userTeam;
  const hasUserPaidForEvent = getHasUserPaidForEvent(userId, event);
  const doesRegistrationRequirePartner = isPartnerNeededToRegister(event);
  const teamHasRequiredPartners = doesTeamHaveRequiredPlayers(userTeam, event);
  const isRegistrationFree = isTournamentFree(tournament);
  const isEventFull = getIsEventFull(event, tournament);
  const isEventInPast = getIsEventInPast(event);
  const showEventScheduleButton = isEventScheduleSupported(event);
  const canRegister = !!event?.canRegister && !isEventInPast;

  return (
    <VStack
      spacing={4}
      align="stretch"
      w="100%"
      data-testid="registration-buttons"
    >
      {/* User is not logged in */}
      {!isLoggedIn && canRegister && (
        <Button
          colorScheme="orange"
          variant="solid"
          onClick={() => {
            history.push(
              `${ROUTES.SIGNUP}?redir=${encodeURIComponent(pathname + search)}`
            );
          }}
        >
          Sign up to register
        </Button>
      )}

      {/* User is not in event */}
      {isLoggedIn && !isUserInEvent && canRegister && (
        <Button
          colorScheme={isEventFull ? "blue" : "teal"}
          variant="solid"
          onClick={onRegistrationModalOpen}
        >
          {isEventFull ? "Join waitlist" : "Register"}
        </Button>
      )}

      {showEventScheduleButton && (
        <Button
          colorScheme="teal"
          variant="outline"
          onClick={() => {
            history.push(`/event-schedule/${event?.objectId}`);
          }}
        >
          {isEventInPast ? "View results" : "Event schedule"}
        </Button>
      )}

      {showTournamentInfoButton && (
        <Button
          colorScheme="teal"
          variant="outline"
          w="100%"
          onClick={() => {
            history.push(`/tournaments/${tournament?.objectId}/register`);
          }}
        >
          Tournament Info
        </Button>
      )}

      {/* User is in paid event but has not paid */}
      {isLoggedIn &&
        isUserInEvent &&
        !isRegistrationFree &&
        !hasUserPaidForEvent && (
          <Button
            colorScheme="teal"
            variant="solid"
            onClick={onCompletePaymentModalOpen}
          >
            Complete payment
          </Button>
        )}

      {/* User is in event but does not have partner */}
      {isLoggedIn &&
        isUserInEvent &&
        doesRegistrationRequirePartner &&
        !teamHasRequiredPartners && (
          <Button
            colorScheme="teal"
            variant="solid"
            onClick={onInvitePartnerModalOpen}
          >
            Invite partner
          </Button>
        )}

      {/* User is logged in and on a team */}
      {isLoggedIn && isUserInEvent && !isEventInPast && (
        <Button
          colorScheme="gray"
          variant="solid"
          onClick={onRegistrationWithdrawModalOpen}
        >
          Withdraw from event
        </Button>
      )}

      <CompletePaymentModal
        league={league}
        tournament={tournament}
        event={event}
        isOpen={isCompletePaymentModalOpen}
        onClose={onCompletePaymentModalClose}
        refetchQueries={refetchQueries}
      />
      <InvitePartnerModal
        league={league}
        tournament={tournament}
        event={event}
        isOpen={isInvitePartnerModalOpen}
        onClose={onInvitePartnerModalClose}
      />
      <RegistrationWizardModal
        league={league}
        tournament={tournament}
        event={event}
        isOpen={isRegistrationModalOpen}
        onClose={onRegistrationModalClose}
        refetchQueries={refetchQueries}
      />
      <RegistrationWithdrawModal
        league={league}
        tournament={tournament}
        event={event}
        isOpen={isRegistrationWithdrawModalOpen}
        onClose={onRegistrationWithdrawModalClose}
        refetchQueries={refetchQueries}
      />
    </VStack>
  );
}

export default RegistrationButtons;
