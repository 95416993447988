import {
  Input,
  InputGroup,
  InputRightAddon,
  Button,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { DURATION } from "constants/duration";
import { useRef } from "react";
import { copyTextToClipboard } from "utils/formatters";

function CopyUrlInput({ url, toastText }) {
  const toast = useToast();
  const inputRef = useRef(null);
  const inputBg = useColorModeValue("white", "none");

  return (
    <InputGroup>
      <Input
        readOnly
        backgroundColor={inputBg}
        ref={inputRef}
        value={url}
        onClick={() => {
          inputRef?.current?.select();
        }}
      />
      <InputRightAddon
        p="0"
        overflow="hidden"
        children={
          <Button
            w="100%"
            variant="solid"
            borderRadius="0"
            onClick={() => {
              inputRef?.current?.select();
              copyTextToClipboard(url);
              toast({
                title: toastText || "Link was copied to your clipboard",
                status: "success",
                duration: DURATION.MEDIUM,
                isClosable: true,
              });
            }}
          >
            Copy
          </Button>
        }
      />
    </InputGroup>
  );
}

export default CopyUrlInput;
