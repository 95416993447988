import { Container, VStack, Text, Box, Image, Heading } from "@chakra-ui/react";
import { GET_EVENTS_WHERE_TEAMS_HAVE_USER } from "graphql/queries";
import { useQuery } from "@apollo/client";
import { useAuthContext } from "context/AuthContext";
import CardLoader from "components/CardLoader";
import EventCard from "components/EventCard";

function MyEventsPage() {
  const { authState } = useAuthContext();
  const userId = authState.user?.objectId;
  const { loading: isLoadingUserEvents, data: userEventsData } = useQuery(
    GET_EVENTS_WHERE_TEAMS_HAVE_USER,
    {
      fetchPolicy: "no-cache",
      variables: {
        userId,
      },
    }
  );

  const events = userEventsData?.events?.edges?.map(({ node }) => node);
  const hasEvents = !!events?.length;

  return (
    <Container
      data-testid="my-events-page"
      maxW="container.lg"
      mt={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}
    >
      <Box d="flex" justifyContent="space-between">
        <Heading mb={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}>
          My Events
        </Heading>
      </Box>

      <CardLoader isLoading={isLoadingUserEvents}>
        {hasEvents && (
          <VStack spacing={4}>
            {events.map((event) => {
              const tournament = event?.tournament;
              const league = event?.tournament?.league;
              return (
                <EventCard
                  key={event.objectId}
                  event={event}
                  tournament={tournament}
                  league={league}
                  showTournamentInfoButton={true}
                  refetchQueries={[GET_EVENTS_WHERE_TEAMS_HAVE_USER]}
                />
              );
            })}
          </VStack>
        )}

        {!hasEvents && (
          <Box
            d="flex"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            minHeight={["300px", "500px"]}
          >
            <Image
              w={["300px", "400px", "500px"]}
              src="/undraw_online_calendar_re_wk3t.svg"
              alt="no registered event"
            />
            <Text fontSize="lg" opacity="75%" mt="6">
              Your registered events will display here
            </Text>
          </Box>
        )}
      </CardLoader>
    </Container>
  );
}

export default MyEventsPage;
