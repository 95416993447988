/**
 * DinkPal's flat fee per transaction.
 * *** This MUST match DINKPAL_PLATFORM_FEE in cloud/constants.js. ***
 */
export const DINKPAL_FLAT_FEE = 2;

/**
 * PayPal's percent fee per transaction.
 */
export const PAYPAL_PERCENT_FEE = 0.0349;

/**
 * PayPal's flat fee per transaction.
 */
export const PAYPAL_FLAT_FEE = 0.49;
