import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { MAX_PLAYERS_INPUT_MAX, MAX_PLAYERS_INPUT_MIN } from "utils/event";

function MaxPlayersInput({ value, onChange, name }) {
  return (
    <NumberInput
      id={name}
      name={name}
      value={value}
      min={MAX_PLAYERS_INPUT_MIN}
      max={MAX_PLAYERS_INPUT_MAX}
      onChange={(value) => {
        // NumberInput allows 'e' input. Ignore these cases.
        if (value.toString().includes("e") || value.toString().includes("E")) {
          return;
        }
        onChange(value);
      }}
    >
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
}

export default MaxPlayersInput;
