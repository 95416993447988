import { Tooltip, Button, Icon } from "@chakra-ui/react";
import { BsFillQuestionCircleFill } from "react-icons/bs";

function QuestionMarkTooltip({ text }) {
  return (
    <Tooltip hasArrow placement="top" label={text}>
      <Button variant="link" top="2px">
        <Icon as={BsFillQuestionCircleFill} w={4} h={4} />
      </Button>
    </Tooltip>
  );
}

export default QuestionMarkTooltip;
