import {
  ApolloClient,
  ApolloLink,
  from,
  InMemoryCache,
  defaultDataIdFromObject,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { SESSION_TOKEN_KEY } from "constants/localstorage";

const BACK4APP_HEADERS = {
  "X-Parse-Application-Id": process.env.REACT_APP_B4A_APP_ID,
  "X-Parse-Client-Key": process.env.REACT_APP_B4A_CLIENT_KEY,
};

export const BACK4APP_BASE_URL = process.env.REACT_APP_PARSE_SERVER;

function getAuthHeaders() {
  const authHeaders = { ...BACK4APP_HEADERS };
  // get the authentication sessionToken from local storage if it exists
  const sessionToken = localStorage.getItem(SESSION_TOKEN_KEY);

  if (sessionToken) {
    authHeaders["X-Parse-Session-Token"] = sessionToken;
  }

  return authHeaders;
}

// Format apollo cache key to use `objectId`, so key is "Game:xi3Fifsji" for example.
// https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-identifier-generation-globally
const cache = new InMemoryCache({
  typePolicies: {
    Event: {
      fields: {
        eventSpec: {
          read(eventSpec) {
            return typeof eventSpec === "string"
              ? JSON.parse(eventSpec)
              : eventSpec;
          },
        },
      },
    },
  },
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case "User":
        return `User:${responseObject.objectId}`;
      case "Event":
        return `Event:${responseObject.objectId}`;
      case "Game":
        return `Game:${responseObject.objectId}`;
      case "League":
        return `League:${responseObject.objectId}`;
      case "Player":
        return `Player:${responseObject.objectId}`;
      case "Team":
        return `Team:${responseObject.objectId}`;
      case "Tournament":
        return `Tournament:${responseObject.objectId}`;
      case "Media":
        return `Media:${responseObject.objectId}`;
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
});

const uploadHttpLink = createUploadLink({
  uri: `${BACK4APP_BASE_URL}/graphql`,
});

const authLink = setContext(() => {
  return {
    headers: getAuthHeaders(),
  };
});

const eventSpecLink = new ApolloLink((operation, forward) => {
  if (operation?.variables?.eventSpec) {
    operation.variables = {
      ...operation.variables,
      eventSpec: JSON.stringify(operation.variables.eventSpec),
    };
  }

  return forward(operation).map((response) => {
    const hasEventSpec = !!response?.data?.event?.eventSpec;
    if (hasEventSpec) {
      try {
        response.data.event.eventSpec = JSON.parse(
          response.data.event.eventSpec
        );
      } catch (e) {
        throw new Error(`Error parsing eventSpec ${e.message}`);
      }
    }
    return response;
  });
});

const client = new ApolloClient({
  link: from([eventSpecLink, authLink, uploadHttpLink]),
  cache,
});

export default client;
