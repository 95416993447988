import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";

function PriceInput({ value, onChange, isDisabled }) {
  const format = (val) => (Number.isInteger(val) ? `$${val}` : "");
  const parse = (val) => val.replace(/^\$/, "");

  return (
    <NumberInput
      min={0}
      isDisabled={isDisabled}
      onChange={(valueString) => {
        // NumberInput allows 'e' input. Ignore these cases.
        if (
          valueString.toString().includes("e") ||
          valueString.toString().includes("E")
        ) {
          return;
        }
        const newValue = parse(valueString);
        onChange(newValue);
      }}
      value={format(value)}
    >
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
}

export default PriceInput;
