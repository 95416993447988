import { Button, Icon, useToast } from "@chakra-ui/react";
import { DURATION } from "constants/duration";
import { BiLinkAlt } from "react-icons/bi";
import { copyTextToClipboard } from "utils/formatters";

function CopyUrlButton({ url = "", toastText = "" }) {
  const toast = useToast();

  return (
    <Button
      h="32px"
      variant="solid"
      colorScheme="teal"
      aria-label="Copy current url"
      onClick={() => {
        copyTextToClipboard(url || window.location.href);
        toast({
          title: toastText || "Share link was copied to your clipboard",
          status: "success",
          duration: DURATION.MEDIUM,
          isClosable: true,
        });
      }}
    >
      <Icon as={BiLinkAlt} w={5} h={5} mr="2" />
      Copy link
    </Button>
  );
}

export default CopyUrlButton;
