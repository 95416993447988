import {
  Text,
  Button,
  Box,
  Link,
  Image,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { ROUTES } from "constants/routes";

function SuccessModalContent({ isPartnerNeeded, isRegistrationFree, onClose }) {
  const linkColor = useColorModeValue("teal.500", "teal.200");

  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalHeader>Congratulations!</ModalHeader>
      <ModalBody d="flex" flexDir="column">
        <Box boxSize="sm" d="flex" alignSelf="center">
          <Image
            src="/undraw_grand_slam_-0-q5r.svg"
            alt="woman playing pickleball"
          />
        </Box>

        {isRegistrationFree && <Text>You are registered for this event.</Text>}

        {!isRegistrationFree && (
          <Text>You have successfully paid for this event.</Text>
        )}

        {isPartnerNeeded && (
          <Text mt="4">
            Next step is to find you a partner. Send someone an invite to join
            your team by clicking on the <b>Invite partner</b> button.
          </Text>
        )}
        <Text mt="4">
          You can always check the status of your registration under{" "}
          <Link
            fontWeight="bold"
            color={linkColor}
            href={ROUTES.MY_EVENTS}
            target="_blank"
          >
            My Events
          </Link>
          .
        </Text>
      </ModalBody>
      <ModalFooter d="flex" justifyContent="space-between">
        <Button w="100%" colorScheme="teal" variant="solid" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}

SuccessModalContent.propTypes = {
  isPartnerNeeded: PropTypes.bool.isRequired,
  isRegistrationFree: PropTypes.bool.isRequired,
};

export default SuccessModalContent;
