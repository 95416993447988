import { useMutation, useQuery } from "@apollo/client";
import {
  VStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Container,
  Icon,
  IconButton,
  Box,
  Heading,
  Text,
  Badge,
  SkeletonText,
  useDisclosure,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { GET_TOURNAMENT_EVENTS } from "graphql/queries";
import {
  DELETE_TOURNAMENT,
  GET_TOURNAMENT_PLAYER_EMAILS,
  GET_EVENT_PLAYER_EMAILS,
} from "graphql/mutations";
import { useAuthContext } from "context/AuthContext";
import {
  BiArrowBack,
  BiCalendarPlus,
  BiCaretDown,
  BiDotsVerticalRounded,
} from "react-icons/bi";
import { useHistory } from "react-router-dom";
import ConfirmModal from "components/ConfirmModal";
import TeamsListEditor from "components/TeamsListEditor";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import NotFoundPage from "pages/NotFoundPage";
import {
  groupTournamentEventsByDate,
  formatTime,
  formatEventName,
  formatPrice,
  isEventScheduleSupported,
  copyTextToClipboard,
  openInNewTab,
} from "utils/formatters";
import { DURATION } from "constants/duration";
import { useParams } from "react-router-dom";
import { ROUTES } from "constants/routes";
import TournamentStatus from "components/TournamentStatus";

/**
 * Used by admins to manage all aspects (events, players, teams) of a single tournament.
 */
function TournamentAdminPage() {
  const { tournamentId } = useParams();

  const history = useHistory();
  const toast = useToast();
  const { authState } = useAuthContext();
  const currentUserId = authState?.user?.objectId;
  const tournamentButtonColor = useColorModeValue("white", "gray.900");
  const eventCardBorderColor = useColorModeValue("gray.200", "gray.700");
  const eventCardBgColor = useColorModeValue("white", "gray.900");
  const footerColor = useColorModeValue("gray.50", "black");

  const {
    isOpen: isDeleteConfirmModalOpen,
    onOpen: onDeleteConfirmModalOpen,
    onClose: onDeleteConfirmModalClose,
  } = useDisclosure();

  const [deleteTournament] = useMutation(DELETE_TOURNAMENT);
  const [getTournamentPlayerEmails] = useMutation(GET_TOURNAMENT_PLAYER_EMAILS);
  const [getEventPlayerEmails] = useMutation(GET_EVENT_PLAYER_EMAILS);

  const {
    loading: isLoadingTournament,
    error: isTournamentError,
    data: tournamentData,
  } = useQuery(GET_TOURNAMENT_EVENTS, {
    fetchPolicy: "no-cache",
    variables: {
      tournamentId,
    },
  });

  // Return error or loading state first.
  if (isTournamentError) {
    return <NotFoundPage />;
  }

  if (isLoadingTournament || !tournamentData) {
    return (
      <Container maxW="container.lg" mt="5">
        <SkeletonText p="4" noOfLines={4} spacing="4" />
        <SkeletonText p="4" noOfLines={4} spacing="4" />
      </Container>
    );
  }

  const league = tournamentData?.tournament?.league;
  const leagueId = league?.objectId;
  const leagueCreatedByUserId = league?.createdBy?.objectId;
  const tournament = groupTournamentEventsByDate(tournamentData?.tournament);
  const eventsByDate = tournament?.eventsByDate;
  const lastEventTime = tournament?.lastEventTime;

  if (leagueCreatedByUserId !== currentUserId) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Container
        data-testid="my-league-page"
        maxW="container.lg"
        mt={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}
      >
        {tournament && (
          <Box>
            {/* Header */}
            <Box
              display="flex"
              flexDir={["column", "column", "row"]}
              justifyContent={[undefined, undefined, "space-between"]}
            >
              <Box display="flex" mb="4">
                <IconButton
                  mr="2"
                  aria-label="Back"
                  variant="solid"
                  icon={<BiArrowBack />}
                  onClick={() => {
                    history.push(ROUTES.MY_LEAGUE);
                  }}
                />
                <Heading as="h1">{tournament.name}</Heading>
              </Box>
              <Box display="flex" flexDir="column" alignItems="center">
                <Box mb="2" display="flex" justifyContent="right" w="100%">
                  <TournamentStatus fontSize="sm" tournament={tournament} />
                </Box>
                <Box display="flex" justifyContent="right" w="100%">
                  <Badge
                    variant="solid"
                    colorScheme="orange"
                    ml={[0, 0, 2]}
                    fontSize="sm"
                  >
                    {formatPrice(tournament.pricePerPerson)}
                  </Badge>
                  {tournament.pricePerPerson !== 0 &&
                    Number.isInteger(tournament.priceAfterFirstEvent) && (
                      <Badge
                        variant="solid"
                        colorScheme="yellow"
                        ml="2"
                        fontSize="sm"
                      >
                        {formatPrice(tournament.priceAfterFirstEvent)} each
                        additional event
                      </Badge>
                    )}
                </Box>
              </Box>
            </Box>

            <Box
              mt="4"
              mb="10"
              display="flex"
              flexDir={["column", "column", "row"]}
            >
              <Button
                mb={[4, 4, 0]}
                colorScheme="teal"
                variant="outline"
                background={tournamentButtonColor}
                onClick={() => {
                  openInNewTab(`/tournaments/${tournament?.objectId}/register`);
                }}
              >
                <Text mr="2">View registration page</Text>
                <ExternalLinkIcon />
              </Button>
              <Button
                mb={[4, 4, 0]}
                marginLeft={[undefined, undefined, "auto"]}
                marginRight={[0, 0, 4]}
                colorScheme="teal"
                variant="outline"
                background={tournamentButtonColor}
                onClick={() => {
                  history.push(
                    `/leagues/${leagueId}/tournaments/${
                      tournament.objectId
                    }/events/new${lastEventTime ? "?st=" + lastEventTime : ""}`
                  );
                }}
              >
                <Icon as={BiCalendarPlus} w={5} h={5} mr={1} />
                Add event
              </Button>
              <Menu marginLeft={[undefined, undefined, "auto"]}>
                <MenuButton
                  colorScheme="teal"
                  variant="outline"
                  background={tournamentButtonColor}
                  as={Button}
                  rightIcon={<BiCaretDown />}
                >
                  Manage
                </MenuButton>
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      getTournamentPlayerEmails({
                        variables: { tournamentId: tournament.objectId },
                      }).then((resp) => {
                        const emails = resp?.data?.GetTournamentPlayerEmails;
                        copyTextToClipboard(emails.join("; "));
                        toast({
                          title: `${emails?.length} emails copied to your clipboard. We suggest pasting them in BCC.`,
                          status: "success",
                          duration: DURATION.MEDIUM,
                          isClosable: true,
                        });
                      });
                    }}
                  >
                    Copy tournament player emails
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      history.push(
                        `/leagues/${leagueId}/tournaments/${tournament.objectId}`
                      );
                    }}
                  >
                    Edit tournament
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      onDeleteConfirmModalOpen();
                    }}
                  >
                    Delete tournament
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>

            {/* Body */}
            {!!eventsByDate?.length ? (
              eventsByDate.map(({ events, date }) => {
                return (
                  <Box key={date}>
                    <Box mt="4" display="flex">
                      <Text fontWeight="bold" fontSize="lg">
                        {date}
                      </Text>
                    </Box>

                    <VStack mt={2} spacing={4}>
                      {events.map((event) => {
                        return (
                          <Box
                            key={event.objectId}
                            display="flex"
                            flexDir="column"
                            w="100%"
                            borderRadius="md"
                            border="1.5px solid"
                            borderColor={eventCardBorderColor}
                            backgroundColor={eventCardBgColor}
                            overflow="hidden"
                          >
                            {/* Card Header */}
                            <Box
                              display="flex"
                              alignItems="center"
                              p="10px 15px"
                              borderBottom="1.5px solid"
                              borderColor={eventCardBorderColor}
                            >
                              <Badge
                                display="flex"
                                flexDir={["column", "row", "row"]}
                                justifyContent="center"
                              >
                                <Text textAlign="center" fontSize="md">
                                  {formatTime(event.eventStartTime).time}
                                </Text>
                                <Text
                                  textAlign="center"
                                  fontSize="md"
                                  ml={[0, 1]}
                                >
                                  {formatTime(event.eventStartTime).ampm}
                                </Text>
                              </Badge>
                              <Heading
                                as="h5"
                                size="sm"
                                ml="2"
                                display="flex"
                                flexGrow={1}
                              >
                                {formatEventName(event)}
                              </Heading>
                              <Menu>
                                <MenuButton
                                  variant="ghost"
                                  as={Button}
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Icon
                                    as={BiDotsVerticalRounded}
                                    w={5}
                                    h={5}
                                    pos="absolute"
                                    top="10px"
                                    right="10px"
                                  />
                                </MenuButton>
                                <MenuList>
                                  <MenuItem
                                    onClick={() => {
                                      getEventPlayerEmails({
                                        variables: {
                                          eventId: event?.objectId,
                                        },
                                      }).then((resp) => {
                                        const emails =
                                          resp?.data?.GetEventPlayerEmails;
                                        copyTextToClipboard(emails.join("; "));
                                        toast({
                                          title: `${emails?.length} emails copied to your clipboard. We suggest pasting them in BCC.`,
                                          status: "success",
                                          duration: DURATION.MEDIUM,
                                          isClosable: true,
                                        });
                                      });
                                    }}
                                  >
                                    Copy event player emails
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      history.push(
                                        `/leagues/${leagueId}/tournaments/${tournament.objectId}/events/${event.objectId}`
                                      );
                                    }}
                                  >
                                    Edit event
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </Box>
                            {/* Card Body */}
                            <Box p="10px 15px">
                              <TeamsListEditor
                                event={event}
                                tournament={tournament}
                                refetchQueries={[GET_TOURNAMENT_EVENTS]}
                              />
                            </Box>
                            {/* Footer */}
                            {isEventScheduleSupported(event) && (
                              <Box
                                display="flex"
                                alignItems="center"
                                p="10px 15px"
                                justifyContent="flex-end"
                                backgroundColor={footerColor}
                                borderTop="1.5px solid"
                                borderColor={eventCardBorderColor}
                              >
                                <Button
                                  variant="outline"
                                  colorScheme="teal"
                                  background={tournamentButtonColor}
                                  onClick={() => {
                                    history.push(
                                      `/event-schedule/${event.objectId}`
                                    );
                                  }}
                                >
                                  Event Schedule
                                </Button>
                              </Box>
                            )}
                          </Box>
                        );
                      })}
                    </VStack>
                  </Box>
                );
              })
            ) : (
              <Box
                display="flex"
                flexDir="column"
                justifyContent="center"
                alignItems="center"
                minHeight="200px"
              >
                <Text>Let's create some events for this tournament</Text>
                <Button
                  mt="3"
                  size="md"
                  colorScheme="teal"
                  variant="solid"
                  onClick={() => {
                    history.push(
                      `/leagues/${leagueId}/tournaments/${tournament.objectId}/events/new`
                    );
                  }}
                >
                  Create event
                </Button>
              </Box>
            )}
          </Box>
        )}

        <ConfirmModal
          headerText="Delete tournament"
          bodyText={`Are you sure you want to delete the tournament "${tournament?.name}"?`}
          confirmButtonText="Delete"
          isOpen={isDeleteConfirmModalOpen}
          onClose={onDeleteConfirmModalClose}
          onConfirmClick={async () => {
            const variables = { tournamentId: tournament?.objectId };
            await deleteTournament({ variables });
            toast({
              title: `Tournament "${tournament?.name}" was deleted`,
              status: "success",
              duration: DURATION.MEDIUM,
              isClosable: true,
            });
            history.push(ROUTES.MY_LEAGUE);
          }}
        />
      </Container>
    </>
  );
}

export default TournamentAdminPage;
