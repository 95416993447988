import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { POINT_TO_WIN_INPUT_MIN, POINT_TO_WIN_INPUT_MAX } from "utils/event";

function PointsToWinInput({ value, onChange, name }) {
  return (
    <NumberInput
      id={name}
      name={name}
      value={value}
      min={POINT_TO_WIN_INPUT_MIN}
      max={POINT_TO_WIN_INPUT_MAX}
      onChange={(value) => {
        // NumberInput allows 'e' input. Ignore these cases.
        if (value.toString().includes("e") || value.toString().includes("E")) {
          return;
        }
        onChange(value);
      }}
    >
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
}

export default PointsToWinInput;
