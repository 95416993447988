import {
  Avatar,
  Button,
  Icon,
  Box,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { ROUTES } from "constants/routes";
import { useHistory } from "react-router-dom";
import { BiListUl, BiTrophy } from "react-icons/bi";

function PrivateMobileBottomNav({ user, hasLeagues = false }) {
  const history = useHistory();
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const bgColor = useColorModeValue("white", "gray.900");

  return (
    <Box
      zIndex={9}
      w="100%"
      d="flex"
      position="fixed"
      bottom="0"
      bg={bgColor}
      borderTop="1.5px solid"
      borderColor={borderColor}
      alignItems="center"
      justifyContent="center"
    >
      <Stack spacing={4} direction="row" align="center">
        {hasLeagues && (
          <Button
            onClick={() => history.push(ROUTES.MY_LEAGUE)}
            aria-label="My League"
            variant="ghost"
            h="60px"
            d="flex"
            borderRadius={0}
            flexDir="column"
            _hover={{
              bg: "none",
            }}
          >
            <Icon as={BiTrophy} w={6} h={6} />
            My League
          </Button>
        )}
        <Button
          onClick={() => history.push(ROUTES.MY_EVENTS)}
          aria-label="My Events"
          variant="ghost"
          h="60px"
          d="flex"
          borderRadius={0}
          flexDir="column"
          _hover={{
            bg: "none",
          }}
        >
          <Icon as={BiListUl} w={6} h={6} />
          My Events
        </Button>

        <button
          onClick={() => history.push(ROUTES.ACCOUNT_SETTINGS)}
          aria-label="My profile"
        >
          <Avatar
            name={user?.fullname}
            src={user?.profilepic}
            onClick={() => history.push(ROUTES.ACCOUNT_SETTINGS)}
          />
        </button>
      </Stack>
    </Box>
  );
}

export default PrivateMobileBottomNav;
