import {
  Alert,
  AlertIcon,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { SIGN_UP } from "graphql/mutations";
import { ROUTES } from "constants/routes";
import { useAuthContext } from "context/AuthContext";
import { DURATION } from "constants/duration";
import PhoneInput from "components/PhoneInput";
import PasswordInput from "components/PasswordInput";
import TextDivider from "components/TextDivider";
import FacebookLoginButton from "components/FacebookLoginButton";
import GoogleLoginButton from "./GoogleLoginButton";

const EMAIL_EXISTS_ERROR_CODE = 202;

function getErrorCode(error) {
  return error?.graphQLErrors?.[0]?.extensions?.code;
}

function SignUpForm({ onSignUp }) {
  const [signUp, { loading }] = useMutation(SIGN_UP);
  const authContext = useAuthContext();
  const toast = useToast();

  return (
    <>
      <FacebookLoginButton onSuccess={onSignUp} />
      <GoogleLoginButton mt="4" onSuccess={onSignUp} />

      <TextDivider text="or" my="10" />

      <Formik
        initialValues={{
          fullname: "",
          email: "",
          phone: "",
          password: "",
        }}
        validationSchema={Yup.object({
          fullname: Yup.string()
            .max(50, "Must be 50 characters or less")
            .required("What's your first and last name?"),
          email: Yup.string()
            .email("Invalid email address")
            .required("What's your email?"),
          phone: Yup.string()
            .matches(/^\d{10}$/, "Phone number is not valid")
            .required("This field is Required"),
          password: Yup.string()
            .min(8, "Password must be 8 characters or more")
            .required("Please enter a password"),
        })}
        onSubmit={({ email, ...rest }) => {
          const variables = { email: email.toLocaleLowerCase(), ...rest };
          signUp({ variables })
            .then((resp) => {
              authContext.setAuthState(resp?.data?.signUp?.viewer);
              toast({
                title: "Your account was successfully created",
                status: "success",
                duration: DURATION.MEDIUM,
                isClosable: true,
              });
              onSignUp();
            })
            .catch((e) => {
              const toastOptions = {
                title: e.message,
                status: "error",
                duration: DURATION.LONG,
                isClosable: true,
              };

              if (getErrorCode(e) === EMAIL_EXISTS_ERROR_CODE) {
                // B4a requires a unique username, we are using the email to
                // populate this field in the SIGN_UP mutation. When b4a throws a 202 code
                // error it's because the 'username' already exists and the server-rendered error message
                // says this.  To prevent confusion, use custom error message for this case.
                toastOptions.render = () => (
                  <Alert status="error" variant="solid" borderRadius="md">
                    <AlertIcon />
                    <span>
                      Account already exists for this email. Would you like to{" "}
                      <a href={ROUTES.SIGNIN}>
                        <Text as="u">Sign In</Text>
                      </a>
                      ?
                    </span>
                  </Alert>
                );
              }

              toast(toastOptions);
            });
        }}
      >
        <Form>
          <Field name="fullname">
            {({ field, form }) => (
              <FormControl
                isInvalid={form.errors.fullname && form.touched.fullname}
              >
                <FormLabel htmlFor="fullname">Full Name</FormLabel>
                <Input {...field} id="fullname" />
                <FormErrorMessage>{form.errors.fullname}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="email">
            {({ field, form }) => (
              <FormControl
                mt="3"
                isInvalid={form.errors.email && form.touched.email}
              >
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input {...field} id="email" />
                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="phone">
            {({ field, form }) => {
              const onChange = (value) => {
                form.setFieldValue(field.name, value);
              };

              return (
                <FormControl
                  mt="3"
                  isInvalid={form.errors.phone && form.touched.phone}
                >
                  <FormLabel htmlFor="phone">Phone</FormLabel>
                  <PhoneInput
                    id="phone"
                    name={field.name}
                    value={field.value}
                    onBlur={field.onBlur}
                    onChange={onChange}
                  />
                  <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>

          <Field name="password">
            {({ field, form }) => (
              <FormControl
                mt="3"
                isInvalid={form.errors.password && form.touched.password}
              >
                <FormLabel htmlFor="password">Password</FormLabel>
                <PasswordInput {...field} id="password" />
                <FormErrorMessage>{form.errors.password}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Button
            mt={4}
            w="100%"
            colorScheme="teal"
            isLoading={loading}
            type="submit"
          >
            Sign up
          </Button>
        </Form>
      </Formik>
    </>
  );
}

export default SignUpForm;
