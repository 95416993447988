import {
  Heading,
  Box,
  Text,
  Badge,
  SimpleGrid,
  useColorModeValue,
  Link,
  Icon,
} from "@chakra-ui/react";
import {
  formatPrice,
  formatFullDateTime,
  formatEventName,
  getTeamByUserId,
} from "utils/formatters";
import RegistrationButtons from "components/RegistrationButtons";
import PropTypes from "prop-types";
import TeamsListViewer from "components/TeamsListViewer";
import YourTeamStatus from "components/YourTeamStatus";
import { useAuthContext } from "context/AuthContext";
import { BiLinkAlt } from "react-icons/bi";
import "./EventCard.css";

function EventCard({
  event,
  tournament,
  league,
  showRegistrationButtons = true,
  showTournamentInfoButton = false,
  refetchQueries,
}) {
  const { authState } = useAuthContext();
  const userId = authState?.user?.objectId;
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const eventCardBgColor = useColorModeValue("white", "gray.900");

  const leagueName = league?.name;
  const tournamentName = tournament?.name;
  const eventName = formatEventName(event);
  const team = getTeamByUserId(userId, event);

  return (
    <Box
      id={`event-${event.objectId}`}
      data-event-id={event.objectId}
      borderRadius="md"
      border="1.5px solid"
      borderColor={borderColor}
      backgroundColor={eventCardBgColor}
      p="20px"
      w="100%"
    >
      <SimpleGrid
        d="flex"
        justifyContent="space-between"
        flexDir={{ base: "column", sm: "column", md: "row" }}
        columns={{ sm: 1, md: 2 }}
        spacing="40px"
        mb="10px"
      >
        <Box>
          <Heading
            className="event-card__header"
            as="h2"
            size="md"
            mb="2"
            alignItems="center"
          >
            <Link href={`#event-${event.objectId}`}>{eventName}</Link>
            <Icon
              className="event-card__header-icon"
              as={BiLinkAlt}
              w={5}
              h={5}
              ml="2"
              verticalAlign="middle"
            />
          </Heading>
          <Badge fontSize="sm">
            {formatFullDateTime(event.eventStartTime)}
          </Badge>
          {Number.isInteger(tournament?.pricePerPerson) && (
            <Badge fontSize="sm" ml="2">
              {formatPrice(tournament?.pricePerPerson)}
            </Badge>
          )}
          {Number.isInteger(tournament?.priceAfterFirstEvent) && (
            <Badge fontSize="sm" ml="2">
              {formatPrice(tournament?.priceAfterFirstEvent)} each additional
              event
            </Badge>
          )}
          <Text fontSize="sm" mt="2">
            {tournamentName} hosted by {leagueName}
          </Text>
          <Text mt="4">
            All matches play to {event.pointsToWin}, win by{" "}
            {event.pointsToWinBy}.
          </Text>
        </Box>
        {showRegistrationButtons && (
          <Box
            d="flex"
            alignItems="flex-end"
            justifyContent="space-between"
            flexDir={{ base: "row", sm: "row", md: "column" }}
          >
            <RegistrationButtons
              league={league}
              event={event}
              tournament={tournament}
              showTournamentInfoButton={showTournamentInfoButton}
              refetchQueries={refetchQueries}
            />
          </Box>
        )}
      </SimpleGrid>

      <TeamsListViewer event={event} tournament={tournament} />

      {team && team?.players && (
        <Box mt="4">
          <YourTeamStatus tournament={tournament} team={team} />
        </Box>
      )}
    </Box>
  );
}

EventCard.propTypes = {
  event: PropTypes.object.isRequired,
  tournament: PropTypes.object.isRequired,
  league: PropTypes.object.isRequired,
  showRegistrationButtons: PropTypes.bool,
  refetchQueries: PropTypes.array,
};

export default EventCard;
