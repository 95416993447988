import {
  HStack,
  Text,
  Input,
  Icon,
  Button,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VisuallyHidden,
  useToast,
} from "@chakra-ui/react";
import { BiMailSend } from "react-icons/bi";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { SEND_INVITE } from "graphql/mutations";
import { DURATION } from "constants/duration";
import CopyUrlInput from "components/CopyUrlInput";
import TextDivider from "components/TextDivider";
import {
  getTeamByUserId,
  formatEventName,
  formatFullDateTime,
  getInvitePartnerUrl,
} from "utils/formatters";
import { useAuthContext } from "context/AuthContext";

function InvitePartnerModalContent({
  league,
  tournament,
  event,
  onClose,
  onComplete,
}) {
  const { authState } = useAuthContext();
  const user = authState?.user;
  const toast = useToast();
  const isRegistrationFree = tournament?.pricePerPerson === 0;
  const userId = user?.objectId;
  const userFullName = user?.fullname;
  const team = getTeamByUserId(userId, event);
  const teamId = team?.objectId;
  const tournamentName = tournament?.name;
  const leagueName = league?.name;
  const eventName = formatEventName(event);
  const eventDateTime = formatFullDateTime(event.eventStartTime);

  const [sendInvite, { loading: isSendingInvite }] = useMutation(SEND_INVITE);

  const invitePartnerModalBodyText = `Enter your partner's email to send them an invite. Your team will be fully registered once your partner accepts the invite${
    isRegistrationFree ? "" : " and you both pay the registration fee"
  }.`;

  return (
    <ModalContent>
      <Formik
        initialValues={{ invitePartnerEmail: "" }}
        validationSchema={Yup.object({
          invitePartnerEmail: Yup.string()
            .email("Invalid email address")
            .required("Please enter your partner's email address"),
        })}
        onSubmit={({ invitePartnerEmail }) => {
          // Send email
          const variables = {
            recipientEmail: invitePartnerEmail,
            teamId: teamId,
            inviterName: userFullName,
            inviterId: userId,
            tournamentName: tournamentName,
            leagueName: leagueName,
            eventName: eventName,
            eventDateTime: eventDateTime,
          };

          sendInvite({ variables })
            .then(() => {
              onComplete();
              toast({
                title: `An invite was sent to "${invitePartnerEmail}"`,
                status: "success",
                duration: DURATION.MEDIUM,
                isClosable: true,
              });
            })
            .catch((e) => {
              toast({
                title: `Sorry, there was a problem sending an email to "${invitePartnerEmail}"`,
                status: "error",
                duration: DURATION.LONG,
                isClosable: true,
              });
            });
        }}
      >
        <Form>
          <ModalHeader>Send Invite</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb="2">Copy invite link</Text>
            <CopyUrlInput
              url={getInvitePartnerUrl(teamId, userId)}
              toastText="Invite link copied clipboard"
            />

            <TextDivider text="or" my="10" />

            <Text>{invitePartnerModalBodyText}</Text>
            <Field name="invitePartnerEmail">
              {({ field, form }) => (
                <FormControl
                  mt="3"
                  isInvalid={
                    form.errors.invitePartnerEmail &&
                    form.touched.invitePartnerEmail
                  }
                >
                  <VisuallyHidden>
                    <FormLabel htmlFor="invitePartnerEmail">
                      Partner email
                    </FormLabel>
                  </VisuallyHidden>
                  <Input
                    {...field}
                    placeholder="Your partner's email"
                    id="invitePartnerEmail"
                  />
                  <FormErrorMessage>
                    {form.errors.invitePartnerEmail}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </ModalBody>
          <ModalFooter d="flex" justifyContent="space-between">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <HStack spacing="3">
              <Button
                colorScheme="teal"
                variant="solid"
                isLoading={isSendingInvite}
                type="submit"
              >
                Send invite
                <Icon as={BiMailSend} w={5} h={5} ml={2} />
              </Button>
            </HStack>
          </ModalFooter>
        </Form>
      </Formik>
    </ModalContent>
  );
}

export default InvitePartnerModalContent;
