import { Box, Button, Icon } from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

const UNSET = "unset";

// "See more" button area needs a constant height set before the button
// if conditionally rendered.  Else scrolling to useHashFragment() hook may not
// scroll to correct position.
const SEE_MORE_BUTTON_HEIGHT = 24;

function TextExpander({ children, maxHeightProp = 80 }) {
  const ref = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState(maxHeightProp);
  const [innerBoxHeight, setInnerBoxHeight] = useState(0);
  const isSeeMoreVisible = innerBoxHeight > maxHeightProp;

  useEffect(() => {
    const innerBox = ref.current;
    setInnerBoxHeight(innerBox.clientHeight);
  }, []);

  return (
    <>
      <Box maxHeight={`${maxHeight}px`} overflow="hidden">
        <Box ref={ref}>{children}</Box>
      </Box>
      <Box h={`${SEE_MORE_BUTTON_HEIGHT}px`}>
        {isSeeMoreVisible && (
          <Button
            variant="link"
            color="white"
            onClick={() => {
              setIsExpanded(!isExpanded);
              setMaxHeight(maxHeight === UNSET ? maxHeightProp : UNSET);
            }}
          >
            {isExpanded ? "see less" : "see more"}
            <Icon as={isExpanded ? BiChevronUp : BiChevronDown} w={5} h={5} />
          </Button>
        )}
      </Box>
    </>
  );
}

export default TextExpander;
