import { useMutation } from "@apollo/client";
import {
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from "@chakra-ui/react";
import { DURATION } from "constants/duration";
import { useAuthContext } from "context/AuthContext";
import { DELETE_PLAYER, DELETE_TEAM } from "graphql/mutations";
import {
  getPlayerFromEvent,
  getTeamByUserId,
  getTeamPlayersCount,
} from "utils/formatters";

/**
 *
 * @param {string} userId - User.objectId of the logged in user
 * @param {Object} event - Event object from Parse-GraphQL
 * @param {string} leaguePaypalEmail - League contact email to request a refund. This should be League.paypalEmail.
 * @param {boolean} isOpen - If modal is open
 * @param {Function} onClose - Called when modal is closed
 * @param {gql[]} refetchQueries - Array of
 */
function RegistrationWithdrawModal({
  league,
  tournament,
  event,
  isOpen,
  onClose,
  refetchQueries,
}) {
  const { authState } = useAuthContext();
  const userId = authState?.user?.objectId;
  const player = getPlayerFromEvent(userId, event);
  const toast = useToast();
  const isRegistrationFree = tournament?.pricePerPerson === 0;
  const leaguePaypalEmail = league?.paypalEmail;

  const [deletePlayer, { loading: isDeletingPlayer }] = useMutation(
    DELETE_PLAYER,
    {
      refetchQueries,
      variables: {
        playerId: player?.objectId,
      },
    }
  );
  const [deleteExistingTeam, { loading: isDeletingExistingTeam }] =
    useMutation(DELETE_TEAM);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Withdraw from event</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb="4">Are you sure you want to withdraw from this event?</Text>
          <Text>
            For any refund inquires please reach out to{" "}
            {leaguePaypalEmail ? leaguePaypalEmail : "the league contact"}.
          </Text>
        </ModalBody>
        <ModalFooter d="flex" justifyContent="space-between">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            variant="ghost"
            isLoading={isDeletingPlayer || isDeletingExistingTeam}
            onClick={() => {
              const existingTeam = getTeamByUserId(userId, event);
              const isLastPlayerOnTeam =
                getTeamPlayersCount(existingTeam) === 1;

              const maybeDeleteTeam =
                existingTeam && isLastPlayerOnTeam
                  ? deleteExistingTeam
                  : () => Promise.resolve();

              maybeDeleteTeam({ variables: { teamId: existingTeam?.objectId } })
                .then(() => {
                  return deletePlayer();
                })
                .then(() => {
                  onClose();

                  let toastMessage =
                    "Your have successfully withdrawn from the event.";
                  if (leaguePaypalEmail && !isRegistrationFree) {
                    toastMessage += ` To request a refund, please reach out to ${leaguePaypalEmail}.`;
                  }

                  toast({
                    title: toastMessage,
                    status: "success",
                    duration: DURATION.LONG,
                    isClosable: true,
                  });
                });
            }}
          >
            Withdraw
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default RegistrationWithdrawModal;
