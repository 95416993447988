import {
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorModeValue,
} from "@chakra-ui/react";
import { useTable, useSortBy } from "react-table";
import { useMemo } from "react";
import { FaSortDown, FaSortUp, FaSort } from "react-icons/fa";

function TeamRankingTable({ teams = [] }) {
  const headerColor = useColorModeValue("gray.800", "whiteAlpha.900");
  const data = teams;

  const columns = useMemo(
    () => [
      {
        Header: <HeaderText>Team</HeaderText>,
        accessor: "teamName",
      },
      {
        Header: <HeaderText>Total Points</HeaderText>,
        accessor: "totalPoints",
        sortType: "basic",
        isNumeric: true,
      },
      {
        Header: <HeaderText>Point Diff.</HeaderText>,
        accessor: "pointDiff",
        sortType: "basic",
        isNumeric: true,
      },
      {
        Header: <HeaderText>Total Wins</HeaderText>,
        accessor: "totalWins",
        sortType: "basic",
        isNumeric: true,
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <Table {...getTableProps()} size="sm" data-testid="team-ranking-table">
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, headerIndex) => (
              <Th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                isNumeric={column.isNumeric}
                py="2"
              >
                <Box
                  d="flex"
                  alignItems="center"
                  color={headerColor}
                  justifyContent={headerIndex === 0 ? "flex-start" : "flex-end"}
                >
                  {column.render("Header")}
                  <Text as="span" pl="2">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FaSortDown aria-label="sorted descending" />
                      ) : (
                        <FaSortUp aria-label="sorted ascending" />
                      )
                    ) : (
                      <FaSort aria-label="sort" />
                    )}
                  </Text>
                </Box>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric}>
                  {cell.render("Cell")}
                </Td>
              ))}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}

function HeaderText({ children }) {
  return (
    <Text as="span" fontSize="sm" textTransform="none">
      {children}
    </Text>
  );
}

export default TeamRankingTable;
