import { useMutation } from "@apollo/client";
import {
  Button,
  Modal,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Icon,
  Input,
  useToast,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { DURATION } from "constants/duration";
import { RESET_PASSWORD } from "graphql/mutations";
import { BiMailSend } from "react-icons/bi";

/**
 *
 * @param {string} email - If provided, modal will show confirmation to send password reset email.
 *                         If not, email input provided to send reset email.
 * @param {boolean} isOpen - If modal is open
 * @param {Function} onClose - Called when modal is closed
 */
function ResetPasswordModal({ email, isOpen, onClose }) {
  const toast = useToast();
  const [resetPassword, { loading: isPasswordResetEmailSending }] =
    useMutation(RESET_PASSWORD);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reset password</ModalHeader>
        <ModalCloseButton />

        <Formik
          initialValues={{
            email: email || "",
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email("Invalid email address")
              .required("Required"),
          })}
          onSubmit={({ email }) => {
            resetPassword({ variables: { email } })
              .then(() => {
                toast({
                  title: `A password reset link was sent to "${email}".`,
                  status: "success",
                  duration: DURATION.MEDIUM,
                  isClosable: true,
                });
                onClose();
              })
              .catch((e) => {
                toast({
                  title: "Oops something went wrong. Please try again.",
                  status: "error",
                  duration: DURATION.LONG,
                  isClosable: true,
                });
              });
          }}
        >
          <Form>
            <ModalBody>
              {email && (
                <Text>
                  Are you sure you want to send a password reset link to {email}
                  ?
                </Text>
              )}
              {!email && (
                <Text>
                  Please enter your email to send a password reset link.
                </Text>
              )}

              <Field name="email">
                {({ field, form }) => (
                  <FormControl
                    mt="3"
                    isInvalid={form.errors.email && form.touched.email}
                    hidden={!!email}
                  >
                    <FormLabel hidden={true} htmlFor="email">
                      Reset password email
                    </FormLabel>
                    <Input {...field} id="email" placeholder="Email" />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </ModalBody>

            <ModalFooter d="flex" justifyContent="space-between">
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>

              <Button
                colorScheme="teal"
                isLoading={isPasswordResetEmailSending}
                variant="solid"
                type="submit"
              >
                Send
                <Icon as={BiMailSend} w={5} h={5} ml={2} />
              </Button>
            </ModalFooter>
          </Form>
        </Formik>
      </ModalContent>
    </Modal>
  );
}

export default ResetPasswordModal;
