import {
  getTeamGroups,
  getTeamPlayerNames,
  getNumPlayersFromRegisteredTeams,
  getDecoratedTeams,
} from "utils/formatters";
import { SimpleGrid, Icon, Badge, Box, Heading, Text } from "@chakra-ui/react";
import { BiUser } from "react-icons/bi";
import QuestionMarkTooltip from "components/QuestionMarkTooltip";

function TeamsListViewer({ event, tournament }) {
  const { teamsFullyRegistered, teamsNeedingPartner } = getTeamGroups(
    event,
    tournament
  );

  const numRegisteredPlayers =
    getNumPlayersFromRegisteredTeams(teamsFullyRegistered);

  const decoratedTeams = getDecoratedTeams(teamsFullyRegistered, event);

  return (
    <Box d="flex" flexDir="column">
      <SimpleGrid columns={[1, null, 2]}>
        <Box mt="4">
          <Heading as="h6" size="sm" mb="4px">
            Registered Teams ({`${numRegisteredPlayers}/${event.playersMax} `}
            Players
            <Icon as={BiUser} w={5} h={5} mb="-4px" ml="2px" />)
          </Heading>
          {!decoratedTeams.length && <Text as="i">None</Text>}
          {!!decoratedTeams.length && (
            <Text as="ol" listStylePos="inside">
              {decoratedTeams.map((team) => {
                return (
                  <Text as="li" key={team.objectId}>
                    {getTeamPlayerNames(team)}{" "}
                    {team?.isWaitlisted && (
                      <Badge ml="1" mt="-2px" colorScheme="blue">
                        Waitlisted
                      </Badge>
                    )}
                  </Text>
                );
              })}
            </Text>
          )}
        </Box>
        <Box mt="4">
          <Heading as="h6" size="sm" mb="4px">
            Players who need a partner
            <QuestionMarkTooltip text="These are registered players who do not have a partner. For paid events, you must pay first to be considered registered." />
          </Heading>
          {!teamsNeedingPartner.length && <Text as="i">None</Text>}
          {!!teamsNeedingPartner.length && (
            <Text as="ul" listStylePos="inside">
              {teamsNeedingPartner.map((team) => {
                return <li key={team.objectId}>{getTeamPlayerNames(team)}</li>;
              })}
            </Text>
          )}
        </Box>
      </SimpleGrid>
    </Box>
  );
}

export default TeamsListViewer;
