// eslint-disable-next-line import/no-anonymous-default-export
export default {
  id: "match:18",
  children: [
    {
      id: "match:15",
      loserTo: "team:L15",
      children: [
        {
          id: "match:11",
          loserTo: "team:L11",
          children: [
            {
              id: "match:3",
              loserTo: "team:L3",
              children: [
                {
                  id: "team:4",
                },
                {
                  id: "team:5",
                },
              ],
            },
            {
              id: "match:5",
              loserTo: "team:L5",
              children: [
                {
                  id: "match:1",
                  children: [
                    {
                      id: "team:8",
                    },
                    {
                      id: "team:9",
                    },
                  ],
                },
                {
                  id: "team:1",
                },
              ],
            },
          ],
        },
        {
          id: "match:12",
          loserTo: "team:L12",
          children: [
            {
              id: "match:6",
              loserTo: "team:L6",
              children: [
                {
                  id: "team:2",
                },
                {
                  id: "match:2",
                  children: [
                    {
                      id: "team:7",
                    },
                    {
                      id: "team:10",
                    },
                  ],
                },
              ],
            },
            {
              id: "match:4",
              loserTo: "team:L4",
              children: [
                {
                  id: "team:3",
                },
                {
                  id: "team:6",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "match:17",
      children: [
        {
          id: "team:L15",
        },
        {
          id: "match:16",
          children: [
            {
              id: "match:13",
              children: [
                {
                  id: "team:L12",
                },
                {
                  id: "match:9",
                  children: [
                    {
                      id: "match:7",
                      children: [
                        {
                          id: "team:L2",
                        },
                        {
                          id: "team:L5",
                        },
                      ],
                    },
                    {
                      id: "team:L3",
                    },
                  ],
                },
              ],
            },
            {
              id: "match:14",
              children: [
                {
                  id: "match:10",
                  children: [
                    {
                      id: "team:L4",
                    },
                    {
                      id: "match:8",
                      children: [
                        {
                          id: "team:L1",
                        },
                        {
                          id: "team:L6",
                        },
                      ],
                    },
                  ],
                },
                {
                  id: "team:L11",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
