import { Box, Icon } from "@chakra-ui/react";
import { IoManOutline, IoWomanOutline } from "react-icons/io5";
import { BiSitemap, BiRefresh } from "react-icons/bi";
import { AiOutlineLine } from "react-icons/ai";

/**
 * 'Bye' team id. Default to the prod bye team id.
 * Used as an empty team placeholder in Event Scheduler.
 */
export const BYE_TEAM_ID = process.env.REACT_APP_BYE_TEAM_ID || "6pgufHOZlm";
export const BYE_PLAYER_ID =
  process.env.REACT_APP_BYE_PLAYER_ID || "nRAjQDAFe7";
export const BYE_USER_ID = process.env.REACT_APP_BYE_USER_ID || "TDMnYwuiAx";

/**
 * All constants related to the 'Event' resource.
 */
export const EVENT_FIELDS = {
  EVENT_TYPE: "eventType",
  EVENT_FORMAT: "eventFormat",
  EVENT_START_TIME: "eventStartTime",
  SKILL_LEVEL: "skillLevel",
  AGE_RANGE: "ageRange",
  PLAYERS_MAX: "playersMax",
  POINTS_TO_WIN: "pointsToWin",
  POINTS_TO_WIN_BY: "pointsToWinBy",
};

/**
 * Event.eventType
 */
export const EVENT_TYPE_OPTIONS = {
  ANY_DOUBLES: "ANY_DOUBLES",
  MIXED_DOUBLES: "MIXED_DOUBLES",
  MENS_DOUBLES: "MENS_DOUBLES",
  WOMENS_DOUBLES: "WOMENS_DOUBLES",
  MENS_SINGLES: "MENS_SINGLES",
  WOMENS_SINGLES: "WOMENS_SINGLES",
};

export const DOUBLES_EVENT_TYPES = [
  EVENT_TYPE_OPTIONS.ANY_DOUBLES,
  EVENT_TYPE_OPTIONS.MIXED_DOUBLES,
  EVENT_TYPE_OPTIONS.MENS_DOUBLES,
  EVENT_TYPE_OPTIONS.WOMENS_DOUBLES,
];

export const SINGLES_EVENT_TYPES = [
  EVENT_TYPE_OPTIONS.MENS_SINGLES,
  EVENT_TYPE_OPTIONS.WOMENS_SINGLES,
];

export const EVENT_TYPE_OPTIONS_LIST = Object.keys(EVENT_TYPE_OPTIONS);

export const EVENT_TYPE_OPTION_NAMES = {
  [EVENT_TYPE_OPTIONS.ANY_DOUBLES]: "Any Doubles",
  [EVENT_TYPE_OPTIONS.MIXED_DOUBLES]: "Mixed Doubles",
  [EVENT_TYPE_OPTIONS.MENS_DOUBLES]: "Men's Doubles",
  [EVENT_TYPE_OPTIONS.WOMENS_DOUBLES]: "Women's Doubles",
  [EVENT_TYPE_OPTIONS.MENS_SINGLES]: "Men's Singles",
  [EVENT_TYPE_OPTIONS.WOMENS_SINGLES]: "Women's Singles",
};

export const EVENT_TYPE_OPTION_ICONS = {
  [EVENT_TYPE_OPTIONS.ANY_DOUBLES]: AnyDoublesIcons,
  [EVENT_TYPE_OPTIONS.MIXED_DOUBLES]: MixedDoublesIcons,
  [EVENT_TYPE_OPTIONS.MENS_DOUBLES]: MensDoublesIcons,
  [EVENT_TYPE_OPTIONS.WOMENS_DOUBLES]: WomensDoublesIcons,
  [EVENT_TYPE_OPTIONS.MENS_SINGLES]: MensSinglesIcons,
  [EVENT_TYPE_OPTIONS.WOMENS_SINGLES]: WomensSinglesIcons,
};

export const EVENT_TYPE_OPTION_DESCRIPTIONS = {
  [EVENT_TYPE_OPTIONS.ANY_DOUBLES]: {
    text: "Doubles teams can be any combination of men and women.",
  },
  [EVENT_TYPE_OPTIONS.MIXED_DOUBLES]: {
    text: "Doubles teams are one man and one woman.",
  },
  [EVENT_TYPE_OPTIONS.MENS_DOUBLES]: {
    text: "Doubles teams are men only.",
  },
  [EVENT_TYPE_OPTIONS.WOMENS_DOUBLES]: {
    text: "Doubles teams are women only.",
  },
  [EVENT_TYPE_OPTIONS.MENS_SINGLES]: {
    text: "Singles matches are men only.",
  },
  [EVENT_TYPE_OPTIONS.WOMENS_SINGLES]: {
    text: "Singles matches are women only.",
  },
};

/**
 * Event.eventFormat
 */
export const EVENT_FORMAT_OPTIONS = {
  ROUND_ROBIN_TEAM: "ROUND_ROBIN_TEAM",
  DOUBLE_ELIMINATION: "DOUBLE_ELIMINATION",
  ROUND_ROBIN_INDIVIDUAL: "ROUND_ROBIN_INDIVIDUAL",
  SINGLE_ELIMINATION: "SINGLE_ELIMINATION",
};

export const SUPPORTED_EVENT_FORMAT_OPTIONS_LIST = [
  EVENT_FORMAT_OPTIONS.ROUND_ROBIN_TEAM,
  EVENT_FORMAT_OPTIONS.DOUBLE_ELIMINATION,
];

export const EVENT_FORMAT_OPTION_NAMES = {
  [EVENT_FORMAT_OPTIONS.ROUND_ROBIN_TEAM]: "Round Robin by Team",
  [EVENT_FORMAT_OPTIONS.ROUND_ROBIN_INDIVIDUAL]: "Round Robin by Individual",
  [EVENT_FORMAT_OPTIONS.DOUBLE_ELIMINATION]: "Double Elimination",
  [EVENT_FORMAT_OPTIONS.SINGLE_ELIMINATION]: "Single Elimination",
};

export const EVENT_FORMAT_OPTION_ICONS = {
  [EVENT_FORMAT_OPTIONS.ROUND_ROBIN_TEAM]: RoundRobinIcons,
  [EVENT_FORMAT_OPTIONS.ROUND_ROBIN_INDIVIDUAL]: RoundRobinIcons,
  [EVENT_FORMAT_OPTIONS.DOUBLE_ELIMINATION]: DoubleEliminationIcons,
  [EVENT_FORMAT_OPTIONS.SINGLE_ELIMINATION]: SingleEliminationIcons,
};

export const EVENT_FORMAT_OPTION_DESCRIPTIONS = {
  [EVENT_FORMAT_OPTIONS.ROUND_ROBIN_TEAM]: {
    text: "All teams in same pool rotate to play each other at least once. Round robins typically takes the longest amount of time compared to Single or Double Elimination, but it's considered the fairest format.",
  },
  [EVENT_FORMAT_OPTIONS.ROUND_ROBIN_INDIVIDUAL]: {
    text: "All players in same pool rotate to form new teams each match. Round robin typically takes the longest amount of time compared to Single or Double Elimination, but it's considered the fairest format.",
  },
  [EVENT_FORMAT_OPTIONS.DOUBLE_ELIMINATION]: {
    text: "In a Double Elimination, participants are removed from the tournament if they lose two matches. This results in a slightly longer tournament compared to Single Elimination but it gives participants more chances to play each other.",
  },
  [EVENT_FORMAT_OPTIONS.SINGLE_ELIMINATION]: {
    text: "In a Single Elimination, participants are removed from the tournament if they lose one match. This results in a faster tournament but all participants don't get to play each other.",
  },
};

/**
 * Event.skillLevel
 */
export const SKILL_LEVEL_OPTIONS_LIST = [
  "2.0",
  "2.5",
  "3.0",
  "3.5",
  "4.0",
  "4.5",
  "5.0",
];

/**
 * Event.ageRange
 */

/**
 * Event.playersMax
 */
export const MAX_PLAYERS_INPUT_MAX = 100;
export const MAX_PLAYERS_INPUT_MIN = 4;

/**
 * Event.pointToWin
 */
export const POINT_TO_WIN_INPUT_MAX = 21;
export const POINT_TO_WIN_INPUT_MIN = 4;

/**
 * Event.pointsToWinBy
 */
export const WIN_BY_OPTIONS_LIST = [1, 2];

/**
 * Default values for all Event fields
 */
export const EVENT_FIELD_DEFAULTS = {
  [EVENT_FIELDS.EVENT_TYPE]: EVENT_TYPE_OPTIONS.ANY_DOUBLES,
  [EVENT_FIELDS.EVENT_FORMAT]: EVENT_FORMAT_OPTIONS.ROUND_ROBIN_TEAM,
  [EVENT_FIELDS.EVENT_START_TIME]: new Date(),
  [EVENT_FIELDS.SKILL_LEVEL]: "3.0",
  [EVENT_FIELDS.AGE_RANGE]: "0,100",
  [EVENT_FIELDS.PLAYERS_MAX]: 12,
  [EVENT_FIELDS.POINTS_TO_WIN]: 11,
  [EVENT_FIELDS.POINTS_TO_WIN_BY]: 2,
};

const iconSizeProps = {
  w: [3, 5, 7],
  h: [3, 5, 7],
};

function AnyDoublesIcons() {
  return (
    <Box mb="3">
      <Icon as={IoManOutline} {...iconSizeProps} />
      <Icon as={IoWomanOutline} {...iconSizeProps} />
      <Icon as={AiOutlineLine} {...iconSizeProps} transform="rotate(90deg)" />
      <Icon as={IoWomanOutline} {...iconSizeProps} />
      <Icon as={IoWomanOutline} {...iconSizeProps} />
    </Box>
  );
}

function MixedDoublesIcons() {
  return (
    <Box mb="3">
      <Icon as={IoManOutline} {...iconSizeProps} />
      <Icon as={IoWomanOutline} {...iconSizeProps} />
      <Icon as={AiOutlineLine} {...iconSizeProps} transform="rotate(90deg)" />
      <Icon as={IoManOutline} {...iconSizeProps} />
      <Icon as={IoWomanOutline} {...iconSizeProps} />
    </Box>
  );
}

function MensDoublesIcons() {
  return (
    <Box mb="3">
      <Icon as={IoManOutline} {...iconSizeProps} />
      <Icon as={IoManOutline} {...iconSizeProps} />
      <Icon as={AiOutlineLine} {...iconSizeProps} transform="rotate(90deg)" />
      <Icon as={IoManOutline} {...iconSizeProps} />
      <Icon as={IoManOutline} {...iconSizeProps} />
    </Box>
  );
}

function WomensDoublesIcons() {
  return (
    <Box mb="3">
      <Icon as={IoWomanOutline} {...iconSizeProps} />
      <Icon as={IoWomanOutline} {...iconSizeProps} />
      <Icon as={AiOutlineLine} {...iconSizeProps} transform="rotate(90deg)" />
      <Icon as={IoWomanOutline} {...iconSizeProps} />
      <Icon as={IoWomanOutline} {...iconSizeProps} />
    </Box>
  );
}

function MensSinglesIcons() {
  return (
    <Box mb="3">
      <Icon as={IoManOutline} {...iconSizeProps} />
      <Icon as={AiOutlineLine} {...iconSizeProps} transform="rotate(90deg)" />
      <Icon as={IoManOutline} {...iconSizeProps} />
    </Box>
  );
}

function WomensSinglesIcons() {
  return (
    <Box mb="3">
      <Icon as={IoWomanOutline} {...iconSizeProps} />
      <Icon as={AiOutlineLine} {...iconSizeProps} transform="rotate(90deg)" />
      <Icon as={IoWomanOutline} {...iconSizeProps} />
    </Box>
  );
}

function RoundRobinIcons() {
  return (
    <Box mb="3">
      <Icon as={BiRefresh} {...iconSizeProps} />
    </Box>
  );
}

function SingleEliminationIcons() {
  return (
    <Box mb="3">
      <Icon as={BiSitemap} {...iconSizeProps} transform="rotate(90deg)" />
    </Box>
  );
}

function DoubleEliminationIcons() {
  return (
    <Box mb="3">
      <Icon
        as={BiSitemap}
        {...iconSizeProps}
        transform="rotate(90deg)"
        mr="3"
      />
      <Icon as={BiSitemap} {...iconSizeProps} transform="rotate(90deg)" />
    </Box>
  );
}
