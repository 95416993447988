import { gql } from "@apollo/client";

export const LEAGUE_FIELDS_FRAGMENT = gql`
  fragment LeagueFieldsFragment on League {
    objectId
    name
    paypalEmail
    paypalMerchantId
    createdBy {
      objectId
    }
  }
`;

export const TOURNAMENT_FIELDS_FRAGMENT = gql`
  fragment TournamentFieldsFragment on Tournament {
    objectId
    isPublished
    name
    description
    location
    parkingInstructions
    pricePerPerson
    priceAfterFirstEvent
  }
`;

export const EVENT_FIELDS_FRAGMENT = gql`
  fragment EventFieldsFragment on Event {
    objectId
    eventType
    eventFormat
    eventStartTime
    eventSpec
    skillLevel
    ageRange
    playersMax
    pointsToWin
    pointsToWinBy
    canRegister
  }
`;

export const TEAM_FIELDS_FRAGMENT = gql`
  fragment TeamFieldsFragment on Team {
    objectId
    players {
      edges {
        node {
          hasPaid
          objectId
          user {
            objectId
            fullname
          }
        }
      }
    }
  }
`;

export const GAME_FIELDS_FRAGMENT = gql`
  ${TEAM_FIELDS_FRAGMENT}
  fragment GameFieldsFragment on Game {
    objectId
    teamOne {
      ...TeamFieldsFragment
    }
    teamTwo {
      ...TeamFieldsFragment
    }
    teamOneScore
    teamTwoScore
  }
`;

export const MEDIA_FIELDS_FRAGMENT = gql`
  fragment MediaFieldsFragment on Media {
    objectId
    createdAt
    website
    file {
      name
      url
    }
  }
`;

export const GET_USER_FIRST_LEAGUE = gql`
  query getUserFirstLeague($userId: ID!) {
    user(id: $userId) {
      objectId
      fullname
      canCreateLeague
      leagues {
        edges {
          node {
            objectId
            name
          }
        }
      }
    }
  }
`;

export const GET_LEAGUE = gql`
  ${LEAGUE_FIELDS_FRAGMENT}
  query getLeague($leagueId: ID!) {
    league(id: $leagueId) {
      ...LeagueFieldsFragment
    }
  }
`;

export const GET_TOURNAMENT = gql`
  ${TOURNAMENT_FIELDS_FRAGMENT}
  ${MEDIA_FIELDS_FRAGMENT}
  query getTournament($tournamentId: ID!) {
    tournament(id: $tournamentId) {
      ...TournamentFieldsFragment
      sponsorImages {
        edges {
          node {
            ...MediaFieldsFragment
          }
        }
      }
    }
  }
`;

export const GET_TOURNAMENT_EVENTS = gql`
  ${EVENT_FIELDS_FRAGMENT}
  ${LEAGUE_FIELDS_FRAGMENT}
  ${MEDIA_FIELDS_FRAGMENT}
  query getTournamentEvents($tournamentId: ID!) {
    tournament(id: $tournamentId) {
      objectId
      isPublished
      name
      description
      location
      parkingInstructions
      pricePerPerson
      priceAfterFirstEvent
      sponsorImages {
        edges {
          node {
            ...MediaFieldsFragment
          }
        }
      }
      league {
        ...LeagueFieldsFragment
      }
      events {
        edges {
          node {
            ...EventFieldsFragment
            teams {
              edges {
                node {
                  objectId
                  createdAt
                  players {
                    edges {
                      node {
                        objectId
                        hasPaid
                        user {
                          objectId
                          fullname
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_UPCOMING_TOURNAMENTS = gql`
  ${TOURNAMENT_FIELDS_FRAGMENT}
  query getUpcomingTournaments($startTime: Date!, $maxResults: Int = 10) {
    tournaments(
      first: $maxResults
      where: {
        isPublished: { equalTo: true }
        events: { have: { eventStartTime: { greaterThan: $startTime } } }
      }
    ) {
      edges {
        node {
          ...TournamentFieldsFragment
          events {
            edges {
              node {
                objectId
                eventStartTime
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PAST_TOURNAMENTS = gql`
  ${TOURNAMENT_FIELDS_FRAGMENT}
  query getPastTournaments($startTime: Date!, $maxResults: Int = 10) {
    tournaments(
      first: $maxResults
      where: {
        isPublished: { equalTo: true }
        events: { have: { eventStartTime: { lessThan: $startTime } } }
      }
    ) {
      edges {
        node {
          ...TournamentFieldsFragment
          events {
            edges {
              node {
                objectId
                eventStartTime
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_LEAGUE_TOURNAMENTS = gql`
  query getLeagueTournaments($leagueId: ID!) {
    tournaments(
      where: { league: { have: { objectId: { equalTo: $leagueId } } } }
    ) {
      edges {
        node {
          objectId
          name
        }
      }
    }
  }
`;

export const GET_GAME = gql`
  ${GAME_FIELDS_FRAGMENT}
  query getGame($gameId: ID!) {
    game(id: $gameId) {
      ...GameFieldFragment
    }
  }
`;

export const GET_USER = gql`
  query getUser($userId: ID!) {
    user(id: $userId) {
      objectId
      fullname
      email
      phone
    }
  }
`;

// Used on My League Page
export const GET_USER_LEAGUE_TOURNAMENTS = gql`
  ${LEAGUE_FIELDS_FRAGMENT}
  ${TOURNAMENT_FIELDS_FRAGMENT}
  ${EVENT_FIELDS_FRAGMENT}
  query getUserLeagueTournaments($userId: ID!) {
    user(id: $userId) {
      leagues(first: 1) {
        edges {
          node {
            ...LeagueFieldsFragment
            tournaments {
              edges {
                node {
                  ...TournamentFieldsFragment
                  events(order: eventStartTime_ASC) {
                    edges {
                      node {
                        ...EventFieldsFragment
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_EVENT = gql`
  ${EVENT_FIELDS_FRAGMENT}
  ${TEAM_FIELDS_FRAGMENT}
  query getEvent($eventId: ID!) {
    event(id: $eventId) {
      ...EventFieldsFragment
      tournament {
        objectId
        name
        pricePerPerson
        league {
          objectId
          name
          createdBy {
            objectId
          }
        }
      }
      teams {
        edges {
          node {
            createdAt
            ...TeamFieldsFragment
          }
        }
      }
    }
  }
`;

export const GET_EVENT_SCHEDULE = gql`
  ${EVENT_FIELDS_FRAGMENT}
  ${TEAM_FIELDS_FRAGMENT}
  query getEventGames($eventId: ID!) {
    event(id: $eventId) {
      ...EventFieldsFragment
      eventActualStartTime
      eventActualEndTime
      tournament {
        objectId
        name
        pricePerPerson
        league {
          objectId
          name
          createdBy {
            objectId
          }
        }
      }
      teams {
        edges {
          node {
            createdAt
            ...TeamFieldsFragment
          }
        }
      }
      eventSpec
      games {
        edges {
          node {
            objectId
            teamOne {
              ...TeamFieldsFragment
            }
            teamTwo {
              ...TeamFieldsFragment
            }
            teamOneScore
            teamTwoScore
          }
        }
      }
    }
  }
`;

// Used on My Events Page
export const GET_EVENTS_WHERE_TEAMS_HAVE_USER = gql`
  ${LEAGUE_FIELDS_FRAGMENT}
  ${TOURNAMENT_FIELDS_FRAGMENT}
  ${EVENT_FIELDS_FRAGMENT}
  ${TEAM_FIELDS_FRAGMENT}
  query getEventsWhereTeamsHaveUser($userId: ID!) {
    events(
      where: {
        teams: {
          have: {
            players: {
              have: { user: { have: { objectId: { equalTo: $userId } } } }
            }
          }
        }
      }
    ) {
      edges {
        node {
          ...EventFieldsFragment
          tournament {
            ...TournamentFieldsFragment
            events {
              edges {
                node {
                  ...EventFieldsFragment
                  teams {
                    edges {
                      node {
                        ...TeamFieldsFragment
                      }
                    }
                  }
                }
              }
            }
            league {
              ...LeagueFieldsFragment
            }
          }
          teams {
            edges {
              node {
                objectId
                createdAt
                players {
                  edges {
                    node {
                      objectId
                      hasPaid
                      user {
                        objectId
                        fullname
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// This query seems to need explicit Team fields else teams are not populated in query.
// Tried to use TeamFieldsFragment but this seemed to cause missing TeamConnections in the Parse server.
export const GET_TEAM_INVITE = gql`
  ${EVENT_FIELDS_FRAGMENT}
  ${TOURNAMENT_FIELDS_FRAGMENT}
  ${LEAGUE_FIELDS_FRAGMENT}
  query getTeamInvite($teamId: ID!) {
    team(id: $teamId) {
      objectId
      players {
        edges {
          node {
            objectId
            hasPaid
            user {
              objectId
              fullname
            }
          }
        }
      }
      event {
        ...EventFieldsFragment
        teams {
          edges {
            node {
              objectId
              createdAt
              players {
                edges {
                  node {
                    objectId
                    hasPaid
                    user {
                      objectId
                      fullname
                    }
                  }
                }
              }
            }
          }
        }
        tournament {
          ...TournamentFieldsFragment
          events {
            edges {
              node {
                objectId
                teams {
                  edges {
                    node {
                      objectId
                      createdAt
                      players {
                        edges {
                          node {
                            objectId
                            hasPaid
                            user {
                              objectId
                              fullname
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          league {
            ...LeagueFieldsFragment
          }
        }
      }
    }
  }
`;
