import { useMutation, useQuery } from "@apollo/client";
import { Box, Text, Spinner, useToast } from "@chakra-ui/react";
import { GET_LEAGUE } from "graphql/queries";
import { useAuthContext } from "context/AuthContext";
import { useParams, useHistory } from "react-router-dom";
import { getQueryParams } from "utils/formatters";
import { DURATION } from "constants/duration";
import { SAVE_PAYPAL_MERCHANT_INFO } from "graphql/mutations";

function PayPalOnboardingCompletePage() {
  const toast = useToast();
  const history = useHistory();
  const { authState } = useAuthContext();
  const currentUserId = authState?.user?.objectId;
  const { leagueId } = useParams();
  const queryParamsObj = getQueryParams();

  const merchantIdInPayPal = queryParamsObj.get("merchantIdInPayPal");
  const permissionsGranted =
    queryParamsObj.get("permissionsGranted") === "true";

  const [savePayPalMerchantInfo] = useMutation(SAVE_PAYPAL_MERCHANT_INFO);

  useQuery(GET_LEAGUE, {
    variables: {
      leagueId,
    },
    onCompleted: async (leagueData) => {
      const league = leagueData?.league;
      const isLeagueOwnedByCurrentUser =
        currentUserId && currentUserId === league?.createdBy?.objectId;
      const errorMessages = [];

      if (!isLeagueOwnedByCurrentUser) {
        errorMessages.push("You do not own this league.");
      }

      if (!permissionsGranted) {
        errorMessages.push("Permission was not granted in PayPal onboarding.");
      }

      if (!merchantIdInPayPal) {
        errorMessages.push("merchantIdInPayPal param not provided.");
      }

      if (!errorMessages.length) {
        await savePayPalMerchantInfo({
          variables: {
            leagueId,
            paypalMerchantId: merchantIdInPayPal,
          },
        });

        history.replace(`/leagues/${leagueId}`);
      } else {
        toast({
          title: `Sorry, could not link PayPal account: ${errorMessages.join(
            " "
          )}`,
          status: "error",
          duration: DURATION.MEDIUM,
          isClosable: true,
        });
        history.replace(`/leagues/${leagueId}`);
      }
    },
  });

  return (
    <Box
      d="flex"
      h="100vh"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <Text mb="4">Linking your PayPal account...</Text>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Box>
  );
}

export default PayPalOnboardingCompletePage;
