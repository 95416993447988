function PrivacyPolicyPage() {
  return (
    <iframe
      title="Privacy Policy for DinkPal"
      style={{
        height: "100vh",
        border: "none",
        width: "100%",
      }}
      src="privacy-policy.html"
    />
  );
}

export default PrivacyPolicyPage;
