import { Modal, ModalOverlay } from "@chakra-ui/react";
import SubmitPaymentModalContent from "components/SubmitPaymentModalContent";
import { useAuthContext } from "context/AuthContext";
import PropTypes from "prop-types";
import { getPlayerFromEvent } from "utils/formatters";

/**
 * Handles payment for a particular player.
 * @param {Object} league - User object from Parse-GraphQL
 * @param {Object} tournament - Tournament object from Parse-GraphQL
 * @param {Object} player - Player object from Parse-GraphQL
 * @param {boolean} isOpen - Is CompletePaymentModal open or not
 * @param {Function} onClose - Call to close CompletePaymentModal
 * @param {gql[]} refetchQueries - Array of GraphQL queries to call after any mutation
 */
function CompletePaymentModal({
  league,
  tournament,
  event,
  isOpen,
  onClose,
  refetchQueries,
}) {
  const { authState } = useAuthContext();
  const userId = authState?.user?.objectId;
  const player = getPlayerFromEvent(userId, event) || {};

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <SubmitPaymentModalContent
        paypalEmail={league?.paypalEmail}
        tournament={tournament}
        event={event}
        playerId={player.objectId}
        onClose={onClose}
        onSuccess={onClose}
        refetchQueries={refetchQueries}
      />
    </Modal>
  );
}

CompletePaymentModal.propTypes = {
  league: PropTypes.object.isRequired,
  tournament: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refetchQueries: PropTypes.array,
};

export default CompletePaymentModal;
