import { useQuery } from "@apollo/client";
import {
  Button,
  Container,
  Box,
  Heading,
  SkeletonText,
} from "@chakra-ui/react";
import { GET_USER_LEAGUE_TOURNAMENTS } from "graphql/queries";
import { useAuthContext } from "context/AuthContext";
import { useHistory } from "react-router-dom";
import { groupTournamentEventsByDate } from "utils/formatters";
import CardLoader from "components/CardLoader";
import TournamentsList from "components/TournamentsList";
import TournamentStatus from "components/TournamentStatus";

function MyLeaguePage() {
  const history = useHistory();
  const { authState } = useAuthContext();

  const {
    loading: isLoadingUserLeagueTournaments,
    data: userLeagueTournaments,
  } = useQuery(GET_USER_LEAGUE_TOURNAMENTS, {
    // fetchPolicy "no-cache" is needed to show latest tournaments when they are created or deleted
    fetchPolicy: "no-cache",
    variables: {
      userId: authState.user.objectId,
    },
  });
  const league = userLeagueTournaments?.user?.leagues?.edges?.[0]?.node;
  const leagueName = league?.name;
  const leagueId = league?.objectId;
  const tournaments = (league?.tournaments?.edges || []).map(({ node }) =>
    groupTournamentEventsByDate(node)
  );
  let { pastTournaments, upcomingTournaments } = tournaments.reduce(
    (accum, tournament) => {
      const isInPast = new Date() > new Date(tournament.lastEventTime);
      if (isInPast) {
        accum.pastTournaments.push(tournament);
      } else {
        accum.upcomingTournaments.push(tournament);
      }
      return accum;
    },
    {
      upcomingTournaments: [],
      pastTournaments: [],
    }
  );

  // Upcoming tournaments should be shown in ascending order top to bottom
  upcomingTournaments = upcomingTournaments.sort(
    (a, b) => new Date(a.lastEventTime) - new Date(b.lastEventTime)
  );
  // Past tournaments should be shown in descending order top to bottom
  pastTournaments = pastTournaments.sort(
    (a, b) => new Date(b.lastEventTime) - new Date(a.lastEventTime)
  );

  return (
    <Container
      data-testid="my-league-page"
      maxW="container.lg"
      mt={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}
    >
      <Box
        d="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}
      >
        <Box>
          {isLoadingUserLeagueTournaments ? (
            <SkeletonText noOfLines={1} minW="200px" />
          ) : (
            <Heading fontSize="lg">{leagueName}</Heading>
          )}
        </Box>
        <Box>
          <Button
            colorScheme="teal"
            size="md"
            onClick={() => {
              history.push(`/leagues/${leagueId}/tournaments/new`);
            }}
          >
            New tournament
          </Button>
        </Box>
      </Box>

      <Box mb="20">
        <Heading as="h2" mb="4">
          Upcoming tournaments
        </Heading>
        <CardLoader
          numSections={1}
          noOfLines={2}
          isLoading={isLoadingUserLeagueTournaments}
        >
          <TournamentsList
            tournaments={upcomingTournaments}
            onClick={(tournament) => {
              history.push(`/my-league/tournaments/${tournament?.objectId}`);
            }}
            yieldRight={(tournament) => (
              <TournamentStatus tournament={tournament} />
            )}
          />
        </CardLoader>
      </Box>

      <Box>
        <Heading as="h2" mb="4">
          Past tournaments
        </Heading>
        <CardLoader
          numSections={1}
          noOfLines={2}
          isLoading={isLoadingUserLeagueTournaments}
        >
          <TournamentsList
            tournaments={pastTournaments}
            onClick={(tournament) => {
              history.push(`/my-league/tournaments/${tournament?.objectId}`);
            }}
            yieldRight={(tournament) => (
              <TournamentStatus tournament={tournament} />
            )}
          />
        </CardLoader>
      </Box>
    </Container>
  );
}

export default MyLeaguePage;
