import { EVENT_FORMAT_OPTIONS } from "utils/event";
import doubleElim03TeamsTemplate from "bracketTemplates/doubleElim03TeamsTemplate";
import doubleElim04TeamsTemplate from "bracketTemplates/doubleElim04TeamsTemplate";
import doubleElim05TeamsTemplate from "bracketTemplates/doubleElim05TeamsTemplate";
import doubleElim06TeamsTemplate from "bracketTemplates/doubleElim06TeamsTemplate";
import doubleElim07TeamsTemplate from "bracketTemplates/doubleElim07TeamsTemplate";
import doubleElim08TeamsTemplate from "bracketTemplates/doubleElim08TeamsTemplate";
import doubleElim09TeamsTemplate from "bracketTemplates/doubleElim09TeamsTemplate";
import doubleElim10TeamsTemplate from "bracketTemplates/doubleElim10TeamsTemplate";
import doubleElim11TeamsTemplate from "bracketTemplates/doubleElim11TeamsTemplate";
import doubleElim12TeamsTemplate from "bracketTemplates/doubleElim12TeamsTemplate";

const doubleElimTemplatesMap = {
  3: doubleElim03TeamsTemplate,
  4: doubleElim04TeamsTemplate,
  5: doubleElim05TeamsTemplate,
  6: doubleElim06TeamsTemplate,
  7: doubleElim07TeamsTemplate,
  8: doubleElim08TeamsTemplate,
  9: doubleElim09TeamsTemplate,
  10: doubleElim10TeamsTemplate,
  11: doubleElim11TeamsTemplate,
  12: doubleElim12TeamsTemplate,
};

export function getBracketTemplate(format, numTeams) {
  switch (format) {
    case EVENT_FORMAT_OPTIONS.DOUBLE_ELIMINATION:
      return doubleElimTemplatesMap[numTeams] || null;
    default:
      return null;
  }
}
