import {
  Text,
  Heading,
  Container,
  Input,
  Button,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Icon,
  useToast,
  useDisclosure,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { BiCheckCircle } from "react-icons/bi";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { ROUTES } from "constants/routes";
import { useHistory, useParams } from "react-router-dom";
import {
  CREATE_LEAGUE,
  GET_PAYPAL_SIGNUP_LINK,
  UPDATE_LEAGUE,
  UPDATE_USER_WITH_LEAGUE,
} from "graphql/mutations";
import { openInNewTab } from "utils/formatters";
import { GET_LEAGUE } from "graphql/queries";
import { useAuthContext } from "context/AuthContext";
import HeaderBack from "components/HeaderBack";
import { DURATION } from "constants/duration";
import PageLoader from "components/PageLoader";
import ConfirmModal from "components/ConfirmModal";
import { FaPaypal } from "react-icons/fa";

/**
 * Used to create new or update existing league.
 */
function LeagueEditPage() {
  const toast = useToast();
  const history = useHistory();
  const { authState, setAuthState } = useAuthContext();
  const userId = authState.user.objectId;
  const { leagueId } = useParams();
  const isNewLeague = leagueId === "new";

  // Create League
  const [createLeague, { loading: isCreatingLeague }] =
    useMutation(CREATE_LEAGUE);
  // Update User with League
  const [updateUserWithLeague, { loading: isUpdatingUserWithLeague }] =
    useMutation(UPDATE_USER_WITH_LEAGUE);
  // Update League
  const [updateLeague, { loading: isUpdatingLeague }] =
    useMutation(UPDATE_LEAGUE);
  // Unlink PayPal Account
  const [unlinkPayPal, { loading: isUnlinkingPayPal }] =
    useMutation(UPDATE_LEAGUE);

  const [getPayPalSignUpLink, { loading: isGettingPayPalSignUpLink }] =
    useMutation(GET_PAYPAL_SIGNUP_LINK);

  const {
    isOpen: isUnlinkPayPalConfirmOpen,
    onOpen: onUnlinkPayPalConfirmOpen,
    onClose: onUnlinkPayPalConfirmClose,
  } = useDisclosure();

  const isSubmitLoading =
    isCreatingLeague || isUpdatingUserWithLeague || isUpdatingLeague;

  const { loading: isLoadingLeague, data } = useQuery(GET_LEAGUE, {
    variables: { leagueId },
    fetchPolicy: "no-cache",
    skip: isNewLeague,
  });

  const formFields = {
    name: data?.league?.name || "",
    paypalEmail: data?.league?.paypalEmail || "",
  };

  const paypalMerchantId = data?.league?.paypalMerchantId;
  const paypalEmail = data?.league?.paypalEmail;

  return (
    <>
      <HeaderBack onCloseRoute={ROUTES.ACCOUNT_SETTINGS} />
      <Container
        maxW="container.md"
        mt={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}
      >
        <Heading mb={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}>
          {isNewLeague ? "Create league" : "Edit league"}
        </Heading>

        <PageLoader isLoading={isLoadingLeague}>
          <Formik
            initialValues={formFields}
            validationSchema={Yup.object({
              name: Yup.string()
                .max(30, "Must be 30 characters or less")
                .required("What's your league name?"),
            })}
            onSubmit={(values) => {
              if (isNewLeague) {
                const variables = { ...values, userId };
                createLeague({ variables })
                  .then((resp) => {
                    const newLeagueId =
                      resp?.data?.createLeague?.league?.objectId;
                    // Set leagueId on authState to render in footer menu
                    setAuthState({
                      sessionToken: authState.sessionToken,
                      user: {
                        ...authState.user,
                        leagueId: newLeagueId,
                      },
                    });
                    return updateUserWithLeague({
                      variables: {
                        userId,
                        leagueId: newLeagueId,
                        leagueIdString: newLeagueId,
                      },
                    });
                  })
                  .then(() => {
                    toast({
                      title: "Your league was successfully created",
                      status: "success",
                      duration: DURATION.MEDIUM,
                      isClosable: true,
                    });
                    history.push(ROUTES.MY_LEAGUE);
                  })
                  .catch((e) => {
                    toast({
                      title: e.message,
                      status: "error",
                      duration: DURATION.LONG,
                      isClosable: true,
                    });
                  });
              } else {
                const variables = { ...values, leagueId };
                updateLeague({ variables })
                  .then((resp) => {
                    toast({
                      title: "Your league was successfully updated",
                      status: "success",
                      duration: DURATION.MEDIUM,
                      isClosable: true,
                    });
                    history.push(ROUTES.ACCOUNT_SETTINGS);
                  })
                  .catch((e) => {
                    toast({
                      title: e.message,
                      status: "error",
                      duration: DURATION.LONG,
                      isClosable: true,
                    });
                  });
              }
            }}
          >
            <Form>
              <Field name="name">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.name && form.touched.name}
                  >
                    <FormLabel htmlFor="name">
                      League name
                      <Text
                        as="i"
                        color="red.500"
                        fontWeight="normal"
                        fontSize="sm"
                        ml="2"
                      >
                        required
                      </Text>
                    </FormLabel>
                    <Input {...field} id="name" maxLength="30" />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Button
                mt={4}
                w="100%"
                colorScheme="teal"
                isLoading={isSubmitLoading}
                type="submit"
              >
                {isNewLeague ? "Create" : "Update"}
              </Button>
            </Form>
          </Formik>

          {!isNewLeague && (
            <>
              <Divider my="8" />

              {paypalMerchantId && (
                <>
                  <Text
                    fontSize="md"
                    fontWeight="semibold"
                    mb="2"
                    d="flex"
                    alignItems="center"
                  >
                    PayPal Merchant Id
                    {paypalMerchantId && (
                      <Icon
                        as={BiCheckCircle}
                        color="green"
                        w={6}
                        h={6}
                        ml="2"
                      />
                    )}
                  </Text>

                  <Input
                    mb="2"
                    disabled={true}
                    readOnly
                    value={paypalMerchantId}
                  ></Input>

                  <Text
                    fontSize="md"
                    fontWeight="semibold"
                    mb="2"
                    d="flex"
                    alignItems="center"
                  >
                    PayPal Email
                    {paypalEmail && (
                      <Icon
                        as={BiCheckCircle}
                        color="green"
                        w={6}
                        h={6}
                        ml="2"
                      />
                    )}
                  </Text>
                  <Input
                    mb="2"
                    disabled={true}
                    readOnly
                    value={paypalEmail}
                  ></Input>

                  <Button
                    w="100%"
                    mt="4"
                    variant="outline"
                    colorScheme="red"
                    isLoading={isUnlinkingPayPal}
                    onClick={onUnlinkPayPalConfirmOpen}
                  >
                    Unlink PayPal
                    <Icon as={FaPaypal} w={5} h={5} ml="2" />
                  </Button>
                </>
              )}

              {!paypalMerchantId && (
                <>
                  <Text fontSize="sm" opacity={0.8}>
                    For paid tournaments you must link your PayPal account.
                  </Text>

                  <Button
                    w="100%"
                    mt={4}
                    colorScheme="blue"
                    isLoading={isGettingPayPalSignUpLink}
                    onClick={async () => {
                      try {
                        const resp = await getPayPalSignUpLink({
                          variables: { leagueId },
                        });
                        const paypalOnboardingLink =
                          resp?.data?.GetPayPalSignUpLink;
                        openInNewTab(paypalOnboardingLink);
                      } catch (e) {
                        toast({
                          title: e.message,
                          status: "error",
                          duration: DURATION.LONG,
                          isClosable: true,
                        });
                      }
                    }}
                  >
                    Link PayPal
                    <Icon as={FaPaypal} w={5} h={5} ml="2" />
                  </Button>
                </>
              )}
            </>
          )}
        </PageLoader>
      </Container>

      <ConfirmModal
        headerText="Unlink PayPal Account"
        confirmButtonText="Unlink"
        isOpen={isUnlinkPayPalConfirmOpen}
        onClose={onUnlinkPayPalConfirmClose}
        onConfirmClick={async () => {
          // TODO: A call to PayPal Partner API
          // may need to be made here to revoke permissions.
          try {
            await unlinkPayPal({
              variables: {
                leagueId,
                paypalMerchantId: null,
                paypalEmail: null,
              },
              refetchQueries: [GET_LEAGUE],
            });
            toast({
              title: "Your PayPal account was successfully unlinked",
              status: "success",
              duration: DURATION.MEDIUM,
              isClosable: true,
            });
          } catch (e) {
            toast({
              title: "Sorry, there was a problem unlinking your PayPal account",
              status: "error",
              duration: DURATION.LONG,
              isClosable: true,
            });
          }
        }}
      >
        <Text mb="4">Are you sure you want to unlink this PayPal account?</Text>
        <Alert status="error">
          <AlertIcon />
          Users will not be able to register for any paid tournaments in this
          league.
        </Alert>
      </ConfirmModal>
    </>
  );
}

export default LeagueEditPage;
