import { Text, Button, Icon, useToast } from "@chakra-ui/react";
import { DURATION } from "constants/duration";
import { useAuthContext } from "context/AuthContext";
import { useMutation } from "@apollo/client";
import { useGoogleLogin } from "react-google-login";
import { CONTINUE_WITH_GOOGLE } from "graphql/mutations";
import { FcGoogle } from "react-icons/fc";
import { useState } from "react";

function GoogleLoginButton({ onSuccess, ...styleProps }) {
  const toast = useToast();
  const { setAuthState } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [continueWithGoogle] = useMutation(CONTINUE_WITH_GOOGLE);

  function showErrorToast(text) {
    setIsLoading(false);
    toast({
      title:
        text ||
        "Sorry, there was a problem logging in with Google. Please try again later.",
      status: "error",
      duration: DURATION.LONG,
      isClosable: true,
    });
  }

  const { signIn } = useGoogleLogin({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    cookiePolicy: "single_host_origin",
    isSignedIn: false,
    onSuccess: (googleLoginResp) => {
      const googleAccessToken = googleLoginResp?.accessToken;
      const googleTokenId = googleLoginResp?.tokenId;

      continueWithGoogle({ variables: { googleAccessToken, googleTokenId } })
        .then((resp) => {
          const { user, sessionToken } = resp?.data?.ContinueWithGoogle;

          setAuthState({
            sessionToken,
            user,
          });

          setIsLoading(false);
          onSuccess();
        })
        .catch((err) => {
          showErrorToast();
        });
    },
    onFailure: (err) => {
      if (err.error === "idpiframe_initialization_failed") {
        setIsButtonDisabled(true);
      } else {
        showErrorToast();
      }
    },
    onScriptLoadFailure: () => {
      setIsButtonDisabled(true);
    },
  });

  return (
    <>
      <Button
        {...styleProps}
        w="100%"
        backgroundColor="white"
        color="black"
        border="1px solid"
        borderColor="gray.200"
        isLoading={isLoading}
        isDisabled={isButtonDisabled}
        onClick={() => {
          setIsLoading(true);
          signIn();
        }}
      >
        <Icon as={FcGoogle} w={5} h={5} mr="2" />
        Continue with Google
      </Button>
      {isButtonDisabled && (
        <Text fontSize="sm">
          Can't continue with Google. Are you in Incognito Mode?
        </Text>
      )}
    </>
  );
}

export default GoogleLoginButton;
