import {
  Container,
  Heading,
  Text,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import HeaderBack from "components/HeaderBack";
import SignInForm from "components/SignInForm";
import { ROUTES } from "constants/routes";
import { getQueryParams } from "utils/formatters";

function SignInPage() {
  const history = useHistory();
  const linkColor = useColorModeValue("teal.500", "teal.200");
  const redirectUrl = getQueryParams().get("redir");

  return (
    <div data-testid="sign-in-page">
      <HeaderBack onCloseRoute={ROUTES.HOME} />
      <Container maxW="container.sm">
        <Heading as="h3" mt="5" mb="5">
          Sign in
        </Heading>
        <Text mb="3">
          Don't have an account?{" "}
          <Link fontWeight="bold" color={linkColor} href={ROUTES.SIGNUP}>
            Sign up
          </Link>
        </Text>
        <SignInForm
          onSignIn={() => {
            if (redirectUrl) {
              history.push(decodeURIComponent(redirectUrl));
            } else {
              history.push(ROUTES.ACCOUNT_SETTINGS);
            }
          }}
        />
      </Container>
    </div>
  );
}

export default SignInPage;
