import MatchCard from "components/MatchCard";
import { Icon, Button, Input, Grid, Text, Box } from "@chakra-ui/react";
import { getTeamPlayerName, isByeTeam } from "utils/formatters";
import { Fragment } from "react";
import { MATCH_NODE_PREFIX } from "utils/constants";
import { BiTrash } from "react-icons/bi";

function MatchInput({
  matchNode,
  onUpdateGame,
  onAddGame,
  onDeleteGame,
  isEditMode = false,
  gamesMap,
}) {
  const games = (matchNode?.gameIds || [])
    .map((gameId) => gamesMap[gameId])
    .filter((game) => !!game);

  const hasGames = !!games?.length;
  const isByeMatch =
    isByeTeam(games[0]?.teamOne) || isByeTeam(games[0]?.teamTwo);

  return (
    <MatchCard w="300px">
      <Box d="flex" justifyContent="center">
        <Text as="b">
          Match {matchNode?.id?.slice(MATCH_NODE_PREFIX.length)}
        </Text>
      </Box>

      {hasGames && (
        <Grid templateColumns="120px 30px 120px" textAlign="center">
          <Box>
            <Text isTruncated>
              {getTeamPlayerName(games?.[0]?.teamOne)?.[0]?.fullname}
            </Text>
            <Text isTruncated>
              {getTeamPlayerName(games?.[0]?.teamOne)?.[1]?.fullname}
            </Text>
          </Box>
          <Box d="flex" justifyContent="center" alignItems="center">
            <Text as="b">vs.</Text>
          </Box>
          <Box>
            <Text isTruncated>
              {getTeamPlayerName(games?.[0]?.teamTwo)?.[0]?.fullname}
            </Text>
            <Text isTruncated>
              {getTeamPlayerName(games?.[0]?.teamTwo)?.[1]?.fullname}
            </Text>
          </Box>

          {games.map((game, gameIndex) => {
            return (
              <Fragment key={game.objectId || gameIndex}>
                <Box mb="2">
                  {isEditMode && !isByeMatch && (
                    <Input
                      inputMode="numeric"
                      type="number"
                      value={game.teamOneScore ?? ""}
                      onChange={({ target: { value } }) => {
                        // keep SOT scores in gamesMap always as number for save to db
                        const trimmed = value.trim().slice(0, 2);
                        onUpdateGame({
                          ...game,
                          teamOneScore: trimmed === "" ? null : Number(trimmed),
                        });
                      }}
                      textAlign="center"
                      backgroundColor="white"
                    />
                  )}
                  {/* Add fixed height box matching input so that all MatchCard heights are the same */}
                  {isEditMode && isByeMatch && <Box h="40px"></Box>}
                  {!isEditMode && (
                    <Text as="b">
                      {Number.isInteger(game.teamOneScore)
                        ? game.teamOneScore
                        : "--"}
                    </Text>
                  )}
                </Box>
                <Box d="flex" alignContent="center" justifyContent="center">
                  {/* Don't show delete button for first game as there should be at least one game per match node. */}
                  {isEditMode && gameIndex !== 0 && (
                    <button
                      onClick={() => {
                        onDeleteGame(matchNode.id, game);
                      }}
                    >
                      <Icon
                        _hover={{ opacity: 1 }}
                        as={BiTrash}
                        opacity={0.3}
                        w={5}
                        h={5}
                      />
                    </button>
                  )}
                </Box>
                <Box>
                  {isEditMode && !isByeMatch && (
                    <Input
                      inputMode="numeric"
                      type="number"
                      value={game.teamTwoScore ?? ""}
                      onChange={({ target: { value } }) => {
                        // keep SOT scores in gamesMap always as number for save to db
                        const trimmed = value.trim().slice(0, 2);
                        onUpdateGame({
                          ...game,
                          teamTwoScore: trimmed === "" ? null : Number(trimmed),
                        });
                      }}
                      textAlign="center"
                      backgroundColor="white"
                    />
                  )}
                  {!isEditMode && (
                    <Text as="b">
                      {Number.isInteger(game.teamTwoScore)
                        ? game.teamTwoScore
                        : "--"}
                    </Text>
                  )}
                </Box>
              </Fragment>
            );
          })}
        </Grid>
      )}
      {isEditMode &&
        typeof onAddGame === "function" &&
        games?.length > 0 &&
        games?.length < 3 && (
          <Box d="flex" justifyContent="center">
            <Button
              variant="outline"
              onClick={() => {
                onAddGame(
                  matchNode.id,
                  games?.[0]?.teamOne?.objectId,
                  games?.[0]?.teamTwo?.objectId
                );
              }}
            >
              Add game
            </Button>
          </Box>
        )}
    </MatchCard>
  );
}

export default MatchInput;
