import BracketViewer from "components/BracketViewer";
import {
  Image,
  IconButton,
  Heading,
  Button,
  Badge,
  Text,
  Box,
  SkeletonText,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import ConfirmModal from "components/ConfirmModal";
import client from "graphql/client";
import {
  addTeamIdsToTree,
  deepClone,
  formatTeamStandings,
  formatEventName,
  generateRoundRobinPoolAndGamesMap,
  getDecoratedTeams,
  getTeamGroups,
  getIsEventStarted,
  getEventSpecNodeById,
  runOnEachNode,
  updateGamesAndEventSpec,
  getAllGameIdsFromEventSpec,
} from "utils/formatters";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_GAME,
  ADD_GAMES_TO_EVENT,
  UPDATE_EVENT,
  getUpdateGameMutation,
  DELETE_GAME,
  ADD_GAMES_AND_UDPATE_EVENT_SPEC,
} from "graphql/mutations";
import { useParams } from "react-router-dom";
import { GET_EVENT_SCHEDULE } from "graphql/queries";
import { useState } from "react";
import { DURATION } from "constants/duration";
import { MATCH_NODE_PREFIX } from "utils/constants";
import { EVENT_FORMAT_OPTIONS } from "utils/event";
import RoundRobinViewer from "components/RoundRobinViewer";
import { useAuthContext } from "context/AuthContext";
import TeamRankingTable from "components/TeamRankingTable";
import NotFoundPage from "pages/NotFoundPage";
import { BiArrowBack, BiRefresh } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import Pulse from "components/Pulse";
import { ROUTES } from "constants/routes";
import { getBracketTemplate } from "bracketTemplates/utils";
import { isMobileDevice } from "utils/browsers";
import ConditionalMobileHeader from "components/ConditionalMobileHeader";
import ConditionalMobileBody from "components/ConditionalMobileBody";

/**
 * Once this page is feature complete, it should replace the current EventSchedulePage.
 *
 * This page is responsible for:
 *   1.  Fetching the Event with event.teams and event.eventSpec.
 *   2a. If no eventSpec, fetch the appropriate template and generate one according to the event format (ie. DOUBLE_ELIMINATION, ROUND_ROBIN)
 *   2b. If eventSpec, read it, and render the entire page with one or more pools, round robins and elimination brackets.
 *   3.  If page is in edit mode, allow user to update and save game scores.
 */
function EventSchedulePage() {
  const { authState } = useAuthContext();
  const currentUserId = authState?.user?.objectId;
  const { eventId } = useParams();
  const toast = useToast();
  const history = useHistory();

  // STATE
  // Keeps track of any games that have been mutated in gamesMap for saving to backend.
  // Needs to be reset after each save.  ex. { <gameId>: true, ... }
  const [updatedGamesMap, setUpdatedGamesMap] = useState({});
  const [gamesMap, setGamesMap] = useState(null);
  const [teamsMap, setTeamsMap] = useState(null);
  const [gameToDeleteContext, setGameToDeleteContext] = useState();
  const [isStarting, setIsStarting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [localEventSpec, setLocalEventSpec] = useState(null);

  const [addGamesToEvent] = useMutation(ADD_GAMES_TO_EVENT);
  const [addGamesAndUpdateEventSpec] = useMutation(
    ADD_GAMES_AND_UDPATE_EVENT_SPEC
  );
  const [createGame] = useMutation(CREATE_GAME);
  const [deleteGame] = useMutation(DELETE_GAME);
  const [updateEvent] = useMutation(UPDATE_EVENT);

  const {
    isOpen: isStartEventConfirmModalOpen,
    onOpen: onStartEventConfirmModalOpen,
    onClose: onStartEventConfirmModalClose,
  } = useDisclosure();
  const {
    isOpen: isEndEventConfirmModalOpen,
    onOpen: onEndEventConfirmModalOpen,
    onClose: onEndEventConfirmModalClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteConfirmModalOpen,
    onOpen: onDeleteConfirmModalOpen,
    onClose: onDeleteConfirmModalClose,
  } = useDisclosure();

  const {
    loading: isEventLoading,
    error: isEventError,
    data: eventData,
    refetch,
  } = useQuery(GET_EVENT_SCHEDULE, {
    variables: { eventId },
    // "no-cache" is absolutely needed to always ensure page has latest data
    fetchPolicy: "no-cache",
  });

  // Return error or loading state first.
  if (isEventError) {
    return <NotFoundPage />;
  }

  if (isEventLoading || !eventData) {
    return (
      <Box mt="4">
        <SkeletonText p="4" noOfLines={4} spacing="4" />
        <SkeletonText p="4" noOfLines={4} spacing="4" />
      </Box>
    );
  }

  // Here we can assume event data is available.
  const event = eventData?.event;
  if (!localEventSpec) {
    setLocalEventSpec({ ...event.eventSpec });
  }

  if (!gamesMap) {
    const eventGames = (event?.games?.edges || []).map(({ node }) => node);

    if (event.eventSpec) {
      // Only populate gamesMap with gameIds that are actually found in eventSpec.
      // There was a case where 2x the games were created from 2 people starting the event.
      // TODO: Prevent this from happening on server side.
      // Some of the duplicate games had score data which threw off calculation in formatTeamStandings() for TeamRankingsTable.
      const gameIdsInEventSpec = getAllGameIdsFromEventSpec(event.eventSpec);

      setGamesMap(
        eventGames.reduce((map, game) => {
          if (gameIdsInEventSpec[game.objectId]) {
            map[game.objectId] = game;
          }
          return map;
        }, {})
      );
    } else {
      setGamesMap(
        eventGames.reduce((map, game) => {
          map[game.objectId] = game;
          return map;
        }, {})
      );
    }
  }

  if (!teamsMap) {
    setTeamsMap(
      (event?.teams?.edges || [])
        .map(({ node }) => node)
        .reduce((map, team) => {
          map[team.objectId] = team;
          return map;
        }, {})
    );
  }

  const tournament = event?.tournament;
  const leagueCreatedById = tournament?.league?.createdBy?.objectId;
  const isCurrentUserAdmin = currentUserId === leagueCreatedById;
  const formattedEventName = formatEventName(event);
  const isMobile = isMobileDevice();
  const isEventStarted = getIsEventStarted(event);

  // Get all registered, non-waitlisted teams.
  const { teamsFullyRegistered } = getTeamGroups(event, tournament);
  const nonWaitlistedTeams = getDecoratedTeams(
    teamsFullyRegistered,
    event
  )?.filter(({ isWaitlisted }) => !isWaitlisted);
  const hasEnoughTeamsForSchedule = nonWaitlistedTeams.length >= 3;
  const hasSavedGames = !!event?.games?.edges?.length;

  if (!event?.eventSpec && !localEventSpec) {
    // Event has not started yet, generate a FE eventSpec depending on format.

    switch (event.eventFormat) {
      case EVENT_FORMAT_OPTIONS.DOUBLE_ELIMINATION:
        const bracketTemplate = getBracketTemplate(
          event?.eventFormat,
          nonWaitlistedTeams.length
        );

        const templateWithTeamIds = addTeamIdsToTree(
          bracketTemplate,
          nonWaitlistedTeams
        );

        setLocalEventSpec({
          pools: [
            {
              id: "pool:DOUBLE_ELIMINATION",
              children: [templateWithTeamIds],
            },
          ],
          finals: [],
        });

        break;
      case EVENT_FORMAT_OPTIONS.ROUND_ROBIN_TEAM:
        const { pool, newGames } =
          generateRoundRobinPoolAndGamesMap(nonWaitlistedTeams);

        setGamesMap(
          newGames.reduce((map, game) => {
            map[game.objectId] = game;
            return map;
          }, {})
        );

        setLocalEventSpec({
          pools: [pool],
          finals: [],
        });
        break;
      default:
    }
  }

  function onUpdateGame(newGame) {
    const gameId = newGame.objectId;

    setUpdatedGamesMap({
      ...updatedGamesMap,
      [gameId]: true,
    });

    setGamesMap({
      ...gamesMap,
      [gameId]: newGame,
    });
  }

  return (
    <Box>
      <ConditionalMobileHeader>
        <Box d="flex" alignItems="center">
          <IconButton
            mr="2"
            aria-label="Back"
            variant="solid"
            icon={<BiArrowBack />}
            onClick={() => {
              if (history.action !== "POP") {
                history.goBack();
              } else {
                if (isCurrentUserAdmin) {
                  history.push(ROUTES.MY_LEAGUE);
                } else {
                  history.push(`/tournaments/${tournament?.objectId}/register`);
                }
              }
            }}
          />
          <IconButton
            mr="2"
            aria-label="Back"
            variant="solid"
            icon={<BiRefresh />}
            onClick={() => {
              window.location.reload();
            }}
          />

          {!isMobile && (
            <Heading
              as="h1"
              size="md"
              d={[
                isMobile ? "none" : "inline-block",
                isMobile ? "none" : "inline-block",
                "inline-block",
              ]}
            >
              {formattedEventName}
            </Heading>
          )}

          {isEventStarted && isMobile && (
            <Pulse pos="fixed" top="0" left="0" zIndex="999">
              <Badge colorScheme="green" fontSize="lg" ml="3" mt="3">
                LIVE
              </Badge>
            </Pulse>
          )}
          {isEventStarted && !isMobile && (
            <Pulse d="inline">
              <Badge colorScheme="green" fontSize="lg" ml="3">
                LIVE
              </Badge>
            </Pulse>
          )}
        </Box>

        {isCurrentUserAdmin && hasEnoughTeamsForSchedule && (
          <Box>
            {isEventStarted && (
              <Button
                isLoading={isSaving}
                colorScheme="teal"
                minW="100px"
                mr="3"
                onClick={() => {
                  setIsSaving(true);

                  // Update any games with teamIds from latest eventSpec
                  const { newEventSpec, newUpdatedGamesMap, newGamesMap } =
                    updateGamesAndEventSpec(
                      localEventSpec,
                      updatedGamesMap,
                      gamesMap,
                      teamsMap
                    );

                  setLocalEventSpec(newEventSpec);
                  setGamesMap(newGamesMap);
                  setUpdatedGamesMap(newUpdatedGamesMap);

                  updateEvent({
                    variables: {
                      eventId,
                      eventSpec: newEventSpec,
                    },
                  })
                    .then(() => {
                      const updateGamePromises = (
                        Object.keys(newUpdatedGamesMap) || []
                      ).map((gameId) => {
                        const game = newGamesMap[gameId];

                        if (!game) {
                          return Promise.resolve();
                        }

                        const { teamOneScore, teamTwoScore, teamOne, teamTwo } =
                          game;

                        const mutation = getUpdateGameMutation({
                          gameId,
                          teamOneScore,
                          teamTwoScore,
                          // if a team is null, pass null as teamId so that
                          // the game.teamOne pointer will be set to null on BE.
                          teamOneId:
                            teamOne === null ? null : teamOne?.objectId,
                          teamTwoId:
                            teamTwo === null ? null : teamTwo?.objectId,
                        });
                        return client.mutate({ mutation });
                      });

                      return Promise.all(updateGamePromises);
                    })
                    .then(() => {
                      setIsSaving(false);
                      setUpdatedGamesMap({});
                      toast({
                        position: isMobile ? "top" : "bottom",
                        title: "Game scores saved!",
                        status: "success",
                        duration: DURATION.SHORT,
                        isClosable: true,
                      });
                    })
                    .catch((err) => {
                      setIsSaving(false);
                      toast({
                        title: `Error saving. Please wait a few seconds then try again. ${err.message}`,
                        status: "error",
                        duration: DURATION.MEDIUM,
                        isClosable: true,
                      });
                    });
                }}
              >
                Save
              </Button>
            )}
            {!isEventStarted && (
              <Button
                isLoading={isStarting}
                colorScheme="green"
                onClick={onStartEventConfirmModalOpen}
              >
                Start
              </Button>
            )}
            {isEventStarted && (
              <Button
                ml="3"
                variant="link"
                colorScheme="red"
                onClick={() => {
                  onEndEventConfirmModalOpen();
                }}
              >
                End
              </Button>
            )}
          </Box>
        )}
      </ConditionalMobileHeader>

      {hasEnoughTeamsForSchedule && (
        <ConditionalMobileBody>
          {localEventSpec?.pools?.map((pool, i) => {
            return (
              <Box key={pool.id}>
                {pool.id === "pool:DOUBLE_ELIMINATION" && (
                  <BracketViewer
                    isEditMode={isCurrentUserAdmin && isEventStarted}
                    gamesMap={gamesMap}
                    teamsMap={teamsMap}
                    rootNode={pool.children?.[0]}
                    onDeleteGame={(matchNodeId, game) => {
                      setGameToDeleteContext({
                        matchNodeId,
                        game,
                      });
                      onDeleteConfirmModalOpen();
                    }}
                    onUpdateGame={onUpdateGame}
                    onAddGame={(
                      matchNodeId,
                      teamOneId = null,
                      teamTwoId = null
                    ) => {
                      const newEventSpec = deepClone(localEventSpec);
                      const matchNode = getEventSpecNodeById(
                        newEventSpec,
                        matchNodeId
                      );

                      createGame({
                        variables: {
                          eventId,
                          teamOneId,
                          teamTwoId,
                        },
                      }).then((resp) => {
                        const newGame = resp?.data?.createGame?.game;
                        const newGameId = newGame.objectId;

                        setGamesMap({
                          [newGameId]: newGame,
                          ...gamesMap,
                        });

                        if (matchNode?.gameIds?.length) {
                          matchNode.gameIds.push(newGameId);
                        } else {
                          matchNode.gameIds = [newGameId];
                        }

                        setLocalEventSpec(newEventSpec);

                        return addGamesAndUpdateEventSpec({
                          variables: {
                            eventId,
                            gameIds: [newGameId],
                            eventSpec: newEventSpec,
                          },
                        });
                      });
                    }}
                  />
                )}
                {pool.id === "pool:ROUND_ROBIN" && (
                  <>
                    <TeamRankingTable
                      teams={formatTeamStandings(Object.values(gamesMap))}
                    />
                    <RoundRobinViewer
                      isEditMode={isCurrentUserAdmin && isEventStarted}
                      gamesMap={gamesMap}
                      teamsMap={teamsMap}
                      rootNode={pool}
                      onUpdateGame={onUpdateGame}
                    />
                  </>
                )}
              </Box>
            );
          })}
        </ConditionalMobileBody>
      )}

      {!hasEnoughTeamsForSchedule && (
        <Box
          d="flex"
          p="3"
          minH="100vh"
          justifyContent="center"
          alignItems="center"
          flexDir="column"
        >
          <Heading as="h1" opacity="50%" textAlign="center">
            Come back after more teams register
          </Heading>
          <Image
            mt="5"
            w={["300px", "400px", "500px"]}
            src="/undraw_lost_re_xqjt.svg"
            alt="come back later"
          />
        </Box>
      )}
      <ConfirmModal
        headerText="Start event"
        confirmButtonText="Start"
        confirmButtonColorScheme="green"
        confirmButtonVariant="solid"
        isOpen={isStartEventConfirmModalOpen}
        onClose={onStartEventConfirmModalClose}
        onConfirmClick={() => {
          setIsStarting(true);

          // Don't create new games if already started.
          if (hasSavedGames) {
            updateEvent({
              variables: {
                eventId,
                eventActualStartTime: new Date(),
              },
            }).then(() => {
              refetch();
              setIsStarting(false);
            });
            return;
          }

          // 1) Recurse through bracket tree and and create 1 game per match node.
          // 2) Append the newly created game ids to the appropriate match nodes in eventSpec.
          const newEventSpec = deepClone(localEventSpec);
          const matchNodes = [];

          // const newEventSpec = JSON.parse(JSON.stringify(localEventSpec));

          runOnEachNode(newEventSpec, (node) => {
            if (node?.id?.startsWith(MATCH_NODE_PREFIX)) {
              matchNodes.push(node);
            }
          });

          const createGamePromises = matchNodes.map((matchNode) => {
            // For Round Robin format, game object are already populated bc we know all matches ahead of time.
            const newGame = gamesMap[matchNode?.gameIds?.[0]];

            // Attempt to populate from child team nodes if present
            const [teamOneId = null, teamTwoId = null] = (
              matchNode?.children || []
            ).map(({ teamId }) => teamId);

            return createGame({
              variables: {
                eventId,
                teamOneId: newGame?.teamOne?.objectId || teamOneId,
                teamTwoId: newGame?.teamTwo?.objectId || teamTwoId,
              },
            });
          });

          Promise.all(createGamePromises)
            .then((resps = []) => {
              const newGameIds = resps?.map(
                ({ data }) => data?.createGame?.game?.objectId
              );

              matchNodes.forEach((matchNode, i) => {
                const newGameId = newGameIds[i];
                if (newGameId) {
                  matchNode.gameIds = [newGameId];
                }
              });

              return addGamesToEvent({
                variables: {
                  eventId,
                  gameIds: newGameIds,
                },
              });
            })
            .then(() => {
              return updateEvent({
                variables: {
                  eventId,
                  eventSpec: newEventSpec,
                  eventActualStartTime: new Date(),
                },
              });
            })
            .then(() => {
              setIsStarting(false);
              // TODO: Find way to start event w/o this page reload?
              window.location.reload();
            })
            .catch((err) => {
              setIsStarting(false);
              toast({
                title: `Error starting: ${err.message}`,
                status: "error",
                duration: DURATION.MEDIUM,
                isClosable: true,
              });
            });
        }}
      >
        <Text>Are you sure you want to start this event?</Text>

        <Text mt="4">
          We recommend you start the event as close to the actual start time as
          possible because once the event is started, the schedule,
          participating teams and players can't be changed.
        </Text>
      </ConfirmModal>

      <ConfirmModal
        headerText="End event"
        confirmButtonText="End"
        confirmButtonColorScheme="red"
        confirmButtonVariant="solid"
        isOpen={isEndEventConfirmModalOpen}
        onClose={onEndEventConfirmModalClose}
        onConfirmClick={() => {
          const eventActualEndTime = new Date();
          const variables = { eventId, eventActualEndTime };
          updateEvent({ variables })
            .then(() => {
              refetch();
            })
            .catch((err) => {
              toast({
                title: "Could not end event. Please try again later.",
                status: "error",
                duration: DURATION.LONG,
                isClosable: true,
              });
            });
        }}
      >
        <Text>Are you sure you want to end this event?</Text>
      </ConfirmModal>

      <ConfirmModal
        headerText="Delete game"
        bodyText="Are you sure you want to delete this game?"
        confirmButtonText="Delete"
        isOpen={isDeleteConfirmModalOpen}
        onClose={onDeleteConfirmModalClose}
        onConfirmClick={() => {
          const gameId = gameToDeleteContext?.game?.objectId;
          const matchNodeId = gameToDeleteContext?.matchNodeId;

          if (gameId && matchNodeId) {
            // Remove gameId from eventSpec and save.
            const newEventSpec = deepClone(localEventSpec);
            const matchNode = getEventSpecNodeById(newEventSpec, matchNodeId);
            matchNode.gameIds = matchNode.gameIds.filter((id) => id !== gameId);
            setLocalEventSpec(newEventSpec);

            deleteGame({
              variables: { gameId },
            })
              .then(() => {
                updateEvent({
                  variables: {
                    eventId,
                    eventSpec: newEventSpec,
                  },
                });
              })
              .then(() => {
                setGameToDeleteContext({});
              });
          }
        }}
      />
    </Box>
  );
}

export default EventSchedulePage;
