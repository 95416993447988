import { Heading, Text, Box, IconButton } from "@chakra-ui/react";
import { getBracketTemplate } from "bracketTemplates/utils";
import { EVENT_FORMAT_OPTIONS, EVENT_FORMAT_OPTION_NAMES } from "utils/event";
import BracketViewer from "components/BracketViewer";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import { useKeyPress } from "utils/hooks";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

const NUM_TEAMS_MIN = 3;
const NUM_TEAMS_MAX = 12;

const bracketTypesMap = {
  DE: EVENT_FORMAT_OPTIONS.DOUBLE_ELIMINATION,
};

function BracketPreviewPage() {
  const history = useHistory();
  const { bracketType, numTeams } = useParams();
  const format = bracketTypesMap[bracketType?.toUpperCase()];
  const template = getBracketTemplate(format, numTeams);
  const hasTemplate = !!template?.children?.length;

  const decrementTeam = () => {
    if (numTeams > NUM_TEAMS_MIN) {
      history.push(`/bracket-preview/${bracketType}/${Number(numTeams) - 1}`);
    }
  };

  const incrementTeam = () => {
    if (numTeams < NUM_TEAMS_MAX) {
      history.push(`/bracket-preview/${bracketType}/${Number(numTeams) + 1}`);
    }
  };

  useKeyPress(["ArrowLeft", "ArrowRight"], (event) => {
    switch (event.key) {
      case "ArrowLeft":
        decrementTeam();
        break;
      case "ArrowRight":
        incrementTeam();
        break;
      default:
        return null;
    }
  });

  return (
    <Box>
      <Box
        p="3"
        w="100%"
        background="white"
        zIndex="999"
        d="flex"
        alignItems="center"
        position="fixed"
        borderBottom="1px solid"
        borderColor="gray.200"
      >
        <Text d="inline">
          Format: <b>{EVENT_FORMAT_OPTION_NAMES[format]}</b>
        </Text>
        <Text d="inline" ml="3">
          Number of teams: <b>{numTeams}</b>
        </Text>
        <IconButton
          ml="4"
          disabled={numTeams === NUM_TEAMS_MIN}
          icon={<BiLeftArrowAlt />}
          onClick={decrementTeam}
        />
        <IconButton
          ml="4"
          disabled={numTeams === NUM_TEAMS_MAX}
          icon={<BiRightArrowAlt />}
          onClick={incrementTeam}
        />
      </Box>
      <Box d="flex" mt="20" p="3">
        {hasTemplate && <BracketViewer rootNode={template} />}
        {!hasTemplate && (
          <Heading
            fontSize={["2em", "2em", "4em"]}
            opacity="50%"
            w="100%"
            mt="20"
            textAlign="center"
          >
            Template not found
          </Heading>
        )}
      </Box>
    </Box>
  );
}

export default BracketPreviewPage;
