import { Button, Icon, Box, useColorModeValue } from "@chakra-ui/react";
import { ROUTES } from "constants/routes";
import { useHistory } from "react-router-dom";

import { BiUserCircle } from "react-icons/bi";

function PublicMobileBottomNav() {
  const history = useHistory();
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const bgColor = useColorModeValue("white", "gray.900");

  return (
    <Box
      w="100%"
      d="flex"
      position="fixed"
      bottom="0"
      bg={bgColor}
      borderTop="1.5px solid"
      borderColor={borderColor}
      alignItems="center"
      justifyContent="center"
    >
      <Button
        onClick={() => history.push(ROUTES.SIGNUP)}
        aria-label="Sign up"
        data-testid="sign-up-btn"
        variant="ghost"
        h="60px"
        d="flex"
        ml="5"
        borderRadius={0}
        flexDir="column"
        _hover={{
          bg: "none",
        }}
      >
        <Icon as={BiUserCircle} w={6} h={6} />
        Sign up
      </Button>
      <Button
        onClick={() => history.push(ROUTES.SIGNIN)}
        aria-label="Sign in"
        data-testid="sign-in-btn"
        variant="ghost"
        h="60px"
        d="flex"
        ml="5"
        borderRadius={0}
        flexDir="column"
        _hover={{
          bg: "none",
        }}
      >
        <Icon as={BiUserCircle} w={6} h={6} />
        Sign in
      </Button>
    </Box>
  );
}

export default PublicMobileBottomNav;
