import {
  Box,
  Heading,
  Text,
  Container,
  Button,
  Link,
  HStack,
} from "@chakra-ui/react";
import UpcomingTournaments from "components/UpcomingTournaments";
import PastTournaments from "components/PastTournaments";
import { ROUTES } from "constants/routes";
import { useAuthContext } from "context/AuthContext";
import { useHistory } from "react-router-dom";

function HomePage() {
  const { isAuthenticated } = useAuthContext();
  const history = useHistory();
  const isLoggedIn = isAuthenticated();

  return (
    <>
      <Container maxW="container.lg">
        <Box mt="40" mb="10">
          <Heading
            as="h1"
            fontSize={["4em", "6em", "8em", "10em"]}
            ml={["-3px", "-6px", "-10px"]}
            userSelect="none"
            data-testid="homepage__header"
          >
            Dink
            <Text as="span" color="teal">
              Pal
            </Text>
          </Heading>
          <Heading as="h2" fontSize={["1.5em", "1.5em", "2em", "2em"]}>
            Pickleball tournaments made easy.
          </Heading>
          {!isLoggedIn && (
            <Button
              mt="10"
              w={["100%", "100%", "50%", "33%"]}
              size="lg"
              colorScheme="teal"
              onClick={() => {
                history.push(ROUTES.SIGNUP);
              }}
            >
              Sign up
            </Button>
          )}
        </Box>
      </Container>
      <Box
        left={0}
        top={0}
        w="100%"
        h="200px"
        // backgroundColor="gray.100"
        backgroundImage="/pattern-color.svg"
        backgroundSize="400px"
      ></Box>
      <Container mt="10" maxW="container.lg">
        <Box mt="10">
          <UpcomingTournaments />
        </Box>

        <Box mt="20">
          <PastTournaments />
        </Box>
      </Container>

      <Container maxW="container.lg" d="flex" justifyContent="center">
        <HStack spacing={6}>
          <Link my="4" href={ROUTES.TERMS_OF_USE}>
            Terms of use
          </Link>
          <Link my="4" href={ROUTES.PRIVACY_POLICY}>
            Privacy policy
          </Link>
          <Link my="4" href={ROUTES.CONTACT_US}>
            Contact us
          </Link>
        </HStack>
      </Container>
    </>
  );
}

export default HomePage;
