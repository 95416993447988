import { IconButton, Container, Text } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import { ROUTES } from "constants/routes";

function HeaderBack({ onCloseRoute }) {
  const history = useHistory();

  return (
    <Container
      maxW="container.xl"
      d="flex"
      alignItems="center"
      justifyContent="space-between"
      mt="1rem"
    >
      <Text fontSize="lg" fontWeight="bold">
        Dink
        <Text as="span" color="teal">
          Pal
        </Text>
      </Text>
      <IconButton
        aria-label="Back to home"
        variant="ghost"
        icon={<CloseIcon />}
        onClick={() => {
          if (history.action !== "POP") {
            history.goBack();
          } else if (onCloseRoute) {
            history.push(onCloseRoute);
          } else {
            history.push(ROUTES.HOME);
          }
        }}
      />
    </Container>
  );
}

export default HeaderBack;
