import { HStack } from "@chakra-ui/react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import CopyUrlButton from "components/CopyUrlButton";

function SocialShareButtons({ ...props }) {
  const currentUrl = window.location.href;

  return (
    <HStack spacing="3" {...props}>
      <FacebookShareButton
        url={currentUrl}
        quote="Check out this pickleball tournament on DinkPal"
        hashtag="DinkPal"
      >
        <FacebookIcon size={36} round={true} />
      </FacebookShareButton>
      <TwitterShareButton url={currentUrl}>
        <TwitterIcon size={36} round={true} />
      </TwitterShareButton>
      <WhatsappShareButton url={currentUrl}>
        <WhatsappIcon size={36} round={true} />
      </WhatsappShareButton>
      <EmailShareButton url={currentUrl}>
        <EmailIcon size={36} round={true} />
      </EmailShareButton>
      <CopyUrlButton />
    </HStack>
  );
}

export default SocialShareButtons;
