import {
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  Button,
} from "@chakra-ui/react";
import { useState } from "react";
import { BiShow, BiHide } from "react-icons/bi";

function PasswordInput({ ...props }) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <InputGroup>
      <Input type={isPasswordVisible ? "text" : "password"} {...props} />
      <InputRightAddon
        p="0"
        overflow="hidden"
        children={
          <Button
            w="100%"
            variant="solid"
            borderRadius="0"
            onClick={() => {
              setIsPasswordVisible(!isPasswordVisible);
            }}
          >
            <Icon w={5} h={5} as={isPasswordVisible ? BiHide : BiShow} />
          </Button>
        }
      />
    </InputGroup>
  );
}

export default PasswordInput;
