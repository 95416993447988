import { Box } from "@chakra-ui/react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { isMobileDevice } from "utils/browsers";

function ConditionalMobileBody({ children }) {
  const isMobile = isMobileDevice();

  return isMobile ? (
    <TransformWrapper
      limitToBounds={true}
      initialScale={0.5}
      // allows input to focus on touch (https://github.com/prc5/react-zoom-pan-pinch/issues/246)
      panning={{ excluded: ["input"] }}
      doubleClick={{ disabled: true }}
      wheel={{ disabled: true }}
      minScale={0.25}
    >
      <TransformComponent
        wrapperStyle={{
          width: "100vw",
          height: "100vh",
        }}
      >
        {children}
      </TransformComponent>
    </TransformWrapper>
  ) : (
    <Box mt="64px">{children}</Box>
  );
}

export default ConditionalMobileBody;
