import { UAParser } from "ua-parser-js";

/**
 * Webview detection
 * Credit to https://github.com/atomantic/is-ua-webview
 */
const rules = [
  // if it says it's a webview, let's go with that
  "WebView",
  // iOS webview will be the same as safari but missing "Safari"
  "(iPhone|iPod|iPad)(?!.*Safari)",
  // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
  // Android KitKat to lollipop webview will put {version}.0.0.0
  "Android.*(wv|.0.0.0)",
  // old chrome android webview agent
  "Linux; U; Android",
];

const webviewRegExp = new RegExp("(" + rules.join("|") + ")", "ig");

/**
 * Is current browser a webview?
 * Needed to know if app is running in a webview as Facebook login no longer work there :(
 * https://developers.facebook.com/docs/facebook-login/android/deprecating-webviews
 */
export function isWebview() {
  return !!window?.navigator?.userAgent?.match(webviewRegExp);
}

/**
 * Facebook login has slightly different login logic in mobile safari so need a check for it.
 */
export function isMobileSafari() {
  return (
    UAParser(window?.navigator?.userAgent)?.browser?.name === "Mobile Safari"
  );
}

/**
 * If page a loaded in mobile device.
 */
export function isMobileDevice() {
  return UAParser(window?.navigator?.userAgent)?.device?.type === "mobile";
}

export function getViewPortDimensions() {
  return {
    w: Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    ),
    h: Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    ),
  };
}
