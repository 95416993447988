import { useRadio, Box } from "@chakra-ui/react";

function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const isDisabled = props.disabled;

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} disabled={isDisabled} />
      <Box
        {...checkbox}
        d="flex"
        h="100%"
        flexDirection="column"
        alignItems="center"
        borderWidth="1px"
        borderRadius="md"
        opacity={isDisabled ? 0.5 : 1}
        cursor={isDisabled ? "not-allowed" : "pointer"}
        _checked={{
          bg: "teal.600",
          color: "white",
          borderColor: "teal.600",
        }}
        _hover={
          isDisabled
            ? {}
            : {
                bg: "teal.500",
                color: "white",
                borderColor: "teal.600",
              }
        }
        px={3}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default RadioCard;
