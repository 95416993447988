import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

/**
 * Used anywhere we need to prompt confirmation before deleting an 'item' ie. Tournament, Event, etc.
 * @param {string} headerText - Text for the header
 * @param {string} bodyText - Text for the body
 * @param {boolean} isOpen - If modal is open
 * @param {Function} onClose - Called when modal is closed
 * @param {Function} onConfirmClick - Called when confirm button is clicked
 * @param {string} confirmButtonText - Text for confirm button on right. Defaults to "Yes".
 * @param {string} confirmButtonColorScheme - Chakra colorScheme for confirm button on right. Defaults to "red".
 */
function ConfirmModal({
  headerText,
  bodyText,
  isOpen,
  onClose,
  onConfirmClick,
  confirmButtonText,
  confirmButtonColorScheme,
  confirmButtonVariant,
  children,
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} w="100%">
      <ModalOverlay w="100%" h="100%" />
      <ModalContent>
        <ModalHeader>{headerText}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{bodyText ?? children}</ModalBody>

        <ModalFooter d="flex" justifyContent="space-between">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme={confirmButtonColorScheme || "red"}
            variant={confirmButtonVariant || "ghost"}
            onClick={() => {
              onConfirmClick();
              // TODO: Find a pattern to show Confirm button loading state while async is happening
              // Problem: When onClose() is called exterally it throws a "Can't perform a React state update on an unmounted component" error. Find a better pattern.
              onClose();
            }}
          >
            {confirmButtonText || "Yes"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ConfirmModal;
