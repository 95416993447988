import { Switch, Route } from "react-router-dom";
import { ROUTES } from "constants/routes";
import AppShell from "components/AppShell";
import AuthenticatedRoute from "components/AuthenticatedRoute";
import HomePage from "pages/HomePage";
import SignUpPage from "pages/SignUpPage";
import SignInPage from "pages/SignInPage";
import TournamentRegisterPage from "pages/TournamentRegisterPage";
import AccountSettingsPage from "pages/AccountSettingsPage";
import MyEventsPage from "pages/MyEventsPage";
import LeagueEditPage from "pages/LeagueEditPage";
import MyLeaguePage from "pages/MyLeaguePage";
import TournamentEditPage from "pages/TournamentEditPage";
import EventEditPage from "pages/EventEditPage";
import TeamEventInvitePage from "pages/TeamEventInvitePage";
import ProfileEditPage from "pages/ProfileEditPage";
import PrivacyPolicyPage from "pages/PrivacyPolicyPage";
import TermsOfUsePage from "pages/TermsOfUsePage";
import ContactUsPage from "pages/ContactUsPage";
import EventSchedulePage from "pages/EventSchedulePage";
import BracketPreviewPage from "pages/BracketPreviewPage";
import TournamentAdminPage from "pages/TournamentAdminPage";
import PayPalOnboardingCompletePage from "pages/PayPalOnboardingCompletePage";

function App() {
  return (
    <AppShell>
      <Switch>
        <Route exact path={ROUTES.HOME}>
          <HomePage />
        </Route>
        <Route exact path={ROUTES.SIGNUP}>
          <SignUpPage />
        </Route>
        <Route exact path={ROUTES.SIGNIN}>
          <SignInPage />
        </Route>
        <Route exact path={ROUTES.PRIVACY_POLICY}>
          <PrivacyPolicyPage />
        </Route>
        <Route exact path={ROUTES.TERMS_OF_USE}>
          <TermsOfUsePage />
        </Route>
        <Route exact path={ROUTES.REGISTER_FOR_TOURNAMENT}>
          <TournamentRegisterPage />
        </Route>
        <Route exact path={ROUTES.EVENT_SCHEDULE}>
          <EventSchedulePage />
        </Route>
        <Route exact path={ROUTES.TEAM_INVITE}>
          <TeamEventInvitePage />
        </Route>
        <Route exact path={ROUTES.CONTACT_US}>
          <ContactUsPage />
        </Route>
        <Route exact path={ROUTES.BRACKET_PREVIEW}>
          <BracketPreviewPage />
        </Route>

        <AuthenticatedRoute exact path={ROUTES.EDIT_PROFILE}>
          <ProfileEditPage />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path={ROUTES.ACCOUNT_SETTINGS}>
          <AccountSettingsPage />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path={ROUTES.MY_EVENTS}>
          <MyEventsPage />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path={ROUTES.EDIT_EVENT}>
          <EventEditPage />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path={ROUTES.EDIT_LEAGUE}>
          <LeagueEditPage />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path={ROUTES.EDIT_TOURNAMENT}>
          <TournamentEditPage />
        </AuthenticatedRoute>

        <AuthenticatedRoute
          exact
          path={ROUTES.LEAGUE_PAYPAL_ONBOARDING_COMPLETE}
        >
          <PayPalOnboardingCompletePage />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path={ROUTES.ADMIN_TOURNAMENT}>
          <TournamentAdminPage />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path={ROUTES.MY_LEAGUE}>
          <MyLeaguePage />
        </AuthenticatedRoute>
      </Switch>
    </AppShell>
  );
}

export default App;
