import {
  Text,
  Button,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import { DURATION } from "constants/duration";
import { ADD_NEW_TEAM_TO_EVENT } from "graphql/mutations";
import { useAuthContext } from "context/AuthContext";
import {
  getIsUserInEvent,
  getIsEventFull,
  formatPrice,
  formatEventName,
  isPartnerNeededToRegister,
  getPlayerFromEvent,
  getHasUserAlreadyPaidForAnotherEvent,
  isTournamentFree,
} from "utils/formatters";

function NewRegistrationModalContent({
  tournament,
  event,
  refetchQueries,
  onClose,
  onComplete,
}) {
  const toast = useToast();
  const { authState } = useAuthContext();
  const userId = authState?.user?.objectId;
  const isUserInEvent = getIsUserInEvent(userId, event);
  const isEventFull = getIsEventFull(event, tournament);
  const isFree = isTournamentFree(tournament);
  const hasUserAlreadyPaidForAnotherEvent =
    getHasUserAlreadyPaidForAnotherEvent(userId, tournament);
  const price =
    Number.isInteger(tournament?.priceAfterFirstEvent) &&
    hasUserAlreadyPaidForAnotherEvent
      ? tournament?.priceAfterFirstEvent
      : tournament?.pricePerPerson;
  const priceText = formatPrice(price);
  const isPartnerNeeded = isPartnerNeededToRegister(event);
  const partnerText = isPartnerNeeded
    ? "requires a partner"
    : "does not require a partner";

  const [addNewTeamToEvent, { loading: isAddingNewTeamToEvent }] = useMutation(
    ADD_NEW_TEAM_TO_EVENT,
    {
      refetchQueries,
      variables: {
        eventId: event?.objectId,
        userId,
      },
    }
  );

  return (
    <ModalContent>
      <ModalHeader>{formatEventName(event)}</ModalHeader>
      <ModalCloseButton />
      <ModalBody minH="200">
        {isEventFull ? (
          <Text fontWeight="bold" mb="6">
            Join waitlist
          </Text>
        ) : (
          <Text fontWeight="bold" mb="6">
            Let's get you registered!
          </Text>
        )}

        {isUserInEvent ? (
          <Text>Looks like you're already signed up for this event.</Text>
        ) : (
          <Text>{`This event is ${priceText} and ${partnerText}.`}</Text>
        )}

        {isEventFull && (
          <>
            <Text mt="4">
              Although this event is full, you can be still be added to the
              waitlist by{" "}
              {isFree ? (
                <span>registering</span>
              ) : (
                <span>paying the registration fee</span>
              )}
              {isPartnerNeeded && (
                <span>
                  {" "}
                  <b>and</b> finding a partner
                </span>
              )}
              . If any team drops out and your team is next in line, you'll
              automatically be taken off the waitlist.
            </Text>
            <Text mt="4">
              Please refer to "My Events" for the latest on your waitlist
              status.
            </Text>
          </>
        )}
      </ModalBody>
      <ModalFooter d="flex" justifyContent="space-between">
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        {!isUserInEvent && (
          <Button
            colorScheme="teal"
            variant="solid"
            isLoading={isAddingNewTeamToEvent}
            type="submit"
            onClick={() => {
              addNewTeamToEvent()
                .then((resp) => {
                  const event = resp?.data?.updateEvent?.event;
                  const newPlayer = getPlayerFromEvent(userId, event);
                  onComplete(newPlayer);
                })
                .catch((e) => {
                  toast({
                    title:
                      "Sorry, there was a problem registering you for the event. Please try again later.",
                    status: "error",
                    duration: DURATION.LONG,
                    isClosable: true,
                  });
                });
            }}
          >
            Continue
          </Button>
        )}
      </ModalFooter>
    </ModalContent>
  );
}

export default NewRegistrationModalContent;
