import { useQuery } from "@apollo/client";
import { Box, Heading } from "@chakra-ui/react";
import { GET_UPCOMING_TOURNAMENTS } from "graphql/queries";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { groupTournamentEventsByDate } from "utils/formatters";
import CardLoader from "./CardLoader";
import TournamentsList from "./TournamentsList";

function UpcomingTournaments() {
  const history = useHistory();

  // Note passing new Date() to useQuery() will result in infinite render loop because
  // it will be different on each render. To prevent this, remove the time data with setHours.
  const todayWithoutTime = new Date();
  todayWithoutTime.setHours(0, 0, 0, 0);

  const { loading: isLoadingUpcomingTournaments, data: upcomingTournaments } =
    useQuery(GET_UPCOMING_TOURNAMENTS, {
      variables: {
        startTime: todayWithoutTime,
      },
    });

  const tournaments = (upcomingTournaments?.tournaments?.edges || [])
    .map(({ node }) => groupTournamentEventsByDate(node))
    .sort((a, b) => new Date(a.lastEventTime) - new Date(b.lastEventTime));

  return (
    <Box>
      <Heading as="h3" fontSize="x-large" mb="4">
        Upcoming tournaments
      </Heading>
      <CardLoader
        numSections={1}
        noOfLines={4}
        isLoading={isLoadingUpcomingTournaments}
      >
        <TournamentsList
          tournaments={tournaments}
          onClick={(tournament) => {
            history.push(`/tournaments/${tournament?.objectId}/register`);
          }}
        />
      </CardLoader>
    </Box>
  );
}

export default UpcomingTournaments;
