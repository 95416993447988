import { gql } from "@apollo/client";
import { TEAM_FIELDS_FRAGMENT, TOURNAMENT_FIELDS_FRAGMENT } from "./queries";

const VIEWER_FIELDS_FRAGMENT = gql`
  fragment ViewerFieldsFragment on Viewer {
    sessionToken
    user {
      objectId
      fullname
      email
      leagueId
      profilepic
    }
  }
`;

/**
 * Creates a new user with fullname, email and password.
 * Note: back4app requires the 'username' field so we are setting it as the email as a workaround
 * @param {string} fullname - User inputted full name. ie. "Sam Smith".
 * @param {string} email - User inputted email
 * @param {string} password - User inputted password
 */
export const SIGN_UP = gql`
  ${VIEWER_FIELDS_FRAGMENT}
  mutation signUp(
    $fullname: String!
    $email: String!
    $password: String!
    $phone: String!
  ) {
    signUp(
      input: {
        fields: {
          fullname: $fullname
          username: $email
          email: $email
          phone: $phone
          password: $password
        }
      }
    ) {
      viewer {
        ...ViewerFieldsFragment
      }
    }
  }
`;

/**
 * Signs in a user with email and password
 * @param {string} email - User inputted email
 * @param {string} password - User inputted password
 */
export const SIGN_IN = gql`
  ${VIEWER_FIELDS_FRAGMENT}
  mutation signIn($email: String!, $password: String!) {
    logIn(input: { username: $email, password: $password }) {
      viewer {
        ...ViewerFieldsFragment
      }
    }
  }
`;

/**
 * Logs in a user via Facebook access token.
 */
export const CONTINUE_WITH_FACEBOOK = gql`
  ${VIEWER_FIELDS_FRAGMENT}
  mutation continueWithFacebook($fbAccessToken: String!) {
    ContinueWithFacebook(fbAccessToken: $fbAccessToken) {
      ...ViewerFieldsFragment
    }
  }
`;

/**
 * Logs in a user via Google access token.
 * Parse also requires Google's "id_token" to save in User.authData.
 */
export const CONTINUE_WITH_GOOGLE = gql`
  ${VIEWER_FIELDS_FRAGMENT}
  mutation continueWithGoogle(
    $googleAccessToken: String!
    $googleTokenId: String!
  ) {
    ContinueWithGoogle(
      googleAccessToken: $googleAccessToken
      googleTokenId: $googleTokenId
    ) {
      ...ViewerFieldsFragment
    }
  }
`;

/**
 * Logs a user out. Must be sent with a valid sessionToken
 * in the "X-Parse-Session-Token" header.
 */
export const LOG_OUT = gql`
  mutation logOut {
    logOut(input: { clientMutationId: "logOut" }) {
      clientMutationId
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile(
    $userId: ID!
    $fullname: String
    $email: String
    $phone: String
  ) {
    UpdateUserProfile(
      userId: $userId
      fullname: $fullname
      email: $email
      phone: $phone
    )
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!) {
    resetPassword(input: { email: $email }) {
      ok
    }
  }
`;

export const CREATE_LEAGUE = gql`
  mutation createLeague($userId: ID!, $name: String!, $paypalEmail: String) {
    createLeague(
      input: {
        fields: {
          name: $name
          createdBy: { link: $userId }
          paypalEmail: $paypalEmail
          ACL: {
            public: { read: true, write: false }
            users: { read: true, write: true, userId: $userId }
          }
        }
      }
    ) {
      league {
        objectId
        name
      }
    }
  }
`;

export const UPDATE_USER_WITH_LEAGUE = gql`
  mutation updateUserWithLeague(
    $userId: ID!
    $leagueId: ID!
    $leagueIdString: String!
  ) {
    updateUser(
      input: {
        id: $userId
        fields: { leagueId: $leagueIdString, leagues: { add: [$leagueId] } }
      }
    ) {
      user {
        objectId
        fullname
      }
    }
  }
`;

export const UPDATE_LEAGUE = gql`
  mutation updateLeague(
    $leagueId: ID!
    $name: String
    $paypalEmail: String
    $paypalMerchantId: String
  ) {
    updateLeague(
      input: {
        id: $leagueId
        fields: {
          name: $name
          paypalEmail: $paypalEmail
          paypalMerchantId: $paypalMerchantId
        }
      }
    ) {
      league {
        objectId
        name
        paypalEmail
        paypalMerchantId
      }
    }
  }
`;

// TODO: Add ACL?
export const CREATE_TOURNAMENT = gql`
  ${TOURNAMENT_FIELDS_FRAGMENT}
  mutation createTournament(
    $leagueId: ID!
    $name: String!
    $description: String
    $location: String
    $parkingInstructions: String
    $pricePerPerson: Float
    $priceAfterFirstEvent: Float
  ) {
    createTournament(
      input: {
        fields: {
          name: $name
          description: $description
          location: $location
          parkingInstructions: $parkingInstructions
          pricePerPerson: $pricePerPerson
          priceAfterFirstEvent: $priceAfterFirstEvent
          league: { link: $leagueId }
        }
      }
    ) {
      tournament {
        ...TournamentFieldsFragment
      }
    }
  }
`;

export const ADD_TOURNAMENT_TO_LEAGUE = gql`
  mutation addTournamentToLeague($leagueId: ID!, $tournamentId: ID!) {
    updateLeague(
      input: {
        id: $leagueId
        fields: { tournaments: { add: [$tournamentId] } }
      }
    ) {
      league {
        objectId
        name
      }
    }
  }
`;

export const ADD_SPONSOR_IMAGE_TO_TOURNAMENT = gql`
  mutation addSponsorImageToTournament($tournamentId: ID!, $file: Upload!) {
    updateTournament(
      input: {
        id: $tournamentId
        fields: { sponsorImages: { createAndAdd: { file: { upload: $file } } } }
      }
    ) {
      tournament {
        objectId
        sponsorImages {
          edges {
            node {
              file {
                name
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_MEDIA = gql`
  mutation updateMedia($mediaId: ID!, $website: String) {
    updateMedia(input: { id: $mediaId, fields: { website: $website } }) {
      media {
        objectId
        website
      }
    }
  }
`;

export const DELETE_MEDIA = gql`
  mutation updateMedia($mediaId: ID!) {
    deleteMedia(input: { id: $mediaId }) {
      media {
        objectId
      }
    }
  }
`;

export const UPDATE_TOURNAMENT = gql`
  mutation updateTournament(
    $tournamentId: ID!
    $name: String!
    $description: String
    $location: String
    $parkingInstructions: String
    $pricePerPerson: Float
    $priceAfterFirstEvent: Float
    $isPublished: Boolean
  ) {
    updateTournament(
      input: {
        id: $tournamentId
        fields: {
          name: $name
          description: $description
          location: $location
          parkingInstructions: $parkingInstructions
          pricePerPerson: $pricePerPerson
          priceAfterFirstEvent: $priceAfterFirstEvent
          isPublished: $isPublished
        }
      }
    ) {
      tournament {
        objectId
        name
        description
        location
        parkingInstructions
        pricePerPerson
        priceAfterFirstEvent
        isPublished
      }
    }
  }
`;

export const DELETE_TOURNAMENT = gql`
  mutation deleteTournament($tournamentId: ID!) {
    deleteTournament(input: { id: $tournamentId }) {
      tournament {
        objectId
        name
      }
    }
  }
`;

// TODO: Add ACL?
// ** This is how you create a child while establishing a 2-way connection between parent <-> child.
export const CREATE_EVENT = gql`
  mutation createEvent(
    $tournamentId: ID!
    $eventType: String
    $eventFormat: String
    $eventStartTime: Date
    $skillLevel: String
    $ageRange: String
    $playersMax: Float
    $pointsToWin: Float
    $pointsToWinBy: Float
  ) {
    updateTournament(
      input: {
        id: $tournamentId
        fields: {
          events: {
            createAndAdd: {
              eventType: $eventType
              eventFormat: $eventFormat
              eventStartTime: $eventStartTime
              skillLevel: $skillLevel
              ageRange: $ageRange
              playersMax: $playersMax
              pointsToWin: $pointsToWin
              pointsToWinBy: $pointsToWinBy
              tournament: { link: $tournamentId }
            }
          }
        }
      }
    ) {
      tournament {
        objectId
      }
    }
  }
`;

export const ADD_NEW_TEAM_TO_EVENT = gql`
  ${TEAM_FIELDS_FRAGMENT}
  mutation addTeamToEvent($eventId: ID!, $userId: ID!) {
    updateEvent(
      input: {
        id: $eventId
        fields: {
          teams: {
            createAndAdd: {
              event: { link: $eventId }
              players: { createAndAdd: { user: { link: $userId } } }
            }
          }
        }
      }
    ) {
      event {
        objectId
        teams {
          edges {
            node {
              ...TeamFieldsFragment
            }
          }
        }
      }
    }
  }
`;

export const ADD_EXISTING_TEAM_TO_EVENT = gql`
  ${TEAM_FIELDS_FRAGMENT}
  mutation addExistingTeamToEvent($eventId: ID!, $teamId: ID!) {
    updateEvent(
      input: { id: $eventId, fields: { teams: { add: [$teamId] } } }
    ) {
      event {
        objectId
        teams {
          edges {
            node {
              ...TeamFieldsFragment
            }
          }
        }
      }
    }
  }
`;

export const REMOVE_TEAM_FROM_EVENT = gql`
  ${TEAM_FIELDS_FRAGMENT}
  mutation removeTeamFromEvent($eventId: ID!, $teamId: ID!) {
    updateEvent(
      input: { id: $eventId, fields: { teams: { remove: [$teamId] } } }
    ) {
      event {
        objectId
        teams {
          edges {
            node {
              ...TeamFieldsFragment
            }
          }
        }
      }
    }
  }
`;

export const CREATE_TEAM = gql`
  mutation createTeam($eventId: ID!) {
    createTeam(input: { fields: { event: { link: $eventId } } }) {
      team {
        objectId
      }
    }
  }
`;

export const CREATE_PLAYER = gql`
  mutation createPlayer($userId: ID!) {
    createPlayer(input: { fields: { user: { link: $userId } } }) {
      player {
        objectId
      }
    }
  }
`;

export const DELETE_PLAYER = gql`
  mutation deletePlayer($playerId: ID!) {
    deletePlayer(input: { id: $playerId }) {
      player {
        objectId
      }
    }
  }
`;

export const UPDATE_PLAYER_HAS_PAID = gql`
  mutation updatePlayerHasPaid($playerId: ID!, $hasPaid: Boolean) {
    updatePlayer(input: { id: $playerId, fields: { hasPaid: $hasPaid } }) {
      player {
        objectId
        hasPaid
      }
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation deleteTeam($teamId: ID!) {
    deleteTeam(input: { id: $teamId }) {
      team {
        objectId
      }
    }
  }
`;

export const ADD_NEW_PLAYER_TO_TEAM = gql`
  mutation addNewPlayerToTeam($teamId: ID!, $userId: ID!) {
    updateTeam(
      input: {
        id: $teamId
        fields: { players: { createAndAdd: { user: { link: $userId } } } }
      }
    ) {
      team {
        objectId
      }
    }
  }
`;

export const ADD_EXISTING_PLAYER_TO_TEAM = gql`
  mutation addExistingPlayerToTeam($teamId: ID!, $playerId: ID!) {
    updateTeam(
      input: { id: $teamId, fields: { players: { add: [$playerId] } } }
    ) {
      team {
        objectId
      }
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation updateEvent(
    $eventId: ID!
    $eventType: String
    $eventFormat: String
    $eventStartTime: Date
    $eventActualStartTime: Date
    $eventActualEndTime: Date
    $eventSpec: String
    $skillLevel: String
    $ageRange: String
    $playersMax: Float
    $pointsToWin: Float
    $pointsToWinBy: Float
  ) {
    updateEvent(
      input: {
        id: $eventId
        fields: {
          eventType: $eventType
          eventFormat: $eventFormat
          eventStartTime: $eventStartTime
          eventActualStartTime: $eventActualStartTime
          eventActualEndTime: $eventActualEndTime
          eventSpec: $eventSpec
          skillLevel: $skillLevel
          ageRange: $ageRange
          playersMax: $playersMax
          pointsToWin: $pointsToWin
          pointsToWinBy: $pointsToWinBy
        }
      }
    ) {
      event {
        objectId
        eventType
        eventFormat
        eventStartTime
        eventSpec
        skillLevel
        ageRange
        playersMax
        pointsToWin
        pointsToWinBy
      }
    }
  }
`;

export const START_EVENT = gql`
  mutation updateEvent($eventId: ID!, $eventActualStartTime: Date) {
    updateEvent(
      input: {
        id: $eventId
        fields: { eventActualStartTime: $eventActualStartTime }
      }
    ) {
      event {
        objectId
        eventActualStartTime
      }
    }
  }
`;

export const END_EVENT = gql`
  mutation updateEvent($eventId: ID!, $eventActualEndTime: Date) {
    updateEvent(
      input: {
        id: $eventId
        fields: { eventActualEndTime: $eventActualEndTime }
      }
    ) {
      event {
        objectId
        eventActualEndTime
      }
    }
  }
`;

export const CREATE_GAME = gql`
  mutation createGame($eventId: ID!, $teamOneId: ID, $teamTwoId: ID) {
    createGame(
      input: {
        fields: {
          event: { link: $eventId }
          teamOne: { link: $teamOneId }
          teamTwo: { link: $teamTwoId }
        }
      }
    ) {
      game {
        objectId
        teamOneScore
        teamTwoScore
        teamOne {
          objectId
          players {
            edges {
              node {
                objectId
                user {
                  fullname
                }
              }
            }
          }
        }
        teamTwo {
          objectId
          players {
            edges {
              node {
                objectId
                user {
                  fullname
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ADD_GAME_TO_EVENT = gql`
  mutation addGameToEvent(
    $eventId: ID!
    $teamOneId: ID
    $teamTwoId: ID
    $eventActualStartTime: Date
  ) {
    updateEvent(
      input: {
        id: $eventId
        fields: {
          eventActualStartTime: $eventActualStartTime
          games: {
            createAndAdd: {
              event: { link: $eventId }
              teamOne: { link: $teamOneId }
              teamTwo: { link: $teamTwoId }
            }
          }
        }
      }
    ) {
      event {
        objectId
      }
    }
  }
`;

export function getUpdateGameMutation({
  gameId,
  teamOneScore,
  teamTwoScore,
  teamOneId,
  teamTwoId,
}) {
  if (!gameId) {
    throw new Error("`gameId` required");
  }

  // only pass scores if number (0,1,2..) or null (the absence of a score).
  function canPassScoreToMutation(score) {
    return typeof score === "number" || score === null;
  }

  const teamOneScoreField = canPassScoreToMutation(teamOneScore)
    ? `teamOneScore: ${teamOneScore},`
    : "";
  const teamTwoScoreField = canPassScoreToMutation(teamTwoScore)
    ? `teamTwoScore: ${teamTwoScore},`
    : "";
  const teamOneIdField =
    teamOneId !== undefined ? `teamOne: { link: "${teamOneId}" },` : "";
  const teamTwoIdField =
    teamTwoId !== undefined ? `teamTwo: { link: "${teamTwoId}" },` : "";

  return gql`
    mutation {
      updateGame(
        input: {
          id: "${gameId}"
          fields: {
            ${teamOneScoreField}
            ${teamTwoScoreField}
            ${teamOneIdField}
            ${teamTwoIdField}
          }
        }
      ) {
        game {
          objectId
        }
      }
    }
  `;
}

export const DELETE_GAME = gql`
  mutation deleteGame($gameId: ID!) {
    deleteGame(input: { id: $gameId }) {
      game {
        objectId
      }
    }
  }
`;

export const UPDATE_GAME_TEAMS = gql`
  mutation updateGameTeams($gameId: ID!, $teamOneId: ID, $teamTwoId: ID) {
    updateGame(
      input: {
        id: $gameId
        fields: { teamOne: { link: $teamOneId }, teamTwo: { link: $teamTwoId } }
      }
    ) {
      game {
        objectId
      }
    }
  }
`;

export const UPDATE_GAME_SCORES = gql`
  mutation updateGameScores(
    $gameId: ID!
    $teamOneScore: Float
    $teamTwoScore: Float
  ) {
    updateGame(
      input: {
        id: $gameId
        fields: { teamOneScore: $teamOneScore, teamTwoScore: $teamTwoScore }
      }
    ) {
      game {
        objectId
      }
    }
  }
`;

export const ADD_GAMES_TO_EVENT = gql`
  mutation addGamesToEvent($eventId: ID!, $gameIds: [ID!]) {
    updateEvent(input: { id: $eventId, fields: { games: { add: $gameIds } } }) {
      event {
        objectId
      }
    }
  }
`;

export const ADD_GAMES_AND_UDPATE_EVENT_SPEC = gql`
  mutation addGamesAndUpdateEventSpec(
    $eventId: ID!
    $gameIds: [ID!]
    $eventSpec: String
  ) {
    updateEvent(
      input: {
        id: $eventId
        fields: { games: { add: $gameIds }, eventSpec: $eventSpec }
      }
    ) {
      event {
        objectId
      }
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation deleteEvent($eventId: ID!) {
    deleteEvent(input: { id: $eventId }) {
      event {
        objectId
      }
    }
  }
`;

export const SEND_INVITE = gql`
  mutation sendInvite(
    $recipientEmail: String!
    $teamId: String!
    $inviterName: String!
    $inviterId: String!
    $tournamentName: String!
    $leagueName: String!
    $eventName: String!
    $eventDateTime: String!
  ) {
    SendInvite(
      recipientEmail: $recipientEmail
      teamId: $teamId
      inviterName: $inviterName
      inviterId: $inviterId
      tournamentName: $tournamentName
      leagueName: $leagueName
      eventName: $eventName
      eventDateTime: $eventDateTime
    )
  }
`;

export const SEND_DECLINE_INVITE = gql`
  mutation sendDeclineInvite(
    $declinerId: ID!
    $inviterId: ID!
    $tournamentName: String!
    $leagueName: String!
    $eventName: String!
    $eventDateTime: String!
  ) {
    SendDecline(
      declinerId: $declinerId
      inviterId: $inviterId
      tournamentName: $tournamentName
      leagueName: $leagueName
      eventName: $eventName
      eventDateTime: $eventDateTime
    )
  }
`;

export const SEND_ACCEPT_INVITE = gql`
  mutation sendAcceptInvite(
    $accepterId: ID!
    $inviterId: ID!
    $tournamentName: String!
    $leagueName: String!
    $eventName: String!
    $eventDateTime: String!
  ) {
    SendAccept(
      accepterId: $accepterId
      inviterId: $inviterId
      tournamentName: $tournamentName
      leagueName: $leagueName
      eventName: $eventName
      eventDateTime: $eventDateTime
    )
  }
`;

export const GET_TOURNAMENT_PLAYER_EMAILS = gql`
  mutation getTournamentPlayerEmails($tournamentId: ID!) {
    GetTournamentPlayerEmails(tournamentId: $tournamentId)
  }
`;

export const GET_EVENT_PLAYER_EMAILS = gql`
  mutation getEventPlayerEmails($eventId: ID!) {
    GetEventPlayerEmails(eventId: $eventId)
  }
`;

export const SEND_FEEDBACK = gql`
  mutation createFeedback($comments: String!) {
    createFeedback(input: { fields: { comments: $comments } }) {
      feedback {
        objectId
      }
    }
  }
`;

export const GET_PAYPAL_SIGNUP_LINK = gql`
  mutation getPayPalSignUpLink($leagueId: ID!) {
    GetPayPalSignUpLink(leagueId: $leagueId)
  }
`;

export const SAVE_PAYPAL_MERCHANT_INFO = gql`
  mutation savePayPalMerchantInfo($leagueId: ID!, $paypalMerchantId: String!) {
    SavePayPalMerchantInfo(
      leagueId: $leagueId
      paypalMerchantId: $paypalMerchantId
    )
  }
`;

export const CREATE_PAYPAL_ORDER = gql`
  mutation createPayPalOrder(
    $pricePerPerson: String!
    $payeePaypalEmail: String!
    $description: String
  ) {
    CreatePayPalOrder(
      pricePerPerson: $pricePerPerson
      payeePaypalEmail: $payeePaypalEmail
      description: $description
    ) {
      id
      status
    }
  }
`;

export const CAPTURE_PAYPAL_ORDER = gql`
  mutation capturePayPalOrder($orderId: String!, $playerId: ID!) {
    CapturePayPalOrder(orderId: $orderId, playerId: $playerId) {
      status
    }
  }
`;
