import {
  Input,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { SIGN_IN } from "graphql/mutations";
import { useAuthContext } from "context/AuthContext";
import { DURATION } from "constants/duration";
import ResetPasswordModal from "components/ResetPasswordModal";
import PasswordInput from "components/PasswordInput";
import FacebookLoginButton from "components/FacebookLoginButton";
import TextDivider from "components/TextDivider";
import GoogleLoginButton from "./GoogleLoginButton";

function SignInForm({ onSignIn = () => {} }) {
  const [signIn, { loading }] = useMutation(SIGN_IN);
  const { setAuthState } = useAuthContext();
  const toast = useToast();
  const {
    isOpen: isResetPasswordModalOpen,
    onOpen: onResetPasswordModalOpen,
    onClose: onResetPasswordModalClose,
  } = useDisclosure();

  return (
    <>
      <FacebookLoginButton onSuccess={onSignIn} />
      <GoogleLoginButton mt="4" onSuccess={onSignIn} />

      <TextDivider text="or" my="10" />

      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("Invalid email address")
            .required("Required"),
          password: Yup.string()
            .min(8, "Password must be 8 characters or more")
            .required("Required"),
        })}
        onSubmit={({ email, ...rest }) => {
          const variables = { email: email.toLocaleLowerCase(), ...rest };
          signIn({ variables })
            .then((resp) => {
              const viewer = resp?.data?.logIn?.viewer;
              setAuthState(viewer);
              onSignIn();
            })
            .catch((e) => {
              toast({
                title: "Could not sign in",
                description:
                  "The email and password you entered did not match our records. Please double-check and try again.",
                status: "error",
                duration: DURATION.LONG,
                isClosable: true,
              });
            });
        }}
      >
        <Form>
          <Field name="email">
            {({ field, form }) => (
              <FormControl isInvalid={form.errors.email && form.touched.email}>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input {...field} id="email" />
                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="password">
            {({ field, form }) => (
              <FormControl
                mt="3"
                isInvalid={form.errors.password && form.touched.password}
              >
                <FormLabel htmlFor="password">Password</FormLabel>
                <PasswordInput {...field} id="password" />
                <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                <Button
                  mt="2"
                  variant="link"
                  size="sm"
                  onClick={onResetPasswordModalOpen}
                >
                  Forgot your password?
                </Button>
              </FormControl>
            )}
          </Field>

          <Button
            mt={4}
            w="100%"
            colorScheme="teal"
            isLoading={loading}
            type="submit"
          >
            Sign in
          </Button>
        </Form>
      </Formik>
      <ResetPasswordModal
        isOpen={isResetPasswordModalOpen}
        onClose={onResetPasswordModalClose}
      />
    </>
  );
}

export default SignInForm;
