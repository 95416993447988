import { useQuery } from "@apollo/client";
import {
  Link,
  HStack,
  VStack,
  StackDivider,
  Button,
  Container,
  Icon,
  Box,
  Heading,
  Text,
  SkeletonText,
  useColorModeValue,
} from "@chakra-ui/react";
import LogoutButton from "components/LogoutButton";
import DarkModeButton from "components/DarkModeButton";
import { ROUTES } from "constants/routes";
import { GET_USER_FIRST_LEAGUE } from "graphql/queries";
import { useAuthContext } from "context/AuthContext";
import { BiPlus, BiPencil } from "react-icons/bi";
import CardLoader from "components/CardLoader";
import { useHistory } from "react-router-dom";

function AccountSettingsPage() {
  const { authState } = useAuthContext();
  const userId = authState?.user?.objectId;
  const history = useHistory();
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const eventCardBgColor = useColorModeValue("white", "gray.900");

  const { loading: isLoadingLeague, data } = useQuery(GET_USER_FIRST_LEAGUE, {
    fetchPolicy: "no-cache",
    variables: {
      userId,
    },
  });

  const league = data?.user?.leagues?.edges?.[0]?.node;
  const userFullName = data?.user?.fullname;
  const canCreateLeague = !!data?.user?.canCreateLeague;
  const hasLeague = !!league;

  return (
    <Container
      data-testid="account-settings-page"
      maxW="container.lg"
      mt={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}
    >
      <Box d="flex" justifyContent="space-between">
        <Heading mb={{ base: 5, sm: 5, md: 10, lg: 10, xl: 10 }}>
          {isLoadingLeague ? (
            <SkeletonText noOfLines={1} minW="200px" minH="44px" />
          ) : (
            `Hey ${userFullName}!`
          )}
        </Heading>
        <DarkModeButton />
      </Box>

      <CardLoader isLoading={isLoadingLeague}>
        <VStack
          divider={<StackDivider borderColor="gray.200" />}
          spacing={4}
          align="stretch"
          borderRadius="md"
          border="1.5px solid"
          borderColor={borderColor}
          backgroundColor={eventCardBgColor}
          p="20px"
        >
          <Box>
            <Heading size="md" mb={3}>
              Profile
            </Heading>
            <Button
              variant="ghost"
              w="100%"
              justifyContent="left"
              onClick={() => {
                history.push(ROUTES.EDIT_PROFILE);
              }}
            >
              <Icon as={BiPencil} w={5} h={5} mr={2} />
              Edit profile
            </Button>
          </Box>

          {canCreateLeague && (
            <Box>
              <Heading size="md" mb={3}>
                Admin
              </Heading>

              {!hasLeague && (
                <Text mb="3">
                  Create your own league to start organizing tournaments,
                  events, and register participants.
                </Text>
              )}

              <Button
                variant="ghost"
                w="100%"
                justifyContent="left"
                onClick={() => {
                  history.push(
                    hasLeague
                      ? `/leagues/${league.objectId}`
                      : ROUTES.NEW_LEAGUE
                  );
                }}
              >
                <Icon as={hasLeague ? BiPencil : BiPlus} w={5} h={5} mr={2} />
                {hasLeague ? "Edit league" : "Create league"}
              </Button>
            </Box>
          )}
          <Box>
            <LogoutButton w="100%" />
          </Box>
        </VStack>
        <Box d="flex" justifyContent="center">
          <HStack spacing={6}>
            <Link my="4" href={ROUTES.TERMS_OF_USE}>
              Terms of use
            </Link>
            <Link my="4" href={ROUTES.PRIVACY_POLICY}>
              Privacy policy
            </Link>
            <Link my="4" href={ROUTES.CONTACT_US}>
              Contact us
            </Link>
          </HStack>
        </Box>
      </CardLoader>
    </Container>
  );
}

export default AccountSettingsPage;
