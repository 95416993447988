import { useMutation } from "@apollo/client";
import { VStack } from "@chakra-ui/react";
import { FUNDING, PayPalButtons } from "@paypal/react-paypal-js";
import { CREATE_PAYPAL_ORDER, CAPTURE_PAYPAL_ORDER } from "graphql/mutations";
import PropTypes from "prop-types";

// https://developer.paypal.com/docs/business/checkout/configure-payments/standalone-buttons/#know-before-you-code
const FUNDING_SOURCES = [FUNDING.PAYPAL, FUNDING.VENMO, FUNDING.CARD];

function PaymentButtons({
  pricePerPerson,
  paypalEmail,
  playerId,
  paymentDescription,
  onPaymentSuccess = () => {},
  onPaymentFail = () => {},
}) {
  const [createOrder] = useMutation(CREATE_PAYPAL_ORDER);
  const [captureOrder] = useMutation(CAPTURE_PAYPAL_ORDER);

  const description = paymentDescription
    ? `[DinkPal] ${paymentDescription}`
    : "[DinkPal] Tournament registration";

  return (
    <VStack spacing="2" align="stretch">
      {FUNDING_SOURCES.map((source) => (
        <PayPalButtons
          key={source}
          fundingSource={source}
          createOrder={async () => {
            // createOrder must return a Promise resolving to an order ID. https://developer.paypal.com/docs/platforms/checkout/set-up-payments/
            const createOrderResp = await createOrder({
              variables: {
                pricePerPerson: String(pricePerPerson),
                description,
                payeePaypalEmail: paypalEmail,
              },
            });

            return createOrderResp.data.CreatePayPalOrder.id;
          }}
          onApprove={async (data) => {
            try {
              const captureOrderResp = await captureOrder({
                variables: {
                  orderId: data.orderID,
                  playerId,
                },
              });

              onPaymentSuccess();
              return captureOrderResp;
            } catch (e) {
              onPaymentFail();
            }
          }}
        />
      ))}
    </VStack>
  );
}

PaymentButtons.propTypes = {
  pricePerPerson: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  paypalEmail: PropTypes.string.isRequired,
  playerId: PropTypes.string.isRequired,
  paymentDescription: PropTypes.string,
  onPaymentSuccess: PropTypes.func,
  onPaymentFail: PropTypes.func,
};

export default PaymentButtons;
