import { Text, Box } from "@chakra-ui/react";
import { MdDragHandle } from "react-icons/md";
import { formatAgeRange } from "utils/formatters";
import { useState } from "react";

import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
} from "@chakra-ui/react";

// This slider sometimes throws when changing knobs on mobile only.
// TODO: Wait for this issue to be fixed: https://github.com/chakra-ui/chakra-ui/issues/4808
function getKnobContent(rangeValues, knobValue) {
  const isHandleIconDisplayed = rangeValues[0] === 0 && rangeValues[1] === 100;
  const displayedValue =
    knobValue === 0 ? "-" : knobValue === 100 ? "+" : knobValue;

  return isHandleIconDisplayed ? (
    <Box
      color="black"
      as={MdDragHandle}
      w={6}
      h={6}
      transform="rotate(90deg)"
    />
  ) : (
    <Text color="black">{displayedValue}</Text>
  );
}

function AgeRangeSlider({ value, onChange }) {
  const [initialMin, initialMax] = value.split(",").map(Number);
  const [minMax, setMinMax] = useState([initialMin, initialMax]);
  const [min, max] = minMax;

  const onChangeEnd = (newValue) => {
    onChange(newValue.join(","));
  };

  const onThumbChange = (newValue) => {
    setMinMax([...newValue]);
  };

  const displayedRange = formatAgeRange(value);
  const knobMin = getKnobContent(minMax, min);
  const knobMax = getKnobContent(minMax, max);
  return (
    <>
      <Text d="flex" justifyContent="center" mb="4">
        {displayedRange}
      </Text>
      <RangeSlider
        // eslint-disable-next-line jsx-a11y/aria-proptypes
        aria-label={["ageMin", "ageMax"]}
        defaultValue={[initialMin, initialMax]}
        min={0}
        max={100}
        colorScheme="teal"
        onChangeEnd={onChangeEnd}
        onChange={onThumbChange}
      >
        <RangeSliderTrack>
          <RangeSliderFilledTrack />
        </RangeSliderTrack>
        <RangeSliderThumb boxSize={8} index={0}>
          {knobMin}
        </RangeSliderThumb>
        <RangeSliderThumb boxSize={8} index={1}>
          {knobMax}
        </RangeSliderThumb>
      </RangeSlider>
    </>
  );
}

export default AgeRangeSlider;
