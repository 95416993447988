import {
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useState } from "react";
import SignUpForm from "components/SignUpForm";
import SignInForm from "components/SignInForm";

/**
 *
 * @param {boolean} isOpen - If modal is open
 * @param {Function} onClose - Called when modal is closed
 * @param {gql[]} refetchQueries - Array of
 */
function SignUpModal({ isOpen, onClose }) {
  const [isSignUp, setIsSignUp] = useState(true);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isSignUp ? "Sign up" : "Sign in"} to continue
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody mb="4">
          {isSignUp && (
            <>
              <Text mb="3">
                Already have an account?{" "}
                <Button
                  variant="link"
                  onClick={() => {
                    setIsSignUp(false);
                  }}
                >
                  Sign in
                </Button>
              </Text>
              <SignUpForm onSignUp={onClose} />
            </>
          )}
          {!isSignUp && (
            <>
              <Text mb="3">
                Don't have an account?{" "}
                <Button
                  variant="link"
                  onClick={() => {
                    setIsSignUp(true);
                  }}
                >
                  Sign up
                </Button>
              </Text>
              <SignInForm onSignIn={onClose} />
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default SignUpModal;
