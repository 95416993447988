function TermsOfUsePage() {
  return (
    <iframe
      title="Terms of Use for DinkPal"
      style={{
        height: "100vh",
        border: "none",
        width: "100%",
        backgroundColor: "white",
      }}
      src="/terms-of-use.html"
    />
  );
}

export default TermsOfUsePage;
