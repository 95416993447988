import { Box, Heading, Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { ROUND_NODE_PREFIX } from "utils/constants";
import MatchInput from "components/MatchInput";

function RoundRobinViewer({
  isEditMode,
  rootNode,
  gamesMap = {},
  teamsMap = {},
  onUpdateGame,
}) {
  return (
    <Box mb="40">
      {!!rootNode.children.length && (
        <Box>
          <Table variant="simple" size="sm">
            <Tbody>
              {rootNode.children.map((roundNode) => (
                <Tr key={roundNode.id}>
                  <Td>
                    <Heading
                      as="h2"
                      fontSize={["sm", "sm", "md", "lg"]}
                      textAlign={["center", "center", "center", "left"]}
                    >
                      Round {roundNode.id.slice(ROUND_NODE_PREFIX.length)}
                    </Heading>
                  </Td>
                  {roundNode.children.map((matchNode) => (
                    <Td key={matchNode.id}>
                      <MatchInput
                        isEditMode={isEditMode}
                        matchNode={matchNode}
                        gamesMap={gamesMap}
                        teamsMap={teamsMap}
                        onUpdateGame={onUpdateGame}
                      />
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
    </Box>
  );
}

export default RoundRobinViewer;
