import {
  Alert,
  AlertIcon,
  Text,
  Button,
  Badge,
  Box,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from "@chakra-ui/react";
import PaymentButtons from "./PaymentButtons";
import PropTypes from "prop-types";
import {
  formatEventName,
  getHasUserAlreadyPaidForAnotherEvent,
} from "utils/formatters";
import client from "graphql/client";
import { useAuthContext } from "context/AuthContext";
import { DURATION } from "constants/duration";

function SubmitPaymentModalContent({
  paypalEmail,
  tournament,
  event,
  playerId,
  onSuccess = () => {},
  onClose,
  refetchQueries,
}) {
  const toast = useToast();
  const { authState } = useAuthContext();
  const userId = authState?.user?.objectId;
  const hasUserAlreadyPaidForAnotherEvent =
    getHasUserAlreadyPaidForAnotherEvent(userId, tournament);
  const isAdditionalEventPrice =
    Number.isInteger(tournament?.priceAfterFirstEvent) &&
    hasUserAlreadyPaidForAnotherEvent;
  const pricePerPerson = isAdditionalEventPrice
    ? tournament?.priceAfterFirstEvent
    : tournament?.pricePerPerson;
  const eventName = formatEventName(event);

  if (!paypalEmail) {
    return (
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Pay for registration</ModalHeader>
        <ModalBody>
          <Alert status="warning">
            <AlertIcon />
            The host league does not have a PayPal email set up. Please contact
            them to fix this.
          </Alert>
        </ModalBody>
        <ModalFooter d="flex" justifyContent="space-between">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    );
  }

  return (
    <ModalContent>
      <ModalHeader>Pay for registration</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Box d="flex" textAlign="center" flexDir="column" mb="35px">
          <Box>
            <Text
              d="inline-block"
              fontWeight="bold"
              fontSize="2em"
              p="5px 20px"
              borderRadius="md"
              border="1.5px solid"
              borderColor="gray.200"
            >
              ${pricePerPerson}
            </Text>
          </Box>
          {isAdditionalEventPrice && (
            <Box>
              <Badge mt="2" colorScheme="yellow">
                Additional event price
              </Badge>
            </Box>
          )}
        </Box>
        <Text mb="2">Please pay with one of the options below.</Text>
        <Alert status="info" borderRadius="md" mb="4">
          <AlertIcon />
          <Text>
            Your payment will be sent via PayPal to:
            <br />
            <b>{paypalEmail}</b>
          </Text>
        </Alert>
        <PaymentButtons
          pricePerPerson={pricePerPerson}
          paypalEmail={paypalEmail}
          paymentDescription={eventName}
          playerId={playerId}
          onPaymentSuccess={async () => {
            toast({
              title: "Thank you for your payment 🙏 ",
              status: "success",
              duration: DURATION.LONG,
              isClosable: true,
            });

            // Refetch any queries because because captureOrder()
            // sets player.hasPaid = true.
            await client.refetchQueries({
              include: refetchQueries,
            });

            onSuccess();
          }}
          onPaymentFail={() => {
            toast({
              title: "We're sorry, something went wrong with your payment.",
              status: "error",
              duration: DURATION.LONG,
              isClosable: true,
            });
          }}
        />
      </ModalBody>
      <ModalFooter d="flex" justifyContent="space-between">
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}

SubmitPaymentModalContent.propTypes = {
  paypalEmail: PropTypes.string,
  pricePerPerson: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  player: PropTypes.object,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  refetchQueries: PropTypes.array,
};

export default SubmitPaymentModalContent;
