import {
  EVENT_TYPE_OPTION_NAMES,
  EVENT_FORMAT_OPTION_NAMES,
  EVENT_FORMAT_OPTIONS,
  DOUBLES_EVENT_TYPES,
  BYE_TEAM_ID,
  BYE_PLAYER_ID,
  BYE_USER_ID,
} from "utils/event";
import dateFnsFormat from "date-fns/format";
import { TEAM_NODE_PREFIX } from "./constants";
import { DINKPAL_FLAT_FEE } from "constants/transactions";

export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    // For IE
    return document.execCommand("copy", true, text);
  }
}

/**
 * Return a fully formed url which a user can send to a potential partner.
 * @returns {string} - Fully formed url
 */
export function getInvitePartnerUrl(teamId, inviterId) {
  return `${process.env.REACT_APP_DOMAIN}/teams/${teamId}/invite?iui=${inviterId}`;
}

/**
 * Gets query params from url.
 * @returns {<URLSearchParams>} - URLSearchParams object instance.
 */
export function getQueryParams() {
  return new URLSearchParams(window.location.search);
}

/**
 * Opens url in new tab
 * @param {string} - url
 */
export function openInNewTab(url) {
  var win = window.open(url, "_blank");
  win.focus();
}

/**
 * Maps over the commonly used Parse/GraphQL Connection object
 * to return an array of under lying node fields.
 * @param {Object} - Connection object
 * @returns {Object[]}
 */
export function getNodes({ edges } = {}) {
  return (edges || []).map(({ node }) => node);
}

/**
 * Returns valid Date object based on the 'st' (start time) query param
 * Used to passed info to url as a default start time for new Events.
 * If query param is missing or invalid, current time Date is returned.
 * @returns {<Date>} - A valid Date object
 */
export function getStartTimeFromQueryParam() {
  let date = new Date();
  const startTimeParam = getQueryParams().get("st");
  if (!startTimeParam) {
    return date;
  }
  const dateObj = new Date(startTimeParam);
  return !isNaN(dateObj) ? dateObj : date;
}

/**
 * @param {string} date - ISO formatted string from DB
 * @returns {string} - Date as MM/dd/yyyy.
 */
export function formatDate(date) {
  return dateFnsFormat(new Date(date), "M/d/yyyy");
}

/**
 * @param {string} date - ISO formatted string from DB
 * @returns {string} - Date as "10:00 AM Oct 30, 2021".
 */
export function formatFullDateTime(date) {
  return dateFnsFormat(new Date(date), "h:mm a EEE MMM d, yyyy");
}

/**
 * @param {string} date - ISO formatted string from DB
 * @returns {Object} - { time: "12:30", ampm: "PM" }
 */
export function formatTime(date) {
  const dateObj = new Date(date);
  return {
    time: dateFnsFormat(dateObj, "h:mm"),
    ampm: dateFnsFormat(dateObj, "a"),
  };
}

/**
 * @param {string} date - ISO formatted string from DB
 * @returns {string} - Date as h:mm a M/d/yyy.
 */
export function formatShortDateTime(date) {
  return dateFnsFormat(new Date(date), "h:mm a M/d/yy");
}

/**
 * @param {Events[]} events - Array of Event objects from Parse-GraphQL
 * @returns {string} - "SEPT 24 - 26, 2021"
 */
export function formatTournamentDateRange(events) {
  if (!events?.edges?.length) {
    return "";
  }
  const startTimes = events.edges
    .map(({ node }) => new Date(node?.eventStartTime))
    .sort((a, b) => a - b);
  const formattedFirst = dateFnsFormat(new Date(startTimes[0]), "MMM d, yyyy");
  const formattedLast = dateFnsFormat(
    new Date(startTimes[startTimes.length - 1]),
    "MMM d, yyyy"
  );

  if (formattedFirst === formattedLast) {
    // Tournament events are all on 1 day
    return formattedFirst;
  }
  // Tournament events are all on multiple days
  return formattedFirst + " - " + formattedLast;
}

/**
 * @param {string} eventType - Event.eventType. ie. "MIXED_DOUBLES".
 * @returns {string} - Formatted eventType. ie. "Mixed Doubles".
 */
export function formatEventType(eventType) {
  return EVENT_TYPE_OPTION_NAMES[eventType];
}

/**
 * @param {string} eventFormat - Event.eventFormat. ie. "ROUND_ROBIN_TEAM".
 * @returns {string} - Formatted eventFormat. ie. "Round Robin".
 */
export function formatEventFormat(eventFormat) {
  return EVENT_FORMAT_OPTION_NAMES[eventFormat];
}

/**
 * @param {Object} event - <Event>
 * @returns {string} - Formatted event name. ie. "4.5 • Women's Singles • Individual Round Robin • Ages 65+".
 */
export function formatEventName(event) {
  if (!event) {
    return null;
  }

  return `${event.skillLevel} • ${formatEventType(
    event.eventType
  )} • ${formatEventFormat(event.eventFormat)} • ${formatAgeRange(
    event.ageRange
  )}`;
}

/**
 * @param {integer} price - Price of an event per person
 * @returns {string} - Formatted price. ie. "FREE" or "$20 per person".
 */
export function formatPrice(price = 0) {
  return price === 0 ? "free" : `$${price} per person`;
}

/**
 * @param {integer} price - Price in USD. ie. 30.
 * @returns {string} - Formatted price. ie. "$26.46".
 */
export function getProfitAfterFees(price) {
  const priceInCents = price * 100;
  const dinkPalFixedFee = DINKPAL_FLAT_FEE * 100;
  const paypalPercentageFee = 0.0349;
  const paypalFixedFee = 49;
  const profitCents =
    (priceInCents -
      dinkPalFixedFee -
      paypalFixedFee -
      priceInCents * paypalPercentageFee) /
    100;
  return "$" + profitCents.toFixed(2);
}

/**
 * Returns a formatted string of ageRange.
 * Examples;
 * "0,100" -> "Any age"
 * "0,65" -> "Ages <65"
 * "65,100" -> "Ages 65+"
 * "18,65" -> "Ages 18-65"
 * @param {string} ageRange - Event.ageRange string
 * @returns {string} - Formatted string of ageRange
 */
export function formatAgeRange(ageRange) {
  let displayedRange = "";
  if (!ageRange) {
    return displayedRange;
  }

  const [min, max] = ageRange.split(",");
  if (min === "0" && max === "100") {
    displayedRange = "Any age";
  } else if (min === "0") {
    displayedRange = `Ages <${max}`;
  } else if (max === "100") {
    displayedRange = `Ages ${min}+`;
  } else {
    displayedRange = `Ages ${min}-${max}`;
  }
  return displayedRange;
}

/**
 * Returns a decorated tournament object with events grouped and sorted by date,
 * and a "lastEventTime" which is used to populate an initial start date when creating a new event.
 * @param {Tournament} tournament - Tournament object from Parse-GraphQL
 * @returns {Object} - Tournament object with events grouped by date, and lastEventTime.
 *
 * Example output:
 * {
 *   objectId: "BIxgzFi4j3",
 *   name: "My Tournament",
 *   lastEventTime: "2021-10-23T18:00:00.000Z"
 *   eventsByDate: [
 *     {
 *       date: "10/30/2021",
 *       events: [
 *         {
 *           eventType: "MIXED_DOUBLES",
 *           eventFormat: "ROUND_ROBIN_TEAM",
 *           ...
 *         }
 *       ]
 *     },
 *     ...
 *   ]
 * }
 *
 */
export function groupTournamentEventsByDate(tournament) {
  if (!tournament) {
    return {};
  }

  const dateMap = {};
  const sortedStartTimes = tournament?.events?.edges
    ?.map(({ node: event }) => {
      const date = formatDate(event.eventStartTime);
      if (dateMap[date]) {
        dateMap[date].push(event);
      } else {
        dateMap[date] = [event];
      }
      return event.eventStartTime;
    })
    .sort((a, b) => {
      return a - b;
    });

  // Sort dates by earliest first, latest last.
  // Easiest to sort using Date object according to
  // https://stackoverflow.com/questions/12192491/sort-array-by-iso-8601-date
  const eventsByDate = Object.keys(dateMap)
    .map((date) => {
      const sortedEvents = (dateMap[date] || []).sort(
        (a, b) => new Date(a.eventStartTime) - new Date(b.eventStartTime)
      );
      return {
        events: sortedEvents,
        date,
      };
    })
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  return {
    ...tournament,
    eventsByDate,
    lastEventTime: sortedStartTimes[sortedStartTimes.length - 1],
  };
}

/**
 * Does event contain a team with the provided id?
 * @param {string} teamId - Team id
 * @param {Object} event - Event object from Parse-GraphQL
 * @returns {boolean}
 */
export function isTeamInEvent(teamId, event) {
  return getNodes(event?.teams).some(({ objectId }) => objectId === teamId);
}

/**
 * If provided team id is found in provided game.
 * @param {string} teamId - Team id
 * @param {Object} game - Game object from Parse-GraphQL
 * @returns {boolean}
 */
export function isTeamInGame(teamId, game) {
  if (!teamId) {
    return false;
  }
  return (
    game?.teamOne?.objectId === teamId || game?.teamTwo?.objectId === teamId
  );
}

/**
 * @param {Object} tournament - If event is free to register
 * @returns {boolean}
 */
export function isTournamentFree(tournament) {
  return tournament?.pricePerPerson === 0;
}

/**
 * @param {Object} team - Team object from Parse-GraphQL
 * @param {Object} event - Event object from Parse-GraphQL
 * @param {boolean} isRegistrationFree - Is event free to register?
 * @returns {boolean}
 */
export function isTeamRegisteredButMissingPartner(
  team,
  event,
  isRegistrationFree
) {
  if (isPartnerNeededToRegister(event)) {
    const teamPlayers = (team.players?.edges || []).map(({ node }) => node);
    const hasOnePlayer = teamPlayers.length === 1;
    const hasPaid = teamPlayers.every((player) => player.hasPaid);

    return isRegistrationFree ? hasOnePlayer : hasOnePlayer && hasPaid;
  }

  return false;
}

export function doesTeamHaveRequiredPlayers(team, event) {
  const teamPlayers = (team?.players?.edges || []).map(({ node }) => node);
  // Use "greater or equal to" in case teams somehow have more than the required number of players.
  if (isPartnerNeededToRegister(event)) {
    return teamPlayers.length >= 2;
  } else {
    return teamPlayers.length >= 1;
  }
}

export function doesTeamHaveAllPlayersPaid(team) {
  const teamPlayers = (team?.players?.edges || []).map(({ node }) => node);
  return teamPlayers.every((player) => player.hasPaid);
}

export function doesTeamFulfillAllRequirements(team, event, isFree) {
  if (!doesTeamHaveRequiredPlayers(team, event)) {
    return false;
  }

  return isFree ? true : doesTeamHaveAllPlayersPaid(team);
}

/**
 * Returns total number of registered players given a tournament.
 *
 * @param {Object} tournament - Tournament object from Parse-GraphQL
 * @returns {Number}
 */
export function getTournamentTotals(tournament) {
  const tournamentTotals = {
    numRegistrations: 0,
    maxRegistrations: 0,
  };

  return getNodes(tournament?.events).reduce((totals, event) => {
    const { teamsFullyRegistered } = getTeamGroups(event, tournament);

    totals.numRegistrations +=
      getNumPlayersFromRegisteredTeams(teamsFullyRegistered);
    totals.maxRegistrations += event.playersMax || 0;
    return totals;
  }, tournamentTotals);
}

/**
 * Returns an object containing 3 types of teams:
 * teamsFullyRegistered - Teams with players that fulfill all requirements for the event (ie. have partner, hasPaid)
 * waitlistedTeams - Teams with players that fulfill all requirements for the event but the event.playersMax has already been reached.
 * teamsFullyRegistered - Teams that are missing a partner. Should only be populated for events that require a partner.
 *
 * Teams are first sorted by earliest 'createdAt' first and latest 'createdAt' last
 * to give priority to those who started registration first.
 *
 * @param {Object} event - Event object from Parse-GraphQL
 * @param {Object} tournament - If event is free to register
 * @returns {{
 *   teamsFullyRegistered: [...],
 *   teamsNeedingPartner: [...],
 *   teamsMissingPayment: [...],
 * }}
 */
export function getTeamGroups(event, tournament) {
  // TODO: Add Waitlisted teams...

  const isFree = isTournamentFree(tournament);

  const teamsFullyRegistered = [];
  const teamsNeedingPartner = [];
  const teamsMissingPayment = [];

  (event?.teams?.edges || [])
    .map(({ node }) => node)
    // TODO: Find a way to sort by "requirementsAt"
    .sort(
      (teamA, teamB) => new Date(teamA.createdAt) - new Date(teamB.createdAt)
    )
    .forEach((team) => {
      const hasRequiredPlayers = doesTeamHaveRequiredPlayers(team, event);
      const haveAllPlayersPaid = doesTeamHaveAllPlayersPaid(team);

      if (isFree) {
        // Free
        if (hasRequiredPlayers) {
          teamsFullyRegistered.push(team);
        } else {
          if (team?.players?.edges?.length > 0) {
            teamsNeedingPartner.push(team);
          }
        }
      } else {
        // Paid
        if (haveAllPlayersPaid) {
          if (hasRequiredPlayers) {
            teamsFullyRegistered.push(team);
          } else {
            if (team?.players?.edges?.length > 0) {
              teamsNeedingPartner.push(team);
            }
          }
        } else {
          teamsMissingPayment.push(team);
        }
      }
    });

  return {
    teamsFullyRegistered,
    teamsNeedingPartner,
    teamsMissingPayment,
  };
}

/**
 *
 * @param {Team[]} registeredTeams - Array of registered teams. ie. teams that fulfill all requirements to be registered.
 * @returns {Number}
 */
export function getNumPlayersFromRegisteredTeams(registeredTeams) {
  return (registeredTeams || []).reduce((total, team) => {
    total += team.players?.edges?.length;
    return total;
  }, 0);
}

/**
 * Returns player names as string given a Team.
 * @param {Object} team
 * @returns {string}
 */
export function getTeamPlayerNames(team, separator = " / ") {
  return getTeamUsers(team)
    .map(({ fullname }) => fullname)
    .join(separator);
}

/**
 * Returns Users from a Team
 * @param {Object} team
 * @returns {Object[]}
 */
export function getTeamUsers(team) {
  return team?.players?.edges.map(({ node }) => node).map(({ user }) => user);
}

/**
 * Return arrays of user objects within a team
 * @param {Object} team
 * @returns {Player[]}
 */
export function getTeamPlayerName(team) {
  return team?.players?.edges.map(({ node }) => node).map(({ user }) => user);
}

/**
 *
 * @param {Team[]} registeredTeams - Array of registered teams. ie. teams that fulfill all requirements to be registered.
 * @param {number} playersMax - Max num of players for the event set by admins.
 * @param {boolean} requiresPartner - Does this event require
 * @returns {number} teams decorated with additional fields (ie. "isWaitlisted")
 */
export function getDecoratedTeams(registeredTeams, event) {
  const playersMax = event?.playersMax;
  const doesRegistrationRequirePartner = isPartnerNeededToRegister(event);
  const playerCountPerTeam = doesRegistrationRequirePartner ? 2 : 1;
  let playerCount = 0;

  return (registeredTeams || []).map((team) => {
    playerCount += playerCountPerTeam;
    return {
      ...team,
      isWaitlisted: playerCount > playersMax,
    };
  });
}

/**
 *
 * @param {string} teamId - Team id
 * @param {Object} event - Event object from Parse-GraphQL
 * @returns {string}
 */
export function getTeamById(teamId, event) {
  return getNodes(event?.teams).find(({ objectId }) => objectId === teamId);
}

/**
 *
 * @param {string} userId - User id
 * @param {Object} team - Team object from Parse-GraphQL
 * @returns {Object} - User object from Parse-GraphQL
 */
export function getUserFromTeam(userId, team) {
  const players = (team?.players?.edges || []).map(({ node }) => node);
  for (var i = 0; i < players.length; i++) {
    const player = players[i];
    if (player?.user?.objectId === userId) {
      return player.user;
    }
  }
}

/**
 *
 * @param {string} userId - User id
 * @param {Object} event - Event object from Parse-GraphQL
 * @returns {string}
 */
export function getTeamByUserId(userId, event) {
  return (
    (event?.teams?.edges || []).find(({ node: team }) => {
      return isUserInTeam(userId, team);
    })?.node || null
  );
}

/**
 *
 * @param {string} userId - User id
 * @param {Object} event - Event object from Parse-GraphQL
 * @returns {string}
 */
export function getTeamPlayersCount(team) {
  return (team?.players?.edges || []).filter(({ node }) => !!node?.objectId)
    .length;
}

/**
 * @param {string} userId - User id
 * @param {Object} team - Team object from Parse-GraphQL
 * @returns {boolean}
 */
export function isUserInTeam(userId, team) {
  return (team?.players?.edges || []).some(({ node: player }) => {
    return !!player.user?.objectId && player.user?.objectId === userId;
  });
}

/**
 * @param {string} userId - User id
 * @param {Object} event - Event object from Parse-GraphQL
 * @returns {boolean}
 */
export function getIsUserInEvent(userId, event) {
  return (event?.teams?.edges || []).some(({ node: team }) => {
    return isUserInTeam(userId, team);
  });
}

/**
 * @param {Object} event - Event object from Parse-GraphQL
 * @param {Object} tournament - Tournament object from Parse-GraphQL
 * @returns {boolean}
 */
export function getIsEventFull(event, tournament) {
  const { teamsFullyRegistered } = getTeamGroups(event, tournament);
  const numRegisteredPlayers =
    getNumPlayersFromRegisteredTeams(teamsFullyRegistered);
  return numRegisteredPlayers >= event?.playersMax;
}

/**
 * @param {string} userId - User id
 * @param {Object} event - Event object from Parse-GraphQL
 * @returns {boolean}
 */
export function getHasUserPaidForEvent(userId, event) {
  const player = getPlayerFromEvent(userId, event);
  return !!player?.hasPaid;
}

/**
 * Returns Player with userId from event -> teams -> players if found.
 * @param {string} userId
 * @param {Object} event - Event object from Parse-GraphQL
 * @returns {Object} - Player object from Parse-GraphQL
 */
export function getPlayerFromEvent(userId, event) {
  const teams = (event?.teams?.edges || []).map(({ node }) => node);

  for (var i = 0; i < teams.length; i++) {
    const team = teams[i];
    const players = (team?.players?.edges || []).map(({ node }) => node);
    for (var j = 0; j < players.length; j++) {
      const player = players[j];
      if (player?.user?.objectId === userId) {
        return player;
      }
    }
  }
}

/**
 * If user is already in another tournament event and has paid.
 * @param {string} userId
 * @param {Object} tournament - Tournament object from Parse-GraphQL
 * @returns {boolean}
 */
export function getHasUserAlreadyPaidForAnotherEvent(userId, tournament) {
  const events = (tournament?.events?.edges || []).map(({ node }) => node);

  for (var i = 0; i < events.length; i++) {
    const event = events[i];
    const player = getPlayerFromEvent(userId, event);
    if (player && player.hasPaid) {
      return true;
    }
  }
  return false;
}

/**
 * Returns Player with userId from teams -> players if found.
 * @param {string} userId
 * @param {Object} team - Team object from Parse-GraphQL
 * @returns {Object} - Player object from Parse-GraphQL
 */
export function getPlayerFromTeam(userId, team) {
  return (team?.players?.edges || [])
    .map(({ node }) => node)
    .find((player) => player?.user?.objectId === userId);
}

/**
 * If partner is needed to register for event.
 * @param {Object} event - Event object from Parse-GraphQL
 * @returns {boolean}
 */
export function isPartnerNeededToRegister(event) {
  return (
    isDoublesEvent(event) &&
    event?.eventFormat !== EVENT_FORMAT_OPTIONS.ROUND_ROBIN_INDIVIDUAL
  );
}

/**
 * If event is a doubles event
 * @param {Event} event - Event object from Parse-GraphQL
 * @returns {boolean}
 */
export function isDoublesEvent(event) {
  return DOUBLES_EVENT_TYPES.includes(event?.eventType);
}

/**
 * Generates 1 pool of a ROUND_ROBIN format using the provided teams.
 * Round robin algorithm shamelessly stolen from https://javascript.tutorialink.com/round-robin-algorithm-with-people-added-and-deleted/
 * @param {Object[]} registeredTeams - Array of Team objects from Parse-GraphQL
 * @returns
 * [
 *   {
 *     id: "round:1",
 *     children: [
 *       {
 *         id: "match:1",
 *         gameIds: ["newX9ee2"],
 *       },
 *       {
 *         id: "match:2",
 *         gameIds: ["newBsie"],
 *       },
 *       ...
 *     ],
 *   },
 *   ...
 * ]
 */

export function generateRoundRobinPoolAndGamesMap(registeredTeams) {
  const teams = [...registeredTeams];
  const newGames = [];

  // Add a 'bye' team if there is an odd number of teams
  if (isOdd(registeredTeams.length)) {
    teams.push(getByeTeam());
  }

  const rounds = [];
  const numRounds = teams.length - 1;

  for (var i = 0; i < numRounds; i++) {
    // Rounds
    const round = {
      id: `round:${i + 1}`,
      children: [],
    };

    const numMatches = teams.length / 2;

    for (var j = 0; j < numMatches; j++) {
      // Matches
      const matchNumber = i ? i * numMatches + (j + 1) : j + 1;
      const teamOne = teams[j];
      const teamTwo = teams[teams.length - 1 - j];

      const newGameId = `new${getRandomId()}`;

      const newGame = {
        objectId: newGameId,
        teamOne,
        teamTwo,
        teamOneScore: undefined,
        teamTwoScore: undefined,
      };

      round.children.push({
        id: `match:${matchNumber}`,
        gameIds: [newGameId],
      });

      newGames.push(newGame);
    }

    rounds.push(round);

    teams.splice(1, 0, teams[teams.length - 1]);
    teams.pop();
  }

  return {
    pool: {
      id: "pool:ROUND_ROBIN",
      children: rounds,
    },
    newGames,
  };
}

/**
 * Returns sorted cumulative team scores.
 * @param {Object[]} games - Array of Game objects from Parse-GraphQL
 * @returns
 * [
 *     {
 *       teamName: 'Kenji Miwa / Kate La',
 *       totalPoints: 52,
 *       totalWins: 4,
 *     },
 *   ...
 * ]
 */
export function formatTeamStandings(games) {
  const teams = {};

  games.forEach((game) => {
    if (isByeGame(game)) {
      return;
    }

    const teamOneId = game?.teamOne?.objectId;
    const teamTwoId = game?.teamTwo?.objectId;
    const teamOneName = getTeamPlayerNames(game?.teamOne);
    const teamTwoName = getTeamPlayerNames(game?.teamTwo);
    const teamOnePoints = game?.teamOneScore ?? 0;
    const teamTwoPoints = game?.teamTwoScore ?? 0;
    const teamOnePointDiff =
      (game?.teamOneScore ?? 0) - (game?.teamTwoScore ?? 0);
    const teamTwoPointDiff =
      (game?.teamTwoScore ?? 0) - (game?.teamOneScore ?? 0);
    const teamOneWins = teamOnePoints > teamTwoPoints ? 1 : 0;
    const teamTwoWins = teamTwoPoints > teamOnePoints ? 1 : 0;

    if (teams[teamOneId]) {
      teams[teamOneId].totalPoints += teamOnePoints;
      teams[teamOneId].pointDiff += teamOnePointDiff;
      teams[teamOneId].totalWins += teamOneWins;
    } else {
      teams[teamOneId] = {
        teamName: teamOneName,
        totalPoints: teamOnePoints,
        pointDiff: teamOnePointDiff,
        totalWins: teamOneWins,
      };
    }

    if (teams[teamTwoId]) {
      teams[teamTwoId].totalPoints += teamTwoPoints;
      teams[teamTwoId].pointDiff += teamTwoPointDiff;
      teams[teamTwoId].totalWins += teamTwoWins;
    } else {
      teams[teamTwoId] = {
        teamName: teamTwoName,
        totalPoints: teamTwoPoints,
        pointDiff: teamTwoPointDiff,
        totalWins: teamTwoWins,
      };
    }
  });

  // Return sorted by totalWins descending
  return Object.values(teams).sort((a, b) => b.totalWins - a.totalWins);
}

/**
 * @param {Object} number - An integer
 * @returns {boolean}
 */
export function isOdd(number) {
  return !!(number % 2);
}

/**
 * If team if a 'Bye' team based on it's objectId.
 * @param {Object} team - Team object from Parse-GraphQL
 * @returns {boolean}
 */
export function isByeTeam(team) {
  return team?.objectId === BYE_TEAM_ID;
}

/**
 * If game contains a 'Bye' team
 * @param {Object} game - Game object from Parse-GraphQL
 * @returns {boolean}
 */
export function isByeGame(game) {
  return isByeTeam(game?.teamOne) || isByeTeam(game?.teamTwo);
}

/**
 * Returns a placeholder 'Bye' team.
 * @returns {Object} team - Team object from Parse-GraphQL
 */
export function getByeTeam() {
  return {
    __typename: "Team",
    objectId: BYE_TEAM_ID,
    players: {
      __typename: "PlayerConnection",
      edges: [
        {
          __typename: "PlayerEdge",
          node: {
            __typename: "Player",
            hasPaid: true,
            objectId: BYE_PLAYER_ID,
            user: {
              fullname: "Bye",
              objectId: BYE_USER_ID,
            },
          },
        },
      ],
    },
  };
}

/**
 * If event schedule can be rendered for this event
 * @returns {Object} event - Event object from Parse-GraphQL
 */
export function isEventScheduleSupported(event) {
  return [
    EVENT_FORMAT_OPTIONS.ROUND_ROBIN_TEAM,
    EVENT_FORMAT_OPTIONS.DOUBLE_ELIMINATION,
  ].includes(event?.eventFormat);
}

/**
 * Return random 6 char string. ie. '3m4wiwf'.
 * @returns {string}
 */
export function getRandomId() {
  return (Math.random() + 1).toString(36).substring(6);
}

/**
 * Runs the provided function on each node in the provided tree.
 */
export function runOnEachNode(node, fn = () => {}) {
  fn(node);
  node?.pools?.forEach((pool) => runOnEachNode(pool, fn));
  node?.finals?.forEach((final) => runOnEachNode(final, fn));
  node?.children?.forEach((node) => runOnEachNode(node, fn));
}

/**
 * Returns map of all gameIds found in the provided eventSpec.
 * @returns {Object}  ex. { '<game_id>': true, ... }
 */
export function getAllGameIdsFromEventSpec(eventSpec) {
  const gameIdsMap = {};
  runOnEachNode(eventSpec, (node) => {
    if (node?.gameIds?.length) {
      node.gameIds.forEach((gId) => {
        gameIdsMap[gId] = true;
      });
    }
  });
  return gameIdsMap;
}

export function deepClone(object) {
  return JSON.parse(JSON.stringify(object));
}

export function getEventSpecNodeById(eventSpec, id) {
  let foundNode = null;
  runOnEachNode(eventSpec, (node) => {
    if (node.id && node.id === id) {
      foundNode = node;
    }
  });
  return foundNode;
}

/**
 * Adds 'teamId' = 'xif32dios' to each team node in tree.
 * The order te
 * @param {Object} rootNode - The highest order bracket node from which as child nodes are derived.
 * @param {Team[]} teams - The order of teams in this array is very important as it dictates the seeding position in the tree.
 * @returns {Object} the original root node w/ teamIds appended.
 */
export function addTeamIdsToTree(rootNode, teams) {
  // const newRootNode = JSON.parse(JSON.stringify(rootNode));
  runOnEachNode(rootNode, (node) => {
    if (node?.id?.startsWith(TEAM_NODE_PREFIX)) {
      const teamSeedNumber = node?.id?.slice(TEAM_NODE_PREFIX.length);
      const teamId = teams[Number(teamSeedNumber) - 1]?.objectId;

      try {
        node.teamId = teamId;
      } catch (e) {}
    }
  });

  return rootNode;
}

export function updateGamesAndEventSpec(
  eventSpec,
  updatedGamesMap,
  gamesMap,
  teamsMap
) {
  const newEventSpec = deepClone(eventSpec);
  const newUpdatedGamesMap = deepClone(updatedGamesMap);
  const newGamesMap = deepClone(gamesMap);

  const loserToMap = {};

  function updateTree() {
    runOnEachNode(newEventSpec, (node) => {
      const nodeGames = (node?.gameIds || [])
        .map((gameId) => gamesMap[gameId])
        .filter((game) => !!game);

      if (!nodeGames?.length) {
        return;
      }

      if (node?.children?.length) {
        const childOneGames = (node?.children[0]?.gameIds || [])
          .map((gameId) => gamesMap[gameId])
          .filter((game) => !!game);
        const childTwoGames = (node?.children[1]?.gameIds || [])
          .map((gameId) => gamesMap[gameId])
          .filter((game) => !!game);

        // Get "winning" team id from either a child team leaf node or from a child team node with enough data to determine winner.
        const {
          winningTeamId: cOneWinningTeamId,
          losingTeamId: cOneLosingTeamId,
        } = getWinningLosingTeamId(childOneGames);
        const {
          winningTeamId: cTwoWinningTeamId,
          losingTeamId: cTwoLosingTeamId,
        } = getWinningLosingTeamId(childTwoGames);
        const childOneWinningTeamId =
          node?.children[0]?.teamId || cOneWinningTeamId;
        const childTwoWinningTeamId =
          node?.children[1]?.teamId || cTwoWinningTeamId;

        if (cOneLosingTeamId) {
          loserToMap[node?.children?.[0]?.loserTo] = cOneLosingTeamId;
        }

        if (cTwoLosingTeamId) {
          loserToMap[node?.children?.[1]?.loserTo] = cTwoLosingTeamId;
        }

        // If child teams do not match teams in current node games, then update.
        const shouldUpdateTeams =
          (childOneWinningTeamId &&
            !isTeamInGame(childOneWinningTeamId, nodeGames[0])) ||
          (childTwoWinningTeamId &&
            !isTeamInGame(childTwoWinningTeamId, nodeGames[0]));

        if (shouldUpdateTeams) {
          // Update game data
          nodeGames.forEach((game) => {
            const gameId = game.objectId;
            const teamOne = teamsMap[childOneWinningTeamId];
            const teamTwo = teamsMap[childTwoWinningTeamId];

            newGamesMap[gameId] = { ...game, teamOne, teamTwo };

            // Update updatedGamesMap to inform save to backend.
            newUpdatedGamesMap[gameId] = true;
          });
        }
      }
    });
  }

  updateTree();

  // Update eventSpec with losing team id
  if (Object.keys(loserToMap)?.length) {
    runOnEachNode(newEventSpec, (node) => {
      const nodeId = node?.id;
      if (nodeId && loserToMap[nodeId]) {
        node.teamId = loserToMap[nodeId];
      }
    });
  }

  // TODO???: Recursively populate ancestor nodes with correct winning team id at all levels
  // if descendent match node winners are different.
  // Currently the corrections only go 2 levels up.
  updateTree();

  return {
    newEventSpec,
    newUpdatedGamesMap,
    newGamesMap,
  };
}

export function getWinningLosingTeamId(games) {
  const gamesWithScores = (games || []).filter(
    (game) =>
      typeof game.teamOneScore === "number" &&
      typeof game.teamTwoScore === "number"
  );

  const wins = gamesWithScores.reduce(
    (accum, game) => {
      if (game.teamOneScore > game.teamTwoScore) {
        accum.teamOneWins += 1;
      } else {
        accum.teamTwoWins += 1;
      }
      return accum;
    },
    {
      teamOneWins: 0,
      teamTwoWins: 0,
    }
  );

  const teamOneId = gamesWithScores[0]?.teamOne?.objectId;
  const teamTwoId = gamesWithScores[0]?.teamTwo?.objectId;

  let winningTeamId;
  let losingTeamId;

  if (
    (gamesWithScores.length === 1 && games.length === 1) ||
    (gamesWithScores.length === 3 && games.length === 3)
  ) {
    if (wins.teamOneWins > wins.teamTwoWins) {
      winningTeamId = teamOneId;
      losingTeamId = teamTwoId;
    } else {
      winningTeamId = teamTwoId;
      losingTeamId = teamOneId;
    }
  }

  if (gamesWithScores.length === 2) {
    if (wins.teamOneWins === 2) {
      winningTeamId = teamOneId;
      losingTeamId = teamTwoId;
    } else if (wins.teamTwoWins === 2) {
      winningTeamId = teamTwoId;
      losingTeamId = teamOneId;
    }

    // Else if each team has 1 win, no winner can be determined yet so leave winning/losing team ids as undefined.
  }

  return {
    winningTeamId,
    losingTeamId,
  };
}

export function getNewEventSpec() {
  return {
    pools: [],
    finals: [],
  };
}

/**
 * Returns if event.eventStartTime is in the past.
 * Returns false if no eventStartTime provided.
 * @param {Object} event - Event object from Parse-GraphQL
 * @returns {boolean}
 */
export function getIsEventInPast(event) {
  if (!event?.eventStartTime) {
    return false;
  }

  return new Date(event.eventStartTime) < new Date();
}

export function getIsEventStarted(event) {
  if (!event) {
    return false;
  }

  if (!!event.eventActualStartTime && !event.eventActualEndTime) {
    return true;
  }

  return (
    new Date(event.eventActualStartTime) > new Date(event?.eventActualEndTime)
  );
}
