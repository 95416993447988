import { Button, useToast } from "@chakra-ui/react";
import { SEND_DECLINE_INVITE } from "graphql/mutations";
import { useMutation } from "@apollo/client";
import { formatEventName, formatFullDateTime } from "utils/formatters";
import PropTypes from "prop-types";
import { DURATION } from "constants/duration";
import { useHistory } from "react-router-dom";
import { ROUTES } from "constants/routes";

function InviteDeclineButton({
  declinerId,
  inviterId,
  tournament,
  league,
  event,
  onButtonClick,
}) {
  const toast = useToast();
  const history = useHistory();
  const tournamentName = tournament?.name;
  const leagueName = league?.name;
  const eventName = formatEventName(event);
  const eventDateTime = formatFullDateTime(event?.eventStartTime);

  const [sendDeclineInvite, { loading: isSendingDeclineInvite }] = useMutation(
    SEND_DECLINE_INVITE,
    {
      variables: {
        declinerId,
        inviterId,
        tournamentName,
        leagueName,
        eventName,
        eventDateTime,
      },
    }
  );

  return (
    <Button
      size="lg"
      width="100%"
      colorScheme="red"
      background="white"
      variant="outline"
      isLoading={isSendingDeclineInvite}
      onClick={() => {
        onButtonClick();

        if (!declinerId) {
          return;
        }

        sendDeclineInvite()
          .then(() => {
            toast({
              title:
                "Thanks, we've let the inviter know you've declined this invite.",
              status: "success",
              duration: DURATION.MEDIUM,
              isClosable: true,
            });
            history.push(ROUTES.MY_EVENTS);
          })
          .catch((e) => {
            toast({
              title:
                "Sorry, there was a problem declining this invite. Please try again later.",
              status: "error",
              duration: DURATION.LONG,
              isClosable: true,
            });
          });
      }}
    >
      Decline
    </Button>
  );
}

InviteDeclineButton.propTypes = {
  declinerId: PropTypes.string,
  inviterId: PropTypes.string.isRequired,
  tournament: PropTypes.object.isRequired,
  league: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
};

export default InviteDeclineButton;
