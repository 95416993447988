// eslint-disable-next-line import/no-anonymous-default-export
export default {
  id: "match:8",
  children: [
    {
      id: "match:5",
      loserTo: "team:L5",
      children: [
        {
          id: "match:2",
          loserTo: "team:L2",
          children: [
            {
              id: "team:2",
            },
            {
              id: "team:3",
            },
          ],
        },
        {
          id: "match:3",
          loserTo: "team:L3",
          children: [
            {
              id: "match:1",
              loserTo: "team:L1",
              children: [
                {
                  id: "team:4",
                },
                {
                  id: "team:5",
                },
              ],
            },
            {
              id: "team:1",
            },
          ],
        },
      ],
    },
    {
      id: "match:7",
      children: [
        {
          id: "team:L5",
        },
        {
          id: "match:6",
          children: [
            {
              id: "match:4",
              children: [
                {
                  id: "team:L1",
                },
                {
                  id: "team:L2",
                },
              ],
            },
            {
              id: "team:L3",
            },
          ],
        },
      ],
    },
  ],
};
