import { useColorModeValue, Box, Icon, Heading, Text } from "@chakra-ui/react";
import { useAuthContext } from "context/AuthContext";
import { getTeamPlayerNames, isTournamentFree } from "utils/formatters";
import { BiCheckCircle, BiInfoCircle } from "react-icons/bi";
import QuestionMarkTooltip from "components/QuestionMarkTooltip";

function YourTeamStatus({ tournament, team }) {
  const { authState } = useAuthContext();
  const userId = authState?.user?.objectId;
  const players = (team?.players?.edges || []).map(({ node }) => node);
  const paymentReminder =
    'Both players must pay the registration fee to be added to "Registered Teams".';
  const bgColor = useColorModeValue("gray.200", "gray.900");
  const isRegistrationFree = isTournamentFree(tournament);

  return (
    <Box pt="4" borderColor={bgColor} borderTopWidth="1px">
      <Box d="flex" justifyContent="space-between">
        <Heading as="h6" size="sm" mb="4px">
          Your team
          <QuestionMarkTooltip text="Only you can see this section" />
        </Heading>
      </Box>
      <Text d="inline-block">{getTeamPlayerNames(team)}</Text>
      {!isRegistrationFree && (
        <Box>
          {players.map((player) => {
            if (player.hasPaid) {
              return (
                <Box key={player?.objectId} d="flex" alignItems="center" mt="4">
                  <Icon as={BiCheckCircle} color="green" w={6} h={6} mr="2" />
                  {player?.user?.objectId === userId
                    ? "You have paid"
                    : `${player?.user?.fullname} has paid`}
                </Box>
              );
            } else {
              return (
                <Box key={player?.objectId} d="flex" alignItems="center" mt="4">
                  <Icon as={BiInfoCircle} color="red" w={6} h={6} mr="2" />
                  {player?.user?.objectId === userId
                    ? `You have not paid. ${paymentReminder}`
                    : `${player?.user?.fullname} has not paid. ${paymentReminder}`}
                </Box>
              );
            }
          })}
        </Box>
      )}
    </Box>
  );
}

export default YourTeamStatus;
