import { Modal, ModalOverlay } from "@chakra-ui/react";
import { useState } from "react";
import { isPartnerNeededToRegister } from "utils/formatters";
import SubmitPaymentModalContent from "components/SubmitPaymentModalContent";

import PropTypes from "prop-types";
import NewRegistrationModalContent from "components/NewRegistrationModalContent";
import SuccessModalContent from "./SuccessModalContent";

const MODEL_CONTENTS = {
  NEW_REGISTRATION: "NEW_REGISTRATION",
  SUBMIT_PAYMENT: "SUBMIT_PAYMENT",
  SUCCESS: "SUCCESS",
};

/**
 * Registers a user in various event format scenarios.
 * @param {Object} user - User object from Parse-GraphQL
 * @param {Object} tournament - Tournament object from Parse-GraphQL
 * @param {Object} event - Event object from Parse-GraphQL
 * @param {boolean} isOpen - Is RegistrationWizardModal open or not
 * @param {Function} onClose - Call to close RegistrationWizardModal
 * @param {gql[]} refetchQueries - Array of GraphQL queries to call after any mutation
 */
function RegistrationWizardModal({
  league,
  tournament,
  event,
  isOpen,
  onClose,
  refetchQueries,
}) {
  const isRegistrationFree = tournament?.pricePerPerson === 0;
  const isPartnerNeeded = isPartnerNeededToRegister(event);
  const [modalContent, setModalContent] = useState(
    MODEL_CONTENTS.NEW_REGISTRATION
  );
  const [player, setPlayer] = useState({});

  // Reset initial modal content state on close.
  function handleClose() {
    setModalContent(MODEL_CONTENTS.NEW_REGISTRATION);
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />

      {modalContent === MODEL_CONTENTS.NEW_REGISTRATION && (
        <NewRegistrationModalContent
          tournament={tournament}
          event={event}
          refetchQueries={refetchQueries}
          onClose={handleClose}
          onComplete={(newPlayer) => {
            setPlayer(newPlayer);

            if (!isRegistrationFree) {
              setModalContent(MODEL_CONTENTS.SUBMIT_PAYMENT);
            } else {
              setModalContent(MODEL_CONTENTS.SUCCESS);
            }
          }}
        />
      )}

      {modalContent === MODEL_CONTENTS.SUBMIT_PAYMENT && (
        <SubmitPaymentModalContent
          paypalEmail={league?.paypalEmail}
          tournament={tournament}
          event={event}
          playerId={player.objectId}
          onClose={handleClose}
          onSuccess={() => {
            setModalContent(MODEL_CONTENTS.SUCCESS);
          }}
          refetchQueries={refetchQueries}
        />
      )}

      {modalContent === MODEL_CONTENTS.SUCCESS && (
        <SuccessModalContent
          isPartnerNeeded={isPartnerNeeded}
          isRegistrationFree={isRegistrationFree}
          onClose={handleClose}
        />
      )}
    </Modal>
  );
}

RegistrationWizardModal.propTypes = {
  league: PropTypes.object.isRequired,
  tournament: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RegistrationWizardModal;
