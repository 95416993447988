import { createContext, useState, useContext } from "react";
import { ROUTES } from "constants/routes";
import { useHistory } from "react-router-dom";
import { SESSION_TOKEN_KEY, USER_KEY } from "constants/localstorage";

const AuthContext = createContext();
const { Provider } = AuthContext;

const useAuthContext = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const history = useHistory();
  const sessionToken = localStorage.getItem(SESSION_TOKEN_KEY);
  const user = localStorage.getItem(USER_KEY);

  const [authState, setAuthState] = useState({
    sessionToken,
    user: user ? JSON.parse(user) : {},
  });

  const setAuthInfo = ({ sessionToken, user }) => {
    localStorage.setItem(SESSION_TOKEN_KEY, sessionToken);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
    setAuthState({ sessionToken, user });
  };

  const isAuthenticated = () => !!authState.sessionToken;

  const logOut = () => {
    localStorage.removeItem(SESSION_TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
    setAuthState({
      sessionToken: null,
      user: {},
    });
    history.push(ROUTES.SIGNIN);
  };

  return (
    <Provider
      value={{
        authState,
        setAuthState: ({ sessionToken, user }) =>
          setAuthInfo({ sessionToken, user }),
        isAuthenticated,
        logOut,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider, useAuthContext };
