import { Box, useColorModeValue } from "@chakra-ui/react";

import { isMobileDevice } from "utils/browsers";

function ConditionalMobileHeader({ children }) {
  const isMobile = isMobileDevice();
  const bgColor = useColorModeValue("white", "gray.900");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  return isMobile ? (
    <Box
      p="3"
      w="100%"
      zIndex="999"
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      position="fixed"
      bottom="0"
    >
      {children}
    </Box>
  ) : (
    <Box
      p="3"
      w="100%"
      zIndex="999"
      d="flex"
      borderBottom="1.5px solid"
      borderColor={borderColor}
      backgroundColor={bgColor}
      justifyContent="space-between"
      alignItems="center"
      position="fixed"
      top="0"
    >
      {children}
    </Box>
  );
}

export default ConditionalMobileHeader;
