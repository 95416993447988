import { useAuthContext } from "context/AuthContext";
import PublicMobileBottomNav from "components/PublicMobileBottomNav";
import PrivateMobileBottomNav from "components/PrivateMobileBottomNav";
import { Box, useColorMode } from "@chakra-ui/react";
import { BOTTOM_NAV_HEIGHT_OFFSET_PX } from "constants/styles";
import { useLocation } from "react-router-dom";

const PATHNAMES_WITH_COLOR_BG_REGEX = [
  /account-settings/,
  /my-league/,
  /admin/,
  /my-events/,
  /register/,
  /invite/,
];

const PATHNAMES_TO_HIDE_BOTTOM_NAV = [/event-schedule/, /sandbox/];

function AppShell({ children }) {
  const { colorMode } = useColorMode();
  const { isAuthenticated, authState } = useAuthContext();
  const { pathname } = useLocation();
  const hasLeagues = !!authState?.user?.leagueId;
  const shouldHaveColorBg =
    colorMode === "light" &&
    PATHNAMES_WITH_COLOR_BG_REGEX.some((regex) => regex.test(pathname));
  const shouldHideBottomNav = PATHNAMES_TO_HIDE_BOTTOM_NAV.some((regex) =>
    regex.test(pathname)
  );

  return (
    <Box
      pb={!shouldHideBottomNav && BOTTOM_NAV_HEIGHT_OFFSET_PX}
      minH="100vh"
      d="flex"
      flexDir="column"
    >
      <>
        {shouldHaveColorBg && (
          <Box
            position="absolute"
            left={0}
            top={0}
            w="100%"
            h="100%"
            zIndex={-1}
            backgroundImage="/pattern-light-gray.svg"
            backgroundSize={["200px", "200px", "400px", "400px"]}
          ></Box>
        )}
        {children}
        {!shouldHideBottomNav && (
          <Box position="relative">
            {isAuthenticated() ? (
              <PrivateMobileBottomNav
                hasLeagues={hasLeagues}
                user={authState.user}
              />
            ) : (
              <PublicMobileBottomNav />
            )}
          </Box>
        )}
      </>
    </Box>
  );
}

export default AppShell;
