import {
  getTeamGroups,
  getTeamPlayerNames,
  getNumPlayersFromRegisteredTeams,
  getDecoratedTeams,
} from "utils/formatters";
import { useMutation } from "@apollo/client";
import {
  Tooltip,
  SimpleGrid,
  Icon,
  Badge,
  Box,
  Button,
  IconButton,
  Heading,
  Text,
  Tag,
  TagLabel,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { BiPencil, BiUser } from "react-icons/bi";
import { useState } from "react";
import ConfirmModal from "components/ConfirmModal";
import {
  DELETE_PLAYER,
  DELETE_TEAM,
  UPDATE_PLAYER_HAS_PAID,
} from "graphql/mutations";
import { DURATION } from "constants/duration";
import MoveTeamDropdown from "./MoveTeamDropdown";
import MergeTeamsDropdown from "./MergeTeamsDropdown";

function TeamsListEditor({ event, tournament, refetchQueries }) {
  const [isEditMode, setEditMode] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState({});
  const [selectedTeam, setSelectedTeam] = useState({});
  const toast = useToast();

  const {
    isOpen: isRemovePlayerConfirmModalOpen,
    onOpen: onRemovePlayerConfirmModalOpen,
    onClose: onRemovePlayerConfirmModalClose,
  } = useDisclosure();
  const {
    isOpen: isMarkAsPaidConfirmModalOpen,
    onOpen: onMarkAsPaidConfirmModalOpen,
    onClose: onMarkAsPaidConfirmModalClose,
  } = useDisclosure();

  const [deletePlayer] = useMutation(DELETE_PLAYER, {
    refetchQueries,
  });
  const [updatePlayerHasPaid] = useMutation(UPDATE_PLAYER_HAS_PAID, {
    refetchQueries,
  });
  const [deleteTeam] = useMutation(DELETE_TEAM, {
    refetchQueries,
  });

  const { teamsFullyRegistered, teamsNeedingPartner, teamsMissingPayment } =
    getTeamGroups(event, tournament);

  const numRegisteredPlayers =
    getNumPlayersFromRegisteredTeams(teamsFullyRegistered);

  const decoratedTeams = getDecoratedTeams(teamsFullyRegistered, event);

  function RemoveButton({ player, team }) {
    return (
      <Button
        variant="link"
        colorScheme="teal"
        size="sm"
        mt="-2px"
        onClick={() => {
          setSelectedPlayer(player);
          setSelectedTeam(team);
          onRemovePlayerConfirmModalOpen();
        }}
      >
        Remove
      </Button>
    );
  }

  return (
    <Box d="flex" flexDir="column">
      <Box
        d="flex"
        alignItems="center"
        justifyContent="space-between"
        pt="10px"
      >
        <Box d="flex" alignItems="center">
          <Heading as="h5" size="sm">
            Players • {`${numRegisteredPlayers}/${event.playersMax}`}
          </Heading>
          <Icon as={BiUser} w={5} h={5} />
        </Box>
        <Box d="flex">
          <Tooltip hasArrow label="Edit players" d="flex">
            <IconButton
              colorScheme="teal"
              isRound={true}
              variant={isEditMode ? "solid" : "ghost"}
              ml="1"
              icon={<Icon as={BiPencil} w={5} h={5} />}
              onClick={() => {
                setEditMode(!isEditMode);
              }}
            />
          </Tooltip>
        </Box>
      </Box>

      <SimpleGrid columns={[1, null, 1]}>
        <Box mt="4">
          <Heading as="h6" size="sm" mb="4px">
            Registered teams
          </Heading>
          {!decoratedTeams.length && <Text as="i">None</Text>}
          {!!decoratedTeams.length && (
            <Text as="ol" listStylePos="inside">
              {decoratedTeams.map((team) => {
                if (isEditMode) {
                  const players = team.players.edges.map(({ node }) => node);

                  return (
                    <li key={team.objectId}>
                      {players.map((player) => (
                        <Tag
                          mr="1"
                          mb="1"
                          size="md"
                          key={player.objectId}
                          borderRadius="full"
                          variant="outline"
                          colorScheme="teal"
                        >
                          <TagLabel>{player?.user?.fullname}</TagLabel>
                          <Text mx="2" opacity="25%">
                            {" "}
                            |{" "}
                          </Text>
                          <RemoveButton player={player} team={team} />
                        </Tag>
                      ))}
                      <MoveTeamDropdown
                        tournament={tournament}
                        event={event}
                        team={team}
                        refetchQueries={refetchQueries}
                        triggerStyles={{ variant: "link", marginLeft: "2" }}
                      />
                    </li>
                  );
                } else {
                  return (
                    <Text as="li" key={team.objectId}>
                      {getTeamPlayerNames(team)}{" "}
                      {team?.isWaitlisted && (
                        <Badge ml="1" mt="-2px" colorScheme="blue">
                          Waitlisted
                        </Badge>
                      )}
                    </Text>
                  );
                }
              })}
            </Text>
          )}
        </Box>
        <Box mt="4">
          <Heading as="h6" size="sm" mb="4px">
            Players who need a partner
          </Heading>
          {!teamsNeedingPartner.length && <Text as="i">None</Text>}
          {!!teamsNeedingPartner.length && (
            <Text as="ul" listStylePos="inside">
              {teamsNeedingPartner.map((team) => {
                if (isEditMode) {
                  const players = team.players.edges.map(({ node }) => node);
                  return (
                    <li key={team.objectId}>
                      {players.map((player) => (
                        <Tag
                          mr="1"
                          mb="1"
                          size="md"
                          key={player.objectId}
                          borderRadius="full"
                          variant="outline"
                          colorScheme="teal"
                        >
                          <TagLabel>{player?.user?.fullname}</TagLabel>
                          <Text mx="2" opacity="25%">
                            |
                          </Text>
                          <RemoveButton player={player} team={team} />
                          <Text mx="2" opacity="25%">
                            |
                          </Text>
                          <MergeTeamsDropdown
                            team={team}
                            teamOptions={teamsNeedingPartner}
                            eventId={event.objectId}
                            refetchQueries={refetchQueries}
                            triggerStyles={{
                              variant: "link",
                              colorScheme: "teal",
                            }}
                          />
                        </Tag>
                      ))}
                      <MoveTeamDropdown
                        tournament={tournament}
                        event={event}
                        team={team}
                        refetchQueries={refetchQueries}
                        triggerStyles={{ variant: "link", marginLeft: "2" }}
                      />
                    </li>
                  );
                } else {
                  return (
                    <li key={team.objectId}>{getTeamPlayerNames(team)}</li>
                  );
                }
              })}
            </Text>
          )}
        </Box>
        <Box mt="4">
          <Heading as="h6" size="sm" mb="4px">
            Teams missing payment
          </Heading>
          {!teamsMissingPayment.length && <Text as="i">None</Text>}
          {!!teamsMissingPayment.length && (
            <Text as="ul" listStylePos="inside">
              {teamsMissingPayment.map((team) => {
                if (isEditMode) {
                  const players = team.players.edges.map(({ node }) => node);
                  return (
                    <li key={team.objectId}>
                      {players.map((player) => (
                        <Tag
                          mr="1"
                          mb="1"
                          size="md"
                          key={player.objectId}
                          borderRadius="full"
                          variant="outline"
                          colorScheme={player.hasPaid ? "green" : "teal"}
                        >
                          <TagLabel>{player?.user?.fullname}</TagLabel>
                          <Text mx="2" opacity="25%">
                            {" "}
                            |{" "}
                          </Text>
                          <RemoveButton player={player} team={team} />
                          <Text mx="2" opacity="25%">
                            {" "}
                            |{" "}
                          </Text>
                          {player.hasPaid && (
                            <Badge colorScheme="green" mr="2">
                              Paid
                            </Badge>
                          )}
                          {!player.hasPaid && (
                            <>
                              <Button
                                colorScheme="teal"
                                variant="link"
                                size="sm"
                                mt="-2px"
                                onClick={() => {
                                  setSelectedPlayer(player);
                                  onMarkAsPaidConfirmModalOpen();
                                }}
                              >
                                Mark as paid
                              </Button>
                            </>
                          )}
                        </Tag>
                      ))}
                      <MoveTeamDropdown
                        tournament={tournament}
                        event={event}
                        team={team}
                        refetchQueries={refetchQueries}
                        triggerStyles={{ variant: "link", marginLeft: "2" }}
                      />
                    </li>
                  );
                } else {
                  return (
                    <li key={team.objectId}>{getTeamPlayerNames(team)}</li>
                  );
                }
              })}
            </Text>
          )}
        </Box>
      </SimpleGrid>

      <ConfirmModal
        headerText="Remove player from team"
        confirmButtonText="Remove"
        isOpen={isRemovePlayerConfirmModalOpen}
        onClose={onRemovePlayerConfirmModalClose}
        onConfirmClick={() => {
          // If last player on team, also delete team
          if (selectedTeam?.players?.edges?.length === 1) {
            deleteTeam({ variables: { teamId: selectedTeam?.objectId } });
          }

          deletePlayer({
            variables: { playerId: selectedPlayer?.objectId },
          }).then(() => {
            toast({
              title: `Player "${selectedPlayer?.user?.fullname}" was removed.`,
              status: "success",
              duration: DURATION.MEDIUM,
              isClosable: true,
            });
          });
        }}
      >
        <Text>
          Are you sure you want to remove{" "}
          <b>{selectedPlayer?.user?.fullname}</b> from this team? This will
          withdraw them from the event. Any remaining player will be moved to
          the "Players who need a partner" section.
        </Text>
        <Text mt="4">
          If applicable, you are responsible for issuing a refund through
          PayPal.
        </Text>
      </ConfirmModal>

      <ConfirmModal
        headerText="Mark player as paid"
        confirmButtonText="Yes"
        confirmButtonColorScheme="teal"
        isOpen={isMarkAsPaidConfirmModalOpen}
        onClose={onMarkAsPaidConfirmModalClose}
        onConfirmClick={() => {
          const variables = {
            playerId: selectedPlayer?.objectId,
            hasPaid: true,
          };
          updatePlayerHasPaid({ variables }).then(() => {
            toast({
              title: `Player "${selectedPlayer?.user?.fullname}" has been marked as paid.`,
              status: "success",
              duration: DURATION.MEDIUM,
              isClosable: true,
            });
          });
        }}
      >
        <Text>
          Are you sure you want to mark player{" "}
          <b>{selectedPlayer?.user?.fullname}</b> as paid?
        </Text>
      </ConfirmModal>
    </Box>
  );
}

export default TeamsListEditor;
